import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoadMask from '../../Utils/LoadMask/LoadMask';

import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';
import PasoTresForm from './PasoTresForm';
import { MONTHLY_PAYMENT, ANNUAL_PAYMENT } from '../../../../utility/constants'

const logo = require('assets/img/logo_navbar.png');
class Registro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            logo: null,
            idPlan: 0,
            pay_mode: "",
        };
    }

    componentWillMount() {
        const { existe_plan } = this.props;
        const { idPlan = 0, idMode = "" } = this.props.match.params
        existe_plan(idPlan, idMode);
        if (idMode == 'annual') {
            this.setState({ idPlan: idPlan, pay_mode: ANNUAL_PAYMENT })
        } else {
            this.setState({ idPlan: idPlan, pay_mode: MONTHLY_PAYMENT })
        }

    }

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };
    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };


    previousStep = () => {
        this.setState({ step: this.state.step - 1 })
    };

    setImg = (logo) => {
        this.setState({ logo });
    };

    create = (_data) => {
        let data = { ..._data }
        const { idPlan, pay_mode } = this.state
        data.plan = idPlan
        data.active_payment = pay_mode
        this.props.onSubmit(
            { ...data, logo: null },
            [{ "file": this.state.logo, "name": "logo" }]
        );
    }

    render() {
        const { loader, datos_plan = {} } = this.props;
        const { enable_type_work_orders = 0 } = datos_plan || {}
        const { step } = this.state;
        if (localStorage.getItem('token')) {
            return <Redirect to="/" />;
        }
        return (
            <div className="blue-gradient-bg d-flex align-items-center">
                <div className="login-wrapper flex-1 px-sm-5 px-md-0">
                    <div className="card-registro mx-3 mx-sm-auto">
                        <div className="d-flex justify-content-center flex-column align-items-center">
                            <img src={logo} alt="Alerta" className="logo-registro" />
                        </div>

                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <h1 className="text-25 text-bold">REGISTRO</h1>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <div className="circulo text-bold text-center circulo circulo-activo">1</div>
                            <div className={`mx-5 circulo text-bold text-center circulo ${step > 1 && "circulo-activo"}`}>2</div>
                            <div className={`circulo text-bold text-center circulo ${step > 2 && "circulo-activo"}`}>3</div>

                            <div className="m2 position-absolute" style={{ zIndex: 70, borderTop: "solid 0px #000", width: 130, borderBottom: "solid 2px #000" }} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <h5 className="text-20 text-bold">
                                {step === 1 && "Datos del taller"}
                                {step === 2 && "Datos del administrador"}
                                {step === 3 && "Datos de facturación"}
                            </h5>
                        </div>

                        <LoadMask loading={loader} light>
                            {step === 1 && (
                                <PasoUnoForm
                                    onSubmit={this.nextStep}
                                    setImg={this.setImg}
                                    enable_type_work_orders={enable_type_work_orders}
                                />
                            )}
                            {step === 2 && (
                                <PasoDosForm
                                    onSubmit={this.nextStep}
                                    previousStep={this.previousStep}
                                />
                            )}
                            {step === 3 && (
                                <PasoTresForm
                                    onSubmit={this.create}
                                    previousStep={this.previousStep}
                                />
                            )}
                        </LoadMask>
                    </div>
                </div>
            </div >
        );
    }
}

export default Registro;
