import React, { Component } from 'react'
import Grid from '../../../Utils/Grid'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { standardActions } from '../../../Utils/Grid/StandardActions'
import Card from '../../../Utils/Cards/Card'
import { HeaderSearch } from '../../../Utils/Header'
import { RenderCurrency } from '../../../Utils/renderField/renderReadField'

export default class Listar extends Component {
    componentWillMount() {
        const { listar, page } = this.props
        listar(page)
    }

    render() {
        const { listar, onSortChange } = this.props
        const { data, loader, page } = this.props
        return (
            <Card
                titulo="Reporte de Bitácora por Colaboradores"

                header={<HeaderSearch {...this.props} />}
            >
                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='10%'
                        dataFormat={standardActions({ ver: "bitacora-collaborator" })}
                    />
                    <TableHeaderColumn
                        dataField="username"
                        dataSort
                        width="12%"
                    >
                        USUARIO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="first_name"
                        dataSort
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="last_name"
                        dataSort
                    >
                        APELLIDO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="email"
                        dataSort
                    >
                        EMAIL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="phone_number"
                        dataSort
                    >
                        TELÉFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="amount_generated"
                        dataSort
                        dataFormat={(cell) => <RenderCurrency value={cell} />}
                    >
                        GENERADO
                    </TableHeaderColumn>
                </Grid>
            </Card>
        )
    }
}
