import React, { Component } from 'react';
import SuscripcionCarousel from './SuscripcionCarousel';
import LoadMask from '../../Utils/LoadMask/LoadMask';

export default class Suscripcion extends Component {
    componentWillMount() {
        const { listarPlanes } = this.props;
        listarPlanes();
    }

    render() {
        const { data, loader } = this.props;//constantes

        return (
            <div className="suscripcion-background d-flex">
                <div className="flex-1 my-auto">
                    <LoadMask loading={loader} light>
                        <SuscripcionCarousel data={data} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}
