import React, {Fragment} from 'react'
import moment from "moment";
import Grid from "../../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../../Utils/Grid/StandardActions";


const NotificationByCarTable = (props) => {

    const {dataNotications=[], eliminar} = props;

    return (
        <div className="card card-body border border-dark no-shadow pb-4">
            <p className="text-18 d-block bold py-0 mt-1 mb-3">
                Notificaciones Pendientes
            </p>
            <Grid hover striped data={{results: dataNotications}} loading={false} page={1} pagination={false}>
                <TableHeaderColumn
                    dataField="type_notification"
                    dataFormat={(cell, row) => {
                        return (
                            <span>
                                {cell == 'engine_tuning' && "Afinamiento de motor"}
                                {cell == 'oil_change' && "Cambio de aceite"}
                                {cell == 'annual_check_up' && "Chequeo Anual"}
                            </span>
                        )

                    }}
                >
                    Notificación
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="send_date"
                    dataFormat={cell => moment(cell).format('DD-MM-YYYY')}
                >
                    Fecha de envio
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    width='150PX'
                    dataFormat={standardActions({eliminar})}
                >
                    Acción
                </TableHeaderColumn>
            </Grid>
        </div>
    )
}


export default NotificationByCarTable;
