import React, { Component, Fragment } from 'react';
import CardFormOrden from 'Utils/Cards/CardFormOrden';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { ButtonSalirGuardar, ButtonSalir } from '../../../../Utils/Button';
import Grid from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { StandardActionsTracking } from '../../../../Utils/Grid/StandardActionsTracking';
const warningSvg = require('assets/img/icons/warning.svg');
import { SWALMOD, QUALITY_CONTROL } from '../../../../../../utility/constants';
import { SWALMODERROR } from '../../../../../../utility/constants';
const alerta_img = require('assets/img/icons/warning.svg');
import { default as OperadorDignosticoMecanica } from '../../DiagnosticoMecanica'
import Modal from '../../../../Utils/Modal/ReactModal'
import ModalAddPart from './ModalAddPart'
import { standardActions } from '../../../../Utils/Grid/StandardActions'


export default class Tracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            dataInitial: {},
            editando: false,
        };
    }
    state = {
        BreakLineCenter: {
            whiteSpace: 'normal',
            textAlign: 'center',
        },
        BreakLine: {
            whiteSpace: 'normal',
        },
        AlignCenter: {
            textAlign: 'center',
        },
        fired: false,
    };

    componentWillMount() {
        const idWorkOrder = this.props.match.params.id || 0;
        const { listarTask } = this.props;
        listarTask(idWorkOrder);
    }

    guardarNuevaParte(data) {
        this.setState({ openModal: false })
        const { actualizarTask } = this.props;
        console.log({data: data, idWorkOrder: this.props.match.params.id});
        if (this.state.editando) {
            this.setState({ dataInitial: {}, editando: false })
            actualizarTask({data: data, idWorkOrder: this.props.match.params.id}, this.props.match.params.id)
        }
    }

    F_editar(id) {
        const { data_task } = this.props
        const { observation = ''} = data_task.results.filter((item) => item.id == id)[0]
        this.setState({ dataInitial: { observation, id }, openModal: true, editando: true })
    }

    pauseTimer = (ev) => {
        if(this.state.fired == false) {
            this.setState({ fired: true })
            const id = this.props.match.params.id
            this.props.pauseTimer(id)
        }
    }

    componentWillUnmount() {
        //this.pauseTimer()
    }

    update = data => {
        this.props.editar(this.props.match.params.id, data);
    };

    accion_tracking(id_task, accion) {
        const idWorkOrder = this.props.match.params.id || 0;
        this.props.accionTask(idWorkOrder, id_task, accion);
    }

    errorSwal = text => {
        SWALMODERROR.fire({
            title: '¡Información!',
            text: text,
            confirmButtonText: 'Aceptar',
            reverseButtons: true,
            imageUrl: alerta_img,
            imageAlt: 'Alerta',
            imageWidth: 90,
        });
    };

    rejectTask(id = null, status = null) {
        if (status == true) {
            if (id) {
                SWALMOD.fire({
                    title: '¿Está seguro de rechazar la tarea? ',
                    text: '',
                    showCancelButton: true,
                    confirmButtonText: '¡Sí, rechazar!',
                    cancelButtonText: 'No',
                    reverseButtons: true,
                    imageUrl: warningSvg,
                    imageAlt: 'Alerta',
                    imageWidth: 90,
                }).then(result => {
                    if (result.value) {
                        const idWorkOrder = this.props.match.params.id || 0;
                        this.props.rechazarTarea(idWorkOrder, id);
                    }
                });
            }
        } else {
            this.errorSwal(
                'No has comenzado a contar el tiempo, por favor seleccionalo.'
            );
        }
    }

    approveTask(id = null, status = null) {
        if (status == true) {
            if (id) {
                SWALMOD.fire({
                    title: '¿Está seguro de aprobar la tarea? ',
                    text: '',
                    showCancelButton: true,
                    confirmButtonText: '¡Sí, aprobar!',
                    cancelButtonText: 'No',
                    reverseButtons: true,
                    imageUrl: warningSvg,
                    imageAlt: 'Alerta',
                    imageWidth: 90,
                }).then(result => {
                    if (result.value) {
                        const idWorkOrder = this.props.match.params.id || 0;
                        this.props.aprobarTarea(idWorkOrder, id);
                    }
                });
            }
        } else {
            this.errorSwal(
                'No has comenzado a contar el tiempo, por favor seleccionalo.'
            );
        }
    }

    finalizarFlujo(status) {
        if (status == true) {
            let text = '',
                rejected = false;
            if (this.props.data_task.status_order != QUALITY_CONTROL) {
                text = `¡Ya no podrás modificar el estado de tus tareas!${
                    this.props.data_task.completed_tasks
                        ? '.'
                        : ', aún tienes tareas sin finalizar.'
                } `;
            }

            this.props.data_task.results.forEach(task => {
                if (task.status < 40) {
                    rejected = true;
                    text =
                        'La orden se trasladará al estado "En proceso", ya que tiene tareas rechazadas o pendientes.';
                }
            });

            SWALMOD.fire({
                title: '¿Finalizar el flujo?',
                text,
                showCancelButton: true,
                confirmButtonText: '¡Sí, finalizar!',
                cancelButtonText: 'No',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then(result => {
                if (result.value) {
                    const idWorkOrder = this.props.match.params.id || 0;
                    if (rejected) this.props.rechazarOrdenMecanica(idWorkOrder);
                    else this.props.finalizarFlujo(idWorkOrder);
                }
            });
        } else {
            this.errorSwal(
                'No has pausado el tiempo, por favor detenlo.'
            );
        }
    }

    deleteTask(id=null) {
        if(id) {
            SWALMOD.fire({
                title: '¿Está seguro de eliminar la tarea? ',
                text: '',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then((result) => {
                if (result.value) {
                    const idWorkOrder = this.props.match.params.id || 0
                    this.props.eliminarTarea(idWorkOrder, id)
                }
            });
        }
    }

    render() {
        //Value
        const { loader, data_task = {} } = this.props;
        const {
            order_number = '--',
            status_order = '',
            status_in_action = {},
        } = data_task || {};

        //window.addEventListener("beforeunload", this.pauseTimer);

        return (
            <Fragment>
                <Modal
                    show_modal={this.state.openModal}
                >
                    <div className='card-content-form2 px-0 px-0'>
                        <div className="col-12 col-lg-8 px-0">
                            <ModalAddPart
                                showModal={() => {
                                    this.setState({ openModal: false, dataInitial: {}, editando: false, })
                                }}
                                onSubmit={(e) => {
                                    this.guardarNuevaParte(e)
                                }}
                                initialValues={{ modalOpen: true, ...this.state.dataInitial }}
                                editarNombre={this.state.editando && !this.state.dataInitial.part_identifier}
                                editando={this.state.editando}
                            />
                        </div>
                    </div>
                </Modal>
            <CardFormOrden
                titulo={
                    status_order != QUALITY_CONTROL ? 'TRABAJOS APROBADOS' : ''
                }
                orden={order_number}
                timer={
                    data_task && status_order == QUALITY_CONTROL ? (
                        <div
                            className="mt-1 mt-md-2 d-flex"
                            style={{ height: 25 }}
                        >
                            {StandardActionsTracking({
                                play: status_in_action.play,
                                no_ver_finalized: true,
                                finalized: status_in_action.finalized,
                                onChange: this.accion_tracking.bind(this),
                            })(data_task.id)}
                            <span className="text-18 ml-3 my-auto">
                                {data_task.time}
                            </span>
                        </div>
                    ) : (
                        ''
                    )
                }
            >
                <LoadMask loading={loader} blur>
                    <div className="row card-form justify-content-center align-items-center">
                        <div className="card padding-custom mb-3">
                            <div style={{ padding: 20 }}>
                                <h3 className="card-title">
                                    INFORMACIÓN DEL VEHÍCULO
                                </h3>
                                <div className="card-body">
                                    <div className="row">
                                        <div style={{ fontWeight: 'bold' }}>
                                            VIN:&nbsp;
                                        </div>
                                        {data_task.car
                                            ? data_task.car.vin
                                            : '---'}
                                    </div>
                                    <div className="row">
                                        <div style={{ fontWeight: 'bold' }}>
                                            MATRÍCULA:&nbsp;
                                        </div>
                                        {data_task.car
                                            ? data_task.car.car_registration
                                            : '---'}
                                    </div>
                                    <div className="row">
                                        <div style={{ fontWeight: 'bold' }}>
                                            MARCA:&nbsp;
                                        </div>
                                        {data_task.car
                                            ? data_task.car.brand
                                            : '---'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {status_order == QUALITY_CONTROL && (
                            <div className="col-12 mb-0 mb-md-4">
                                <span className="negro bold text-25 pb-1">
                                    Control de calidad
                                </span>
                            </div>
                        )}
                        <div className="col-12 mb-0 mb-md-4 mt-4">
                            <Grid
                                hover
                                striped
                                data={data_task}
                                loading={loader}
                                pagination={false}
                            >
                                {status_order != QUALITY_CONTROL && (
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        width="110px"
                                        dataFormat={(cell, row) => {
                                            const { status_in_action = {} } =
                                                row;
                                            const {
                                                play = true,
                                                finalized = true,
                                            } = status_in_action;
                                            return StandardActionsTracking({
                                                play,
                                                finalized,
                                                onChange:
                                                    this.accion_tracking.bind(
                                                        this
                                                    ),
                                            })(cell, row);
                                        }}
                                        thStyle={this.state.AlignCenter}
                                        tdStyle={this.state.BreakLineCenter}
                                    />
                                )}
                                <TableHeaderColumn
                                    dataField="name"
                                    dataSort
                                    isKey
                                    thStyle={this.state.AlignCenter}
                                    tdStyle={this.state.BreakLineCenter}
                                >
                                    NÚMERO DE PARTE
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="quotation"
                                    dataSort
                                    dataFormat={(cell, row) =>
                                        cell.replacement || '--'
                                    }
                                    thStyle={this.state.AlignCenter}
                                    tdStyle={this.state.BreakLineCenter}
                                >
                                    COMPONENTE Y SISTEMA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="description"
                                    dataSort
                                    thStyle={this.state.AlignCenter}
                                    tdStyle={this.state.BreakLineCenter}
                                >
                                    DESCRIPCIÓN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="observation"
                                    dataSort
                                    thStyle={this.state.AlignCenter}
                                    tdStyle={this.state.BreakLineCenter}
                                >
                                    OBSERVACIONES
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="time"
                                    dataSort
                                    width="90px"
                                    thStyle={this.state.AlignCenter}
                                    tdStyle={this.state.BreakLineCenter}
                                >
                                    TIEMPO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    width='90px'
                                    dataFormat={standardActions({ F_editar: this.F_editar.bind(this) })}
                                >
                                    ACCION
                                </TableHeaderColumn>
                                {status_order == QUALITY_CONTROL && (
                                    <TableHeaderColumn
                                        dataField="status"
                                        dataSort
                                        width="125px"
                                        dataFormat={(cell, row) =>
                                            cell == 40 ? (
                                                'Finalizado'
                                            ) : (
                                                <span className="rojo">
                                                    {' '}
                                                    No Finalizado{' '}
                                                </span>
                                            )
                                        }
                                        thStyle={this.state.AlignCenter}
                                        tdStyle={this.state.BreakLineCenter}
                                    >
                                        ESTADO
                                    </TableHeaderColumn>
                                )}
                                {status_order == QUALITY_CONTROL && (
                                    <TableHeaderColumn
                                        dataField="status"
                                        dataSort
                                        width="125px"
                                        dataFormat={(cell, row) => {
                                            if(cell == 40) { // FINALIZADA
                                                return (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary px-3"
                                                        style={{
                                                            width: '100px',
                                                        }}
                                                        onClick={this.rejectTask.bind(
                                                            this,
                                                            row.id,
                                                            status_in_action.play
                                                        )}
                                                    >
                                                        Rechazar
                                                    </button>
                                                )
                                            } else if(cell == 30) { // PAUSADA/RECHAZADA
                                                return (
                                                    <button
                                                        className="btn btn-success px-3"
                                                        style={{
                                                            width: '100px',
                                                        }}
                                                        onClick={this.approveTask.bind(
                                                            this,
                                                            row.id,
                                                            status_in_action.play
                                                        )}
                                                    >
                                                        Aprobar
                                                    </button>
                                                )
                                            } else if(cell == 10) { // POR COMENZAR
                                                return (
                                                    <button
                                                        className="btn btn-primary px-3"
                                                        style={{ width: "100px" }}
                                                        onClick={this.deleteTask.bind(this, row.id)}
                                                    >
                                                        Eliminar
                                                    </button>
                                                )
                                            }
                                        }}
                                        thStyle={this.state.AlignCenter}
                                        tdStyle={this.state.BreakLineCenter}
                                    />
                                )}
                            </Grid>
                        </div>
                    </div>
                    {status_order == QUALITY_CONTROL &&
                    <React.Fragment>
                        <br/><br/><br/>
                        <OperadorDignosticoMecanica isQA {...this.props} />
                    </React.Fragment>
                    }
                    {status_order == QUALITY_CONTROL ?
                        <ButtonSalirGuardar link="/mywork-orders" btnText2="Finalizar flujo" link2={this.finalizarFlujo.bind(this, status_in_action.pause)} />
                        :
                        <ButtonSalir link="/mywork-orders" />
                    }
                </LoadMask>
            </CardFormOrden>
            </Fragment>
        );
    }
}
