import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import icono from '../../../../../assets/img/icons/search.svg';
import classnames from 'classnames'
import { constants } from '../../../../redux/modules/cuenta/login';
class HeaderBtnSearch extends Component {
    render() {
        //Props de search
        const { search, link, textBtn, textBtn2, link2, searchChange, placeholder } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-sm-row col-12 mb-4 px-0">
                    {(link !== undefined || textBtn2 !== undefined) &&
                        <div className={`mb-3 mb-sm-0 d-flex flex-row flex-sm-row justify-content-start align-items-center justify-content-lg-start mx-1 px-0 ${(link !== undefined || textBtn2 !== undefined) ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                            {(textBtn2 !== undefined) && (
                                <Link to={link2} className={`btn btn-outline-gris text-16 mr-4 ${search !== undefined ? "btn-flex3" : "btn-flex2"} ${textBtn2 == "Salir" ? "btn-salir " : ''}`}>{textBtn2}</Link>
                            )}
                            {(link !== undefined) && (
                                <Link to={link} className={`btn btn-primary text-18 bold${search !== undefined ? "btn-flex3" : "btn-flex2"}`}>{textBtn}</Link>
                            )}
                        </div>
                    }
                    <div className="espacio1" />
                    {(search !== undefined) && (
                        <div className={`d-flex flex-column flex-1 input-search1 px-0 ${search !== undefined ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                            <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

class HeaderBtnSearch1 extends Component {
    render() {
        const { searchEP, link, textBtn, textBtn2, link2, searchEPChange, placeholder } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-sm-row col-12 mb-4 px-0">
                    {(link !== undefined || textBtn2 !== undefined) &&
                        <div className={`mb-3 mb-sm-0 d-flex flex-row flex-sm-row justify-content-start align-items-center justify-content-lg-start mx-1 px-0 ${(link !== undefined || textBtn2 !== undefined) ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                            {(textBtn2 !== undefined) && (
                                <Link to={link2} className={`btn btn-outline-gris text-16 mr-4 ${searchEP !== undefined ? "btn-flex3" : "btn-flex2"} ${textBtn2 == "Salir" ? "btn-salir " : ''}`}>{textBtn2}</Link>
                            )}
                            {(link !== undefined) && (
                                <Link to={link} className={`btn btn-primary text-18 bold${searchEP !== undefined ? "btn-flex3" : "btn-flex2"}`}>{textBtn}</Link>
                            )}
                        </div>
                    }
                    <div className="espacio1" />
                    {(searchEP !== undefined) && (
                        <div className={`d-flex flex-column flex-1 input-search1 px-0 ${searchEP !== undefined ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                            <SearchInput search={searchEP} searchChange={searchEPChange} placeholder={placeholder} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

class HeaderSearch extends Component {
    render() {
        //Props de search
        const { search, searchChange, placeholder } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-sm-row col-12 mb-4 px-0">
                    <div className={`mb-3 mb-sm-0 d-flex flex-row flex-sm-row justify-content-start align-items-center justify-content-lg-start mx-1 px-0 `}>
                    </div>
                    <div className="espacio1" />
                    {(search !== undefined) && (
                        <div className={`d-flex flex-column flex-1 input-search1 px-0 ${search !== undefined ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                            <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

class HeaderOneFilter extends Component {
    render() {
        const { filtro1, filtroText1 } = this.props;
        //Props de search
        const { search, link, textBtn, textBtn2, disabledBtn2, btn2Click, searchChange, placeholder } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-lg-row col-12 mb-4 px-0">
                    <div className={`mb-3 mb-lg-0 d-flex flex-row justify-content-start align-items-center justify-content-lg-start px-0 ${(link !== undefined || textBtn2 !== undefined) ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                        {(link !== undefined) && (
                            <Link to={link} className={`btn btn-primary btn-flex mx-1`}>{textBtn}</Link>
                        )}
                        {(textBtn2 !== undefined) && (
                            <button onClick={btn2Click} disabled={disabledBtn2 ? disabledBtn2 : false} className={`btn btn-secondary mx-1 btn-flex`}>{textBtn2}</button>
                        )}
                    </div>
                    <div className={`d-flex flex-column flex-sm-row justify-content-start align-items-center justify-content-lg-start px-0 mx-0 flex-1`}>
                        <div className="d-flex flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            <span className="mr-2 ">{filtroText1 || ""}</span>
                            {filtro1 && filtro1}
                        </div>
                        {(search !== undefined) && (
                            <div className={`flex-1 input-header-2`}>
                                <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

// con la misma estructura de la clase HeaderTwoFilter has otra funcion para 3 filtros
class HeaderThreeFilter extends Component {
    render() { 
        const { filtro1, filtroText1, filtro2, filtroText2, filtro3, filtroText3 } = this.props;
        const { search, link, textBtn, textBtn2, disabledBtn2, btn2Click, searchChange, placeholder } = this.props;      
        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-lg-row col-12 mb-4 px-0">
                    <div className={`mb-3 mb-lg-0 d-flex flex-row justify-content-start align-items-center justify-content-lg-start px-0 ${(link !== undefined || textBtn2 !== undefined) ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                        {(link !== undefined) && (
                            <Link to={link} className={`btn btn-primary btn-flex mx-1`}>{textBtn}</Link>
                        )}
                        {(textBtn2 !== undefined) && (
                            <button onClick={btn2Click} disabled={disabledBtn2 ? disabledBtn2 : false} className={`btn btn-secondary mx-1 btn-flex`}>{textBtn2}</button>
                        )}
                    </div>
                    <div className={`d-flex flex-column flex-sm-row justify-content-start align-items-center justify-content-lg-start px-0 mx-0 flex-1`}>
                        <div className="w-100 d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            <span className="mr-2 ">{filtroText1 || "Ingrese el titulo"}</span>
                            {filtro1 && filtro1}
                        </div>
                        <div className="w-100 d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            <span className="mr-2 ">{filtroText2 || "Ingrese el titulo"}</span>
                            {filtro2 && filtro2}
                        </div>
                        <div className="w-100 d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            <span className="mr-2 ">{filtroText3 || "Ingrese el titulo"}</span>
                            {filtro3 && filtro3}
                        </div>
                        {(search !== undefined) && (
                            <div className="d-flex w-100 flex-1 input-search1 px-0">
                                <div className="d-flex flex-1 flex-column flex-sm-row col-12 mb-4 px-0">
                                    <div className={`mb-3 mb-sm-0 d-flex flex-row flex-sm-row justify-content-start align-items-center justify-content-lg-start mx-1 px-0 `}>
                                    </div>
                                    <div className="espacio1" />
                                    {(search !== undefined) && (
                                        <div className={`d-flex flex-column flex-1 input-search1 px-0 ${search !== undefined ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                                            <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
}}  

class HeaderTwoFilter extends Component {
    render() {
        const { filtro1, filtroText1, filtro2, filtroText2 } = this.props;
        //Props de search
        const { search, link, textBtn, textBtn2, disabledBtn2, btn2Click, searchChange, placeholder } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-lg-row col-12 mb-4 px-0">
                    <div className={`mb-3 mb-lg-0 d-flex flex-row justify-content-start align-items-center justify-content-lg-start px-0 ${(link !== undefined || textBtn2 !== undefined) ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                        {(link !== undefined) && (
                            <Link to={link} className={`btn btn-primary btn-flex mx-1`}>{textBtn}</Link>
                        )}
                        {(textBtn2 !== undefined) && (
                            <button onClick={btn2Click} disabled={disabledBtn2 ? disabledBtn2 : false} className={`btn btn-secondary mx-1 btn-flex`}>{textBtn2}</button>
                        )}
                    </div>
                    <div className={`d-flex flex-column flex-sm-row justify-content-start align-items-center justify-content-lg-start px-0 mx-0 flex-1`}>
                        <div className="w-100 d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            <span className="mr-2 ">{filtroText1 || "Ingrese el titulo"}</span>
                            {filtro1 && filtro1}
                        </div>
                        <div className="w-100 d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            <span className="mr-2 ">{filtroText2 || "Ingrese el titulo"}</span>
                            {filtro2 && filtro2}
                        </div>
                        {(search !== undefined) && (
                            <div className="d-flex w-100 flex-1 input-search1 px-0">
                                <div className="d-flex flex-1 flex-column flex-sm-row col-12 mb-4 px-0">
                                    <div className={`mb-3 mb-sm-0 d-flex flex-row flex-sm-row justify-content-start align-items-center justify-content-lg-start mx-1 px-0 `}>
                                    </div>
                                    <div className="espacio1" />
                                    {(search !== undefined) && (
                                        <div className={`d-flex flex-column flex-1 input-search1 px-0 ${search !== undefined ? "" : "d-sm-none d-md-none d-lg-inline"}`}>
                                            <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

class SearchInput extends Component {
    static propTypes = {
        search: PropTypes.string.isRequired,
        searchChange: PropTypes.func.isRequired,
    };

    render() {
        const { search, searchChange, placeholder } = this.props;

        return (
            <div className="toolbar-search">
                <input
                    className="form-control search-input bg-white"
                    style={{ backgroundImage: `url(${icono})` }}
                    id="buscador"
                    type="text"
                    placeholder={placeholder ? placeholder : "Buscar..."}
                    ref={(node) => {
                        this.buscar = node;
                        if (this.buscar) {
                            this.buscar.value = search;
                        }
                    }}
                    onChange={() => {
                        if (this.buscar) {
                            searchChange(this.buscar.value);
                        }
                    }}
                    autoComplete="off"
                />
            </div>
        );
    }
}

class HeaderFourFilter extends Component {
    render() {
        const { filtro1, filtro2, filtro3, filtroText3, filtro4, filtroText4 } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-lg-row col-lg-8 col-md-12 col-sm-12  mb-4 px-0">

                    <div className={`d-flex flex-column flex-sm-row justify-content-start justify-content-lg-start px-0 mx-0 flex-1`}>
                        <div className="d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            {filtro1 && filtro1}
                        </div>
                        <div className="d-flex ml-1 mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            {filtro2 && filtro2}
                        </div>
                        <div className="d-flex ml-2 align-items-center">
                            <span className="">{filtroText3 || "Ingrese el titulo"}</span>
                        </div>
                        <div className="d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            {filtro3 && filtro3}
                        </div>
                        <div className="d-flex ml-2 align-items-center mb-lg-0 mb-sm-0 input-header-1">
                            <span className="">{filtroText4 || "Ingrese el titulo"}</span>
                        </div>
                        <div className="d-flex mx-1 flex-1 align-items-center mb-3 mb-lg-0 mb-sm-0 input-header-1">
                            {filtro4 && filtro4}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

class SearchInput2 extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
        this.searchRef = React.createRef();
    };

    static propTypes = {
        search: PropTypes.string.isRequired,
        searchChange: PropTypes.func.isRequired,
    };

    toogleSearch = () => {
        this.setState({ open: !this.state.open }, () => {
            if (this.state.open) {
                this.searchRef.current.focus();
            }
        });
    };


    render() {
        const { search, searchChange, placeholder } = this.props;
        let expanded = false;
        let search_active = false;
        if (this.state.open || search !== "") {
            expanded = true
        }
        if (search !== "") {
            search_active = true;
        }
        return (
            <div className="container-search">
                <input
                    className={classnames('form-control ', 'input--search ', { expanded, search_active })}
                    type="text"
                    placeholder="Buscar..."
                    ref={this.searchRef}
                    onChange={() => {
                        if (this.searchRef.current) {
                            searchChange(this.searchRef.current.value);
                        }
                    }}
                    value={search}
                    onBlur={this.toogleSearch}
                    autoComplete="off"
                />
                <div className="icon-search d-flex align-items-center justify-content-center" onClick={this.toogleSearch} >
                    <img src={require('assets/img/icons/search.svg')} alt="Buscar" className="action_img" />
                </div>
            </div>
        );
    }
}

export { HeaderBtnSearch, HeaderBtnSearch1, HeaderSearch, HeaderOneFilter, HeaderTwoFilter, HeaderFourFilter, SearchInput, SearchInput2, HeaderThreeFilter }
