import React, { Component, Fragment } from 'react'
import { Field, reduxForm } from 'redux-form';
import { renderField, renderNumber, renderFilePicker, SelectField, renderDatePicker, renderCurrency } from '../../../Utils/renderField/renderField';
import { ButtonSalirGuardar } from '../../../Utils/Button';
import { validate } from 'validate-redux-form';
import { validationGarage, validationAdministrador, validationConfiguration } from '../../../../../utility/validationForm'
import { COUNTRIES } from '../../../../../utility/countries';
import { ACTIVE_GARAGE, PENDING_GARAGE } from '../../../../../utility/constants';
import { connect } from 'react-redux'
import { Link } from "react-router-dom";


let TalleresForm = (props) => {
    const { handleSubmit, setImg, item = {}, logo } = props;
    const { plan = {} } = item || {}
    const { enable_type_work_orders = 0 } = plan || {}
    const TYPE_ORDER = [
        { "name": "Mecánica", "id": "mechanic" },
        { "name": "Enderezado y pintura", "id": "body_shop" }
    ]
    return (
        <Fragment>
            <div className='card-content-form2 px-0 px-0'>
                <div className="col-12 col-lg-9">
                    <form
                        id="ConfigurationForm"
                        onSubmit={handleSubmit}
                    >
                        <div className="row card-form">
                            <div className="col-md-12 padding-custom mb-3 ">
                                <label htmlFor="logo">Logo</label>
                                <Field
                                    name="logo"
                                    photo={logo || null}
                                    component={renderFilePicker}
                                    setFile={setImg}
                                />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <span className="d-block">Nombre</span>
                                <span className="text-18 bold negro d-block">{item.name}</span>
                            </div>

                            <div className="col-md-6 padding-custom mb-3">
                                <span className="d-block">ID</span>
                                <span className="text-18 bold negro d-block">{item.id}</span>
                            </div>

                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="tax_percentage">Porcentaje de impuesto a pagar en repuesto*</label>
                                <Field name="tax_percentage" decimalScale={2} suffix="%" component={renderNumber} className="form-control" maxLength={validationConfiguration.tax_percentage.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="phone_number">Teléfono*</label>
                                <Field name="phone_number" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationConfiguration.phone_number.MaxLength} />
                            </div>
                            <div className="col-md-12 padding-custom mb-3">
                                <label htmlFor="address">Dirección*</label>
                                <Field name="address" component={renderField} type="text" className="form-control" maxLength={validationConfiguration.address.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="email">E-mail*</label>
                                <Field name="email" component={renderField} type="email" className="form-control" maxLength={validationConfiguration.email.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="email_notifications">E-mail para notificaciones*</label>
                                <Field name="email_notifications" component={renderField} type="email" className="form-control" maxLength={validationConfiguration.email_notifications.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="web_page">Pagina Web</label>
                                <Field name="web_page" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="facebook">Facebook</label>
                                <Field name="facebook" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="instagram">Instagram</label>
                                <Field name="instagram" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="twitter">Twitter</label>
                                <Field name="twitter" component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="cost_hour">Tarifa de costo por hora</label>
                                <Field name="cost_hour" component={renderCurrency} className="form-control" />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="hourly_sale">Tarifa de venta por hora</label>
                                <Field name="hourly_sale" component={renderCurrency} className="form-control" />

                            </div>
                            {enable_type_work_orders > 1 ?
                                <div className="col-md-6 padding-custom mb-3">
                                    <label htmlFor="prefix">Tipos de orden activos:</label>
                                    <label htmlFor="prefix" className="text-16 bold verde d-block mb-0">- Mecánica</label>
                                    <label htmlFor="prefix" className="text-16 bold verde d-block mb-0">- Enderezado y pintura</label>
                                    {enable_type_work_orders > 2 &&
                                        <label htmlFor="prefix" className="text-16 bold verde d-block mb-0">- Avalúo</label>
                                    }
                                </div>
                                :
                                <div className="col-md-6 padding-custom mb-3">
                                    <label htmlFor="type_order_active">Tipo de orden</label>
                                    <Field
                                        name="type_order_active"
                                        valueKey="id"
                                        labelKey="name"
                                        options={TYPE_ORDER}
                                        component={SelectField}
                                    />
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <ButtonSalirGuardar formSubmit="ConfigurationForm" link="/" />
        </Fragment>
    );
}

TalleresForm = reduxForm({
    form: 'ConfigurationForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            tax_percentage: validationConfiguration.tax_percentage.Combine,
            email_notifications: validationConfiguration.email_notifications.Combine,
            email: validationConfiguration.email.Combine,
            phone_number: validationConfiguration.phone_number.Combine,
            address: validationConfiguration.address.Combine,
        })

    }
})(TalleresForm);


TalleresForm = connect(state => {
    const form = state.form.ConfigurationForm;
    let logo = "";
    if (form && form.values) {
        const values = form.values;
        logo = values.logo || ""
    }
    return {
        logo
    }
})(TalleresForm);

export default TalleresForm;
