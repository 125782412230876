import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { getMe } from "../cuenta/login";

const LOADER = 'LOADER_CONFIGURACIONES';
const DATA = 'DATA_CONFIGURACIONES';
const ITEM_DATA = 'ITEM_CONFIGURACIONES';
const PAGE = 'PAGE_CONFIGURACIONES';
const ORDERING = 'ORDERING_CONFIGURACIONES';
const SEARCH = 'SEARCH_CONFIGURACIONES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});


// ------------------------------------
// Actions
// ------------------------------------

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'garage'}/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('ConfigurationForm', response));
    }).catch(() => {
        dispatch(push('/'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().configuraciones;
    const params = { page };

    if (resource.ordering)
        params.ordering = resource.ordering;
    if (resource.search)
        params.search = resource.search;

    dispatch(setLoader(true));
    api.get('garage_branches', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const editar = (id, data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`${'garage'}/${id}`, data, attachments)
        .then(() => {
            NotificationManager.success('Registro actualizado', 'Éxito', 3000);
            dispatch(leer(id))
            dispatch(getMe())
            dispatch(push('/configurations'));
        })
        .catch((error) => {
            let mensaje = 'Error en la edición, vuelve a intentarlo';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
const adquirirOrdenExtra = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${'garage'}/order_extra`, data)
        .then(() => {
            NotificationManager.success('Compra realizado con éxito', 'Éxito', 3000);
            dispatch(push('/login'));
        })
        .catch((error) => {
            let mensaje = 'Error en la compra, vuelve a intentarlo';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('ConfigurationForm', {}));
};

const searchChange = search => (dispatch, getStore) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().configuraciones.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const actions = {
    listar,
    editar,
    leer,
    searchChange,
    onSortChange,
    cleanForm,
    adquirirOrdenExtra,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
