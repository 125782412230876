import React, { Component, Fragment } from 'react'
import queryString from 'query-string';

export default class CambiarEstado extends Component {

    state = {
        ACCEPTED: 20,
        REJECTED: 30,
    }

    componentDidMount(){
        const token = this.props.match.params.token
        const params = queryString.parse(this.props.location.search)
        const state = this.state[params.state]
        this.props.change_order_state({ token, state })
    }

    render(){
        return <React.Fragment/>
    }
}