import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { NOT_ASSIGNED } from '../../../../utility/constants';
import { NotificationManager } from 'react-notifications';
import { push } from 'react-router-redux';
import { initialize as initializeForm, destroy } from "redux-form";

const LOADER = 'LOADER_AVALUOS';
const DATA = 'DATA_AVALUOS';
const DATA_FACTURA = 'DATA__FACTURA_AVALUOS';
const ITEM_DATA = 'ITEM_AVALUOS';
const ITEM_ORDER = 'ORDER_AVALUOS';
const PAGE = 'PAGE_AVALUOS';
const ORDERING = 'ORDERING_AVALUOS';
const SEARCH = 'SEARCH_AVALUOS';
const ESTADO_AVALUO_TAB = 'ESTADO_AVALUO_TAB';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setDataFactura = data_factura => ({
    type: DATA_FACTURA,
    data_factura,
});
const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setOrder = order => ({
    type: ITEM_ORDER,
    order,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setEstadoAvaluoTab = estado_avaluo_tab => ({
    type: ESTADO_AVALUO_TAB,
    estado_avaluo_tab,
});
// ------------------------------------
// Actions
// ------------------------------------
const timeHistory = (idWorkOrder) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`my_orders_appraisal/${idWorkOrder}/time_history`).then((response) => {
        dispatch(setDataFactura(response));
    }).catch(() => {
        dispatch(push('/appraisal-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`work_orders/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error en la eliminación, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`work_orders/${id}`, data, attachments)
        .then(() => {
            NotificationManager.success('Registro actualizado', 'Éxito', 3000);
            dispatch(leer(id))
            if (data && data.close_order) {
                dispatch(push(`/work-order/${id}/order_closing/pdf`));
            } else {
                dispatch(push('/appraisal-orders'));
            }
        })
        .catch((error) => {
            let mensaje = 'Error en la edición, vuelve a intentarlo';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.type = 'order_appraisal'
    api.get(`${'work_orders'}/${id}`, params).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('WorkOrdersForm', response));
    }).catch(() => {
        dispatch(push('/appraisal-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const idOrder = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.type = 'order_appraisal'
    api.get(`${'garage'}/${id}`, params).then((response) => {
        dispatch(setOrder(response));
        console.log(response)
        // dispatch(initializeForm('WorkOrdersForm', response));
    }).catch(() => {
        dispatch(push('/appraisal-orders'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data = {}, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('work_orders', data, attachments)
        .then(() => {
            NotificationManager.success('Registro creado', 'Éxito', 3000);
            dispatch(push('/appraisal-orders'));
        })
        .catch((error) => {
            let mensaje = 'Error en la creación, vuelve a intentarlo';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().adminAvaluos;
    const params = { page, status: resource.estado_avaluo_tab };

    if (resource.ordering)
        params.ordering = resource.ordering;
    if (resource.search)
        params.search = resource.search;
    params.type = 'order_appraisal'
    dispatch(setLoader(true));
    api.get('work_orders', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const estadoAvaluoTab = estado => (dispatch) => {
    dispatch(setEstadoAvaluoTab(estado));
    dispatch(listar());
};


const searchChange = search => (dispatch, getStore) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().adminAvaluos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const destroyForm = () => (dispatch) => {
    dispatch(destroy('WorkOrdersForm'));
};



export const actions = {
    listar,
    searchChange,
    onSortChange,
    estadoAvaluoTab,
    crear,
    leer,
    editar,
    eliminar,
    destroyForm,
    idOrder,
    timeHistory,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA_FACTURA]: (state, { data_factura }) => {
        return {
            ...state,
            data_factura,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [ITEM_ORDER]: (state, { order }) => {
        return {
            ...state,
            order,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [ESTADO_AVALUO_TAB]: (state, { estado_avaluo_tab }) => {
        return {
            ...state,
            estado_avaluo_tab,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_factura: {
        results: [],
    },
    item: {},
    order: {},
    page: 1,
    ordering: '',
    search: '',
    estado_avaluo_tab: NOT_ASSIGNED,
};

export default handleActions(reducers, initialState);
