import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch } from '../../../Utils/Header'

export default class Listar extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <Card
                titulo="Aseguradoras"
                header={
                    <HeaderBtnSearch
                        link="insurance-carrier/create"
                        textBtn="Crear aseguradora"
                        {...this.props}
                    />
                }
            >
                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='15%'
                        dataFormat={standardActions({ editar: "insurance-carrier", eliminar })}
                    />
                    <TableHeaderColumn
                        dataField="name"
                        dataSort
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="email"
                        dataSort
                    >
                        E-MAIL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="phone_number"
                        dataSort
                    >
                        TELÉFONO
                    </TableHeaderColumn>
                </Grid>
            </Card>
        )
    }
}
