import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemState
} from 'react-accessible-accordion';
import {
    CUSTOMER_MANAGEMENT,
    WORK_ORDER_MANAGEMENT,
    MY_WORK_ORDERS,
    USER_MANAGEMENT_GARAGE,
    ROLE_MANAGEMENT_GARAGE,
    SYSTEMS_AND_COMPONENTS_MANAGEMENT,
    INSURANCE_MANAGEMENT,
    WORKSHOP_SETUP,
    REPORTS_GARAGE,
    HISTORY_OF_NOTIFICATIONS_AND_INCOMPLETE_ORDERS,
    DASHBOARD_GARAGE,
} from '../../../../utility/constants';

const dashboard = require("assets/img/icons/menu.svg");
const dashboard_activo = require("assets/img/icons/menu01.svg");
const settings = require("assets/img/icons/settings.svg");
const settings_activo = require("assets/img/icons/settings01.svg");
const talleres = require("assets/img/icons/talleres.svg")
const talleres_activo = require("assets/img/icons/talleres01.svg")
const cliente_activo = require("assets/img/icons/customer01.svg");
const cliente = require("assets/img/icons/customer.svg")
const orden_activo = require("assets/img/icons/order_admin_active.svg");
const orden = require("assets/img/icons/order_admin.svg")
const suscripcion_activo = require("assets/img/icons/suscripcion01.svg");
const suscripcion = require("assets/img/icons/suscripcion.svg")
const sucursal_activo = require("assets/img/icons/sucursales01.svg");
const sucursal = require("assets/img/icons/sucursales.svg")
const moneda_activo = require("assets/img/icons/balance01.svg");
const moneda = require("assets/img/icons/balance.svg")
const cuenta_activo = require("assets/img/icons/cuenta01.svg");
const cuenta = require("assets/img/icons/cuenta.svg")
const solicitud_activo = require("assets/img/icons/solicitudes01.svg");
const solicitud = require("assets/img/icons/solicitudes.svg")
const image_placeholder = require('assets/img/place_holder/image_placeholder.png');
const point = require('assets/img/punto_sidebar.svg');
const point_disabled = require('assets/img/punto_sidebar_inactivo.svg');
const settings_user = require('assets/img/settings_user.svg')
const defaultAvatar = require('assets/img/avatar-placeholder.png');
const my_orders = require('assets/img/icons/myorders.svg');
const my_orders_active = require('assets/img/icons/myorders_active.svg');
const reporte_activo = require("assets/img/dashboard/statistics_rojo.svg");
const reporte = require("assets/img/icons/statistics.svg")
const order_incomplete_activo = require("assets/img/icons/order_incomplete_active.png");
const order_incomplete = require("assets/img/icons/order_incomplete.png")
const notification1 = require("assets/img/icons/notificaciones-vencidas.png")
const notification2 = require("assets/img/icons/notificaciones-vencidas-2.png")

const PERFIL = 'PERFIL';
const MODULO_DASHBOARD = 'DASHBOARD';
const MODULO_CONFIGURACION = 'CONFIGURACION';
const MODULO_TALLER = 'TALLER';
const MODULO_CLIENTE = 'CLIENTE';
const MODULO_ORDEN = 'ORDEN';
const MODULO_ORDEN_INCOMPLETE = 'ORDEN_INCOMPLETE';
const MODULO_OVERDUE_NOTIFICARIONES = 'MODULO_OVERDUE_NOTIFICARIONES';
const MODULO_MISORDENES = 'MISORDENES';
const MODULO_SUSCRIPCION = 'SUSCRIPCION';
const MODULO_SUCURSAL = 'SUCURSAL';
const MODULO_MONEDA = 'MONEDA';
const MODULO_CUENTA = 'CUENTA';
const MODULO_SOLICITUD = 'SOLICITUD';
const MODULO_REPORTE = 'REPORTE';


class SideBar extends Component {
    state = {
        isFirstSelected: false,
        isSidebarExpanded: false,
    }
    constructor(props) {
        super(props);
    }
    mouseEnter = () => {
        this.setState({ isSidebarExpanded: true })
    }
    mouseLeave = () => {
        this.setState({ isSidebarExpanded: false })
    }

    /**
     * En base al path actual se determina si un item se marca como activo o no.
     * @param idModulo Una de las constantes definidas con el prefijo MODULO_
     * @param path la URL a comparar.
     * @param isItemSelected indica si el item en cuestión se le dió clic o no.
     */
    isItemSelected(idModulo, path, isItemSelected) {
        const isFirstSelected = this.state.isFirstSelected;
        isItemSelected && !isFirstSelected && (this.setState({ isFirstSelected: true }));
        let isMatch = false;
        const isSidebarExpanded = this.state.isSidebarExpanded;
        switch (idModulo) {
            case PERFIL:
                isMatch = path === '#/user-profile';
                break;
            case MODULO_DASHBOARD:
                isMatch = path === '#/';
                break;
            case MODULO_CLIENTE:
                isMatch = path === '#/clients';
                break;
            case MODULO_TALLER:
                isMatch = path === '#/garages';
                break;
            case MODULO_REPORTE:
                isMatch = path === '#/collaborators';
                break;
            case MODULO_ORDEN:
                isMatch = path === '#/work-orders';
                break;
            case MODULO_ORDEN_INCOMPLETE:
                isMatch = path === '#/cars/incomplete-orders';
                break;
            case MODULO_OVERDUE_NOTIFICARIONES:
                isMatch = path === '#/overdue-notifications';
                break;
            case MODULO_MISORDENES:
                isMatch = path === '#/mywork-orders';
                break;
            case MODULO_SUSCRIPCION:
                isMatch = path === '#/subscriptions';
                break;
            case MODULO_SUCURSAL:
                isMatch = path === '#/branch_offices';
                break;
            case MODULO_MONEDA:
                isMatch = path === '#/currency';
                break;
            case MODULO_CUENTA:
                isMatch = path === '#/accounts';
                break;
            case MODULO_SOLICITUD:
                isMatch = path === '#/requests';
                break;
            case MODULO_CONFIGURACION:
                isMatch = path.includes('/users') || path.includes('/roles') || path.includes('/insurance-carriers') || path.includes('/configurations') || path.includes('/car-systems');
                break;
            default:
                isMatch = false;
                break;
        }
        return !isFirstSelected && !isItemSelected && isMatch || isFirstSelected && isMatch && !isSidebarExpanded || isFirstSelected && isItemSelected && isSidebarExpanded;
    }

    render() {
        const { toggleOpen, navToggle, user, logOut } = this.props;
        return (
            <aside className={`main-sidebar ${toggleOpen ? '' : 'open'}`} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>

                <div className="sidebar-cont" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                    <div className="sidebar-cont-icon" ></div>
                    <div className='sidebar-cont-opc'  >
                        <div className="text-center py-2 px-2">
                            <img
                                className="main-logo-sidebar"
                                src={user.imagen_garage ? user.imagen_garage : image_placeholder}
                                alt="Logo" />
                        </div>
                    </div>
                    <div className="sidebar-principal" >
                        <div className="main-navbar">
                            <a
                                className="toggle-sidebar d-flex d-md-none d-lg-none bg-white "
                                onClick={navToggle}
                            >
                                <i className="material-icons" style={{ margin: "auto" }}></i>
                            </a>
                        </div>
                        <div className="nav-wrapper w-100 spacing-items">
                            <Accordion className="nav--no-borders flex-column nav">
                                <div className="nav-item pb-4">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(PERFIL, location.hash, expanded);
                                                        return <NavLink onClick={navToggle} to="/user-profile" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                            <img src={(user && user.avatar) ? user.avatar : defaultAvatar}
                                                                className="icon-menu-profile" alt="Avatar user" />
                                                            <span className="text-12 bold">{(user && user.first_name) ? user.first_name + ' ' + user.last_name : 'Usuario Invitado'}</span>
                                                        </NavLink>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                <div className="nav-item">
                                    {user.permissions.includes(DASHBOARD_GARAGE) && (
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => {
                                                            const isSelected = this.isItemSelected(MODULO_DASHBOARD, location.hash, expanded);
                                                            return <NavLink onClick={navToggle} to="/" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                                <img src={isSelected ? dashboard_activo : dashboard}
                                                                    className="icon-menu" alt="" />
                                                                <span className="text-14 medium">Dashboard</span>
                                                            </NavLink>
                                                        }}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                        </AccordionItem>
                                    )}
                                    {user.permissions.includes(CUSTOMER_MANAGEMENT) && (
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => {
                                                            const isSelected = this.isItemSelected(MODULO_CLIENTE, location.hash, expanded);
                                                            return <NavLink onClick={navToggle} to="/clients" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                                <img src={isSelected ? cliente_activo : cliente}
                                                                    className="icon-menu" alt="" />
                                                                <span className="text-14 medium">Clientes</span>
                                                            </NavLink>
                                                        }}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                        </AccordionItem>
                                    )}

                                    {user.permissions.includes(WORK_ORDER_MANAGEMENT) && (
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => {
                                                            const isSelected = this.isItemSelected(MODULO_ORDEN, location.hash, expanded);
                                                            return <NavLink onClick={navToggle} to="/work-orders" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                                <img src={isSelected ? orden_activo : orden}
                                                                    className="icon-menu" alt="" />
                                                                <span className="text-14 medium">Ordenes</span>
                                                            </NavLink>
                                                        }}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                        </AccordionItem>
                                    )}
                                    {user.permissions.includes(MY_WORK_ORDERS) && (
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => {
                                                            const isSelected = this.isItemSelected(MODULO_MISORDENES, location.hash, expanded);
                                                            return <NavLink onClick={navToggle} to="/mywork-orders" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                                <img src={isSelected ? my_orders_active : my_orders}
                                                                    className="icon-menu" alt="" />
                                                                <span className="text-14 medium">Mis Ordenes</span>
                                                            </NavLink>
                                                        }}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                        </AccordionItem>
                                    )}
                                    {user.permissions.includes(WORK_ORDER_MANAGEMENT) && (
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => {
                                                            const isSelected = this.isItemSelected(MODULO_ORDEN_INCOMPLETE, location.hash, expanded);
                                                            return <NavLink onClick={navToggle} to="/cars/incomplete-orders" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                                <img src={isSelected ? order_incomplete_activo : order_incomplete}
                                                                    className="icon-menu" alt="" />
                                                                <span className="text-14 medium">Ordenes Incompletas</span>
                                                            </NavLink>
                                                        }}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                        </AccordionItem>
                                    )}
                                    {user.permissions.includes(WORK_ORDER_MANAGEMENT) && (
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => {
                                                            const isSelected = this.isItemSelected(MODULO_OVERDUE_NOTIFICARIONES, location.hash, expanded);
                                                            return <NavLink onClick={navToggle} to="/overdue-notifications" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                                <img src={isSelected ? notification2 : notification1}
                                                                    className="icon-menu" alt="" />
                                                                <span className="text-14 medium">Notificaciones Vencidas</span>
                                                            </NavLink>
                                                        }}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                        </AccordionItem>
                                    )}
                                    {user.permissions.includes(REPORTS_GARAGE) && (
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => {
                                                            const isSelected = this.isItemSelected(MODULO_REPORTE, location.hash, expanded);
                                                            return <div className={`nav-link${isSelected ? ' active' : ''}`} >
                                                                <img src={isSelected ? reporte_activo : reporte}
                                                                    className="icon-menu" alt="" />
                                                                <span className="text-14 medium">Reporte</span>
                                                            </div>
                                                        }}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemState>
                                            {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_REPORTE, location.hash, expanded);
                                                        return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                                <div className="sub-nav-item">
                                                                    <NavLink onClick={navToggle} to="/collaborators" exact className="nav-link" activeClassName={'active'}
                                                                        isActive={(match, location) => location.pathname.includes('/collaborators')}>
                                                                        <span style={{ color: "#323232" }} className="text-12 medium">Colaboradores</span>
                                                                    </NavLink>
                                                                </div>
                                                                <div className="sub-nav-item">
                                                                    <NavLink onClick={navToggle} to="/bitacora-collaborators" exact className="nav-link" activeClassName={'active'}
                                                                        isActive={(match, location) => location.pathname.includes('/bitacora-collaborators')}>
                                                                        <span style={{ color: "#323232" }} className="text-12 medium">Bitácora</span>
                                                                    </NavLink>
                                                                </div>
                                                        </AccordionItemPanel>
                                                    }}
                                            </AccordionItemState>
                                        </AccordionItem>
                                    )}
                                    {((user.permissions.includes(SYSTEMS_AND_COMPONENTS_MANAGEMENT)) ||
                                        (user.permissions.includes(INSURANCE_MANAGEMENT)) ||
                                        (user.permissions.includes(WORKSHOP_SETUP)) ||
                                        (user.permissions.includes(ROLE_MANAGEMENT_GARAGE)) ||
                                        (user.permissions.includes(USER_MANAGEMENT_GARAGE))) && (
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <AccordionItemState>
                                                            {({ expanded }) => {
                                                                const isSelected = this.isItemSelected(MODULO_CONFIGURACION, location.hash, expanded);
                                                                return <div className={`nav-link${isSelected ? ' active' : ''}`} >
                                                                    <img src={isSelected ? settings_activo : settings}
                                                                        className="icon-menu" alt="" />
                                                                    <span className="text-14 medium">Administración</span>
                                                                </div>
                                                            }}
                                                        </AccordionItemState>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_CONFIGURACION, location.hash, expanded);
                                                        const isUsuarios = location.hash.includes('/users')
                                                        const isRoles = location.hash.includes('/roles')
                                                        const isAseguradora = location.hash.includes('/insurance-carriers')
                                                        const isConfiguration = location.hash.includes('/configurations')
                                                        const isSistemaVehiculos = location.hash.includes('/car-systems')
                                                        return isSelected && <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>

                                                            {user.permissions.includes(USER_MANAGEMENT_GARAGE) && (
                                                                <div className="sub-nav-item">
                                                                    <NavLink onClick={navToggle} to="/users" exact className="nav-link" activeClassName={'active'}
                                                                        isActive={(match, location) => location.pathname.includes('/users')}>
                                                                        <img src={isUsuarios ? point : point_disabled}
                                                                            className="icon-menu-sub" alt="" />
                                                                        <span style={{ color: isUsuarios ? "#cd0a0a" : "#323232" }} className="text-12 medium">Usuarios</span>
                                                                    </NavLink>
                                                                </div>
                                                            )}
                                                            {user.permissions.includes(ROLE_MANAGEMENT_GARAGE) && !user.is_branch_user && (
                                                                <div className="sub-nav-item">
                                                                    <NavLink onClick={navToggle} to="/roles" exact className="nav-link" activeClassName={'active'}
                                                                        isActive={(match, location) => location.pathname.includes('/roles')}>
                                                                        <img src={isRoles ? point : point_disabled}
                                                                            className="icon-menu-sub" alt="" />
                                                                        <span style={{ color: isRoles ? "#cd0a0a" : "#323232" }} className="text-12 medium">Roles</span>
                                                                    </NavLink>
                                                                </div>
                                                            )}

                                                            {user.permissions.includes(INSURANCE_MANAGEMENT) && (
                                                                <div className="sub-nav-item">
                                                                    <NavLink onClick={navToggle} to="/insurance-carriers" exact className="nav-link" activeClassName={'active'}
                                                                        isActive={(match, location) => location.pathname.includes('/insurance-carriers')}>
                                                                        <img src={isAseguradora ? point : point_disabled}
                                                                            className="icon-menu-sub" alt="" />
                                                                        <span style={{ color: isAseguradora ? "#cd0a0a" : "#323232" }} className="text-12 medium">Aseguradoras</span>
                                                                    </NavLink>
                                                                </div>
                                                            )}
                                                            {user.permissions.includes(WORKSHOP_SETUP) && !user.is_branch_user && (
                                                                <div className="sub-nav-item">
                                                                    <NavLink onClick={navToggle} to="/configurations" exact className="nav-link" activeClassName={'active'}
                                                                        isActive={(match, location) => location.pathname.includes('/configurations')}>
                                                                        <img src={isConfiguration ? point : point_disabled}
                                                                            className="icon-menu-sub" alt="" />
                                                                        <span style={{ color: isConfiguration ? "#cd0a0a" : "#323232" }} className="text-12 medium">Configuraciones</span>
                                                                    </NavLink>
                                                                </div>
                                                            )}
                                                            {user.permissions.includes(SYSTEMS_AND_COMPONENTS_MANAGEMENT) && (
                                                                <div className="sub-nav-item">
                                                                    <NavLink onClick={navToggle} to="/car-systems" exact className="nav-link" activeClassName={'active'}
                                                                        isActive={(match, location) => location.pathname.includes('/car-systems')}>
                                                                        <img src={isSistemaVehiculos ? point : point_disabled}
                                                                            className="icon-menu-sub" alt="" />
                                                                        <span style={{ color: isSistemaVehiculos ? "#cd0a0a" : "#323232" }} className="text-12 medium">Sistemas de vehiculos</span>
                                                                    </NavLink>
                                                                </div>
                                                            )}
                                                        </AccordionItemPanel>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItem>
                                        )}
                                    {/*
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_ORDEN, location.hash, expanded);
                                                        return <NavLink onClick={navToggle} to="/orders" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                            <img src={isSelected ? orden_activo : orden}
                                                                className="icon-menu" alt="" />
                                                            <span className="text-14 medium">Ordenes</span>
                                                        </NavLink>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_SUSCRIPCION, location.hash, expanded);
                                                        return <NavLink onClick={navToggle} to="/subscriptions" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                            <img src={isSelected ? suscripcion_activo : suscripcion}
                                                                className="icon-menu" alt="" />
                                                            <span className="text-14 medium">Suscripciones</span>
                                                        </NavLink>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_SUCURSAL, location.hash, expanded);
                                                        return <NavLink onClick={navToggle} to="/branch_offices" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                            <img src={isSelected ? sucursal_activo : sucursal}
                                                                className="icon-menu" alt="" />
                                                            <span className="text-14 medium">Sucursales</span>
                                                        </NavLink>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_MONEDA, location.hash, expanded);
                                                        return <NavLink onClick={navToggle} to="/currency" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                            <img src={isSelected ? moneda_activo : moneda}
                                                                className="icon-menu" alt="" />
                                                            <span className="text-14 medium">Monedas</span>
                                                        </NavLink>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_CUENTA, location.hash, expanded);
                                                        return <NavLink onClick={navToggle} to="/accounts" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                            <img src={isSelected ? cuenta_activo : cuenta}
                                                                className="icon-menu" alt="" />
                                                            <span className="text-14 medium">Cuentas</span>
                                                        </NavLink>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        const isSelected = this.isItemSelected(MODULO_SOLICITUD, location.hash, expanded);
                                                        return <NavLink onClick={navToggle} to="/requests" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                            <img src={isSelected ? solicitud_activo : solicitud}
                                                                className="icon-menu" alt="" />
                                                            <span className="text-14 medium">Solicitudes</span>
                                                        </NavLink>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem> */}

                                </div>
                                <div className="nav-item pie-sidebar">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => {
                                                        return <a onClick={logOut} href="/" className="nav-item">
                                                            <img src={settings_user}
                                                                className="icon-menu-close" alt="Avatar user" />
                                                            <span className="text-14 medium">Cerrar sesión</span>
                                                        </a>
                                                    }}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                            </Accordion>
                        </div>
                    </div>
                </div>

            </aside>
        )
    }
}

export default SideBar;
