import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import {HeaderBtnSearch, HeaderSearch} from '../../../Utils/Header'
import {ROLE_MANAGEMENT_GARAGE} from '../../../../../utility/constants';
import {ButtonSalir} from '../../../Utils/Button';
import moment from "moment";


function getCaret(direction) {
    if (direction === 'asc') {
        return (
            <span className="material-icons" style={{color: '#FFF'}}>arrow_drop_down</span>
        );
    }
    if (direction === 'desc') {
        return (
            <span className="material-icons" style={{color: '#FFF'}}>arrow_drop_up</span>
        );
    }
    return (
        <span className="material-icons" style={{color: '#FFF', textSize: '10px', opacity: 0.3}}>arrow_drop_down</span>
    );
}

export default class ListarN extends Component {

    componentWillMount() {
        const {listar, page} = this.props;
        listar(page);
    }

    render() {
        const {listar, onSortChange} = this.props; //Funciones
        const {data, loader, page,} = this.props; //Constantes

        return (
            <Card
                titulo="Notificaciones Vencidas"

                header={
                    <div className="row mx-auto">
                        <div className="col-lg-4 col-md-8 col-sm-12 mx-0 px-0">
                            <p className="mb-n2">
                                Las notificaciones vencidas tienen un mes de vigencia a partir de su fecha de envio,
                                luego seran removidas del sistema.
                            </p>
                        </div>
                        <HeaderSearch {...this.props} />
                    </div>
                }
            >
                <Grid
                    hover
                    striped
                    data={data}
                    page={page}
                    loading={loader}
                    onPageChange={listar}
                    onSortChange={onSortChange}
                >
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        width='20px'
                        dataFormat={(cell) => {
                            return null
                        }}
                    >
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width='100px'
                        dataField="car.car_registration"
                        dataFormat={(cell, row) => {
                            if (row) {
                                return row.car.car_registration
                            }
                        }}
                    >
                        PLACA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="car.brand"
                        dataFormat={(cell, row) => {
                            let brand_name = row.car && row.car.brand ? row.car.brand.name : '';
                            let model_name = row.car && row.car.model ? row.car.model.name : '';
                            if (row) {
                                return brand_name + " " + model_name
                            }
                        }}
                    >
                        VEHICULO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="client.name"
                        dataFormat={(cell, row) => {
                            if (row) {
                                return row.client.name
                            }

                        }}
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="client.phone_number"
                        dataFormat={(cell, row) => {
                            if (row) {
                                return row.client.phone_number
                            }
                        }}
                    >
                        TELÉFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="client.email"
                        dataFormat={(cell, row) => {
                            if (row) {
                                return row.client.email
                            }
                        }}
                    >
                        EMAIL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="type_notification"
                        dataSort={true}
                        caretRender={getCaret}
                        dataFormat={(cell, row) => {
                            return (
                                <span>
                                    {cell == 'engine_tuning' && "Afinamiento de motor"}
                                    {cell == 'oil_change' && "Cambio de aceite"}
                                    {cell == 'annual_check_up' && "Chequeo Anual"}
                                </span>
                            )
                        }}
                    >
                        TIPO DE NOTIFICACION
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataSort={true}
                        caretRender={getCaret}
                        dataField="send_date"
                        thStyle={{textAlign: 'center'}}
                        tdStyle={{whiteSpace: 'normal', textAlign: 'center'}}
                        dataFormat={cell => moment(cell).format('DD-MM-YYYY')}
                    >
                        FECHA DE NOTIFICACION
                    </TableHeaderColumn>
                </Grid>
            </Card>
        )
    }
}
