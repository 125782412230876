import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import PasoUnoForm from '../DatosComun/PasoUnoForm';
import PasoDosForm from '../DatosComun/PasoDosForm';
import PasoFinalForm from './PasoFinalForm';
import PasoTresForm from '../DatosComun/PasoTresForm';
import { formData } from '../DatosComun/Metodos'

export default class Crear extends Component {
    state = {
        editar: false,
        step: 1,
    };

    componentWillMount() {
        this.props.destroyForm()
        this.props.setItemToEmpty();
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
            this.setState({ editar: true });
        }
    }

    componentWillUnmount() {
        this.props.setCurrentNotification([])
    }

    nextStep = () => {
        if (this.state.editar === false && this.state.step === 2) {
            this.setState({step: this.state.step + 2});
            return;
        }

        this.setState({ step: this.state.step + 1 })
    };

    previousStep = () => {
        if (this.state.editar === false && this.state.step === 4) {
            this.setState({step: this.state.step - 2});
            return;
        }


        this.setState({ step: this.state.step - 1 })
    };

    onSubmit = (_data) => {
        const { arrayFiles, data } = formData(_data)
        data.type = "order_mechanic"
        if (this.state.editar) {
            this.props.editar(this.props.match.params.id, data, arrayFiles)
        } else {
            this.props.crear(data, arrayFiles);
        }
    }


    render() {
        //Value
        const { loader, item, idOrder, profile, order } = this.props;
        const { editar, step } = this.state
        return (
            <CardForm titulo={editar ? "EDITAR ORDEN DE TRABAJO" : "AGREGAR ORDEN DE TRABAJO"}>
                <div className="d-flex justify-content-center align-items-center mb-3">
                    <h5 className="text-20 text-bold">
                        {step === 1 && "DETALLES DE SOLICITUD"}
                        {step === 2 && "INVENTARIO DE VEHÍCULO"}
                        {step === 3 && "IMÁGENES DIAGNÓSTICO"}
                        {step === 4 && "ORDEN MECANICA"}
                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center mb-3">
                    <div className={`mx-2 circulo text-bold text-center circulo circulo-activo`}>1</div>
                    <div className={`${editar === false ? 'mx-4' : 'mx-2'} circulo text-bold text-center circulo ${step > 1 && "circulo-activo"}`}>2</div>
                    {editar === true && (
                        <div className={`mx-2 circulo text-bold text-center circulo ${step > 2 && "circulo-activo"}`}>3</div>
                    )}
                    <div className={`${editar === false ? 'mx-2' : 'mx-2'} circulo text-bold text-center circulo ${step > 3 && "circulo-activo"}`}>
                        {editar === true ? 4 : 3}
                    </div>
                    <div className="m2 position-absolute" style={{ zIndex: 70, borderTop: "solid 0px #000", width: 130, borderBottom: "solid 2px #000" }} />
                </div>
                <LoadMask loading={loader} blur>

                    {step === 1 &&
                        <PasoUnoForm
                            onSubmit={this.nextStep}
                            editar={editar}
                            idOrder={idOrder}
                            profile={profile}
                            order={order}
                            item={item}
                            backList='/mechanic_orders'
                            showNotifiactionTable={!editar}
                            setCurrentNotification={this.props.setCurrentNotification}
                            currentNotification={this.props.current_notification}
                        />
                    }
                    {step === 2 && (
                        <PasoDosForm
                            onSubmit={this.nextStep}
                            previousStep={this.previousStep}
                        />
                    )}
                    {step === 3 && (
                        <PasoTresForm
                            onSubmit={this.nextStep}
                        
                            previousStep={this.previousStep}
                        />
                    )}
                    {step === 4 && (
                        <PasoFinalForm 
                            onSubmit={this.onSubmit}
                            editar={editar}
                            previousStep={this.previousStep}
                        />
                    )}
                </LoadMask>
            </CardForm>
        )
    }
}
