import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate } from 'validate-redux-form';

import {
    renderField,
    renderNumber,
    SelectField,
    renderDatePicker,
} from '../../../Utils/renderField/renderField';

import { ButtonSalirGuardar } from '../../../Utils/Button'
import { validationBranchGarage, validationReceiptInfo, validationAdministrador } from '../../../../../utility/validationForm'
import { COUNTRIES } from '../../../../../utility/countries';


const SucursalForm = (props) => {
    const { handleSubmit, editando } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">
                <div className="col-12 padding-custom mb-4">
                    <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>DATOS SUCURSAL</span>
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="name">Nombre*</label>
                    <Field name="name" component={renderField} type="text" className="form-control" maxLength={validationBranchGarage.name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="phone_number">Teléfono*</label>
                    <Field name="phone_number" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationBranchGarage.phone_number.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="email">Email*</label>
                    <Field name="email" component={renderField} type="email" className="form-control" maxLength={validationBranchGarage.email.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="foundation_date">Fecha fundación</label>
                    <Field name="foundation_date" component={renderDatePicker} className="form-control" />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="country">País*</label>
                    <Field
                        valueKey="id"
                        name="country"
                        labelKey="name"
                        options={COUNTRIES}
                        component={SelectField}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="city">Ciudad*</label>
                    <Field name="city" component={renderField} type="text" className="form-control" maxLength={validationBranchGarage.city.MaxLength} />
                </div>
                <div className="col-12 padding-custom mb-3">
                    <label htmlFor="address">Dirección*</label>
                    <Field name="address" component={renderField} type="text" className="form-control" maxLength={validationBranchGarage.address.MaxLength} />
                </div>

                <div className="col-12 padding-custom mb-4 mt-5">
                    <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>DATOS DEL ADMINISTRADOR</span>
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="admin.first_name">Nombre*</label>
                    <Field name="admin.first_name" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.first_name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="admin.last_name">Apellido*</label>
                    <Field name="admin.last_name" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.last_name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="admin.username">Usuario*</label>
                    <Field name="admin.username" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.username.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="admin.phone_number">Teléfono*</label>
                    <Field name="admin.phone_number" component={renderNumber} type="text" className="form-control" isNumericString={true} maxLength={validationAdministrador.phone_number.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="admin.email">Email*</label>
                    <Field name="admin.email" component={renderField} type="email" className="form-control" maxLength={validationAdministrador.email.MaxLength} />
                </div>

                <div className="col-12 padding-custom mb-4 mt-5">
                    <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>DATOS FACTURACIÓN</span>
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.name">Nombre facturación*</label>
                    <Field name="receipt_info.name" component={renderField} disabled={editando} type="text" className="form-control" maxLength={validationReceiptInfo.name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.phone_number">Teléfono</label>
                    <Field name="receipt_info.phone_number" isNumericString={true} disabled={editando} component={renderNumber} className="form-control" maxLength={validationReceiptInfo.phone_number.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.email">Email</label>
                    <Field name="receipt_info.email" component={renderField} disabled={editando} type="email" className="form-control" maxLength={validationReceiptInfo.email.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.tax_id">ID tributario*</label>
                    <Field name="receipt_info.tax_id" component={renderField} disabled={editando} type="text" className="form-control" maxLength={validationReceiptInfo.tax_id.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.country">País*</label>
                    <Field
                        valueKey="id"
                        name="receipt_info.country"
                        labelKey="name"
                        options={COUNTRIES}
                        component={SelectField}
                        disabled={editando}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.city">Ciudad*</label>
                    <Field name="receipt_info.city" component={renderField} disabled={editando} type="text" className="form-control" maxLength={validationReceiptInfo.city.MaxLength} />
                </div>
                <div className="col-12 padding-custom mb-3">
                    <label htmlFor="receipt_info.address">Dirección*</label>
                    <Field name="receipt_info.address" component={renderField} disabled={editando} type="text" className="form-control" maxLength={validationReceiptInfo.address.MaxLength} />
                </div>
                <div className="col-12 padding-custom mb-3">
                    <p className="text-right azul text-12 bold">* Campos requeridos</p>
                </div>
            </div>
            <ButtonSalirGuardar link="/configurations" />
        </form>
    );
};
export default reduxForm({
    form: 'SucursalForm',
    validate: (data) => {
        let errors = {};
        if (data) {
            errors.receipt_info = validate(data.receipt_info || {}, {
                name: validationReceiptInfo.name.Combine,
                tax_id: validationReceiptInfo.tax_id.Combine,
                country: validationReceiptInfo.country.Combine,
                city: validationReceiptInfo.city.Combine,
                address: validationReceiptInfo.address.Combine,
                phone_number: validationReceiptInfo.phone_number.Combine,
                email: validationReceiptInfo.email.Combine,
            })
            errors.admin = validate(data.admin || {}, {
                first_name: validationAdministrador.first_name.Combine,
                last_name: validationAdministrador.last_name.Combine,
                username: validationAdministrador.username.Combine,
                phone_number: validationAdministrador.phone_number.Combine,
                email: validationAdministrador.email.Combine,
            })
        }
        return {
            ...errors, ...validate(data, {
                name: validationBranchGarage.name.Combine,
                email: validationBranchGarage.email.Combine,
                phone_number: validationBranchGarage.phone_number.Combine,
                country: validationBranchGarage.country.Combine,
                city: validationBranchGarage.city.Combine,
                address: validationBranchGarage.address.Combine,
            })
        }
    },

})(SucursalForm);
