import React, { Fragment, useEffect } from 'react';
import { Field, reduxForm, FieldArray, change as changeForm, } from 'redux-form';

import { validate } from 'validate-redux-form';

import {
    renderField,
    renderNumber,
    SelectField,
    renderDatePicker,
} from '../../../Utils/renderField/renderField';

import { ButtonSalirGuardar } from '../../../Utils/Button'
import { validationClient, validationContactInfo } from '../../../../../utility/validationForm'
import { COUNTRIES } from '../../../../../utility/countries';
import { REFERENCES, GENDERS, RED_SOCIAL } from '../../../../../utility/constants'
const svgDelete = require('assets/img/icons_table/delete.svg');
import { connect } from 'react-redux'

export const REDES_SOCIALES = [
    { "name": "Facebook", "id": "Facebook" },
    { "name": "Twitter", "id": "Twitter" },
    { "name": "Instagram", "id": "Instagram" },
    { "name": "LinkedIn", "id": "LinkedIn" },
]
const renderContactos = ({ fields, meta: { error, submitFailed }, }) => {
    return (
        <div className="col-12 form-group m-auto px-0 px-sm-0 pt-2">
            <div className="my-n2 d-none d-md-block">
                <div className="react-bs-container-header" style={{ height: 25 }} >
                    <div className="d-none d-md-flex row py-1 px-0 px-sm-1 px-lg-1 px-xl-1">
                        <div className="col-1 mb-2 d-none d-md-flex justify-content-center align-items-center">
                        </div>
                        <div className="col-md-3 mb-2 px-1">
                            <label htmlFor="name" className="ml-2 blanco d-block">NOMBRE*</label>
                        </div>
                        <div className="col-md-3 mb-2 px-1">
                            <label htmlFor="mobil_number" className="blanco d-block">TELÉFONO MÓVIL</label>
                        </div>
                        <div className="col-md-2 mb-2 px-1">
                            <label htmlFor="phone_number" className="blanco d-block">TELÉFONO FIJO</label>
                        </div>
                        <div className="col-md-3 mb-2 px-1">
                            <label htmlFor="email" className="blanco d-block">E-MAIL</label>
                        </div>
                    </div>
                </div>
            </div>

            {fields.map((contact, index) => {
                return (

                    <div className="d-flex py-3 px-0 px-sm-1 flex-md-row flex-column" key={index}>
                        <div className="order-1 order-md-0 px-1 d-inline d-md-flex">
                            <img src={svgDelete} alt="Eliminar"
                                className="icon_svg pointer my-auto row mx-auto"
                                onClick={() => {
                                    fields.remove(index)
                                }}
                            />
                            <span className="text-18 bold d-block  d-md-none d-none mx-2 mt-2" style={{ borderBottom: "2px #7EA04D solid", paddingBottom: 5 }} />

                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="name" className="gris-oscuro d-block d-md-none">Nombre*</label>
                            <Field
                                name={`${contact}.name`}
                                type="text"
                                component={renderField}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="mobil_number" className="gris-oscuro d-block d-md-none">Teléfono móvil</label>
                            <Field
                                name={`${contact}.mobil_number`}
                                type="text"
                                component={renderField}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="phone_number" className="gris-oscuro d-block d-md-none">Teléfono Fijo</label>
                            <Field
                                name={`${contact}.phone_number`}
                                type="text"
                                component={renderField}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="email" className="gris-oscuro d-block d-md-none">E-mail</label>
                            <Field
                                name={`${contact}.email`}
                                type="text"
                                component={renderField}
                            />
                        </div>
                    </div>
                )
            })}
            <div className="d-flex justify-content-center mt-3">
                <button type="button" className="btn btn-secondary py-1" onClick={() => {
                    fields.push({})
                }}>
                    AGREGAR
                </button>
            </div>
        </div>
    )
}


let ClienteForm = (props) => {
    const { handleSubmit, selectRedSocial = false, dispatch } = props;


    useEffect(() => {
        if (!selectRedSocial)
            dispatch(changeForm('ClienteForm', "description_reference", null))
    }, [selectRedSocial])


    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form pt-4">
                <div className="col-md-6 padding-custom mb-3 ">
                    <label htmlFor="name">Nombre cliente*</label>
                    <Field name="name" component={renderField} type="text" className="form-control" maxLength={validationClient.name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3 ">
                    <label htmlFor="name">Actividad</label>
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="email">E-mail*</label>
                    <Field name="email" component={renderField} type="email" className="form-control" maxLength={validationClient.email.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="phone_number">Teléfono fijo*</label>
                    <Field name="phone_number" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationClient.phone_number.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="mobil_number">Teléfono móvil</label>
                    <Field name="mobil_number" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationClient.mobil_number.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="birth_date">Fecha de nacimiento</label>
                    <Field name="birth_date" component={renderDatePicker} className="form-control" />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="reference">Género*</label>
                    <Field
                        name="gender"
                        options={GENDERS}
                        placeholder="Género"
                        component={SelectField}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="document_number">Número de identificación</label>
                    <Field name="document_number" component={renderField} type="text" className="form-control" maxLength={validationClient.document_number.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="admission_date">Fecha de ingreso*</label>
                    <Field name="admission_date" component={renderDatePicker} className="form-control" />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="country">País*</label>
                    <Field
                        valueKey="id"
                        name="country"
                        labelKey="name"
                        placeholder="País"
                        options={COUNTRIES}
                        component={SelectField}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="city">Ciudad*</label>
                    <Field name="city" component={renderField} type="text" className="form-control" maxLength={validationClient.city.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="address">Dirección*</label>
                    <Field name="address" component={renderField} type="text" className="form-control" maxLength={validationClient.address.MaxLength} />
                </div>
                <div className="col-12 padding-custom mb-3 mb-0">
                    <p className="text-right azul text-12 bold mb-0">* Campos requeridos</p>
                </div>
                <div className="col-12 padding-custom mb-4 mt-3">
                    <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>REFERENCIAS</span>
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="reference">Referencia</label>
                    <Field
                        valueKey="id"
                        name="reference"
                        labelKey="name"
                        options={REFERENCES}
                        placeholder="Referencia"
                        component={SelectField}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="description_reference">Descripción</label>
                    {selectRedSocial ?
                        <Field
                            name="description_reference"
                            options={REDES_SOCIALES}
                            component={SelectField}
                            isSearchable={false}
                            defaultValue="Facebook"
                        />
                        :
                        <Field name="description_reference" component={renderField} type="text" className="form-control" maxLength={validationClient.description_reference.MaxLength} />
                    }
                </div>
                <div className="col-12 padding-custom mb-0 mb-md-4 mt-4">
                    <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>CONTACTOS SECUNDARIOS</span>
                </div>
                <div className="col-12 padding-custom mb-3">
                    <FieldArray name="contacts_info" component={renderContactos} />
                </div>
            </div>
            <ButtonSalirGuardar link="/clients" btnText1="Cancelar" btnText2="Siguiente" />
        </form>
    );
};
ClienteForm = reduxForm({
    form: 'ClienteForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data) => {
        let errors = {};
        let DetalleArrayErrors = [];
        if (data && data.contacts_info && data.contacts_info.length) {
            data.contacts_info.forEach((detalle, detalleIndex) => {
                DetalleArrayErrors[detalleIndex] = validate(detalle, {
                    name: validationContactInfo.name.Combine,
                    mobil_number: validationContactInfo.mobil_number.Combine,
                    phone_number: validationContactInfo.phone_number.Combine,
                    email: validationContactInfo.email.Combine,
                })
            });
        }
        errors.contacts_info = DetalleArrayErrors
        return {
            ...errors, ...validate(data, {
                name: validationClient.name.Combine,

                country: validationClient.country.Combine,
                city: validationClient.city.Combine,
                address: validationClient.address.Combine,

                phone_number: validationClient.phone_number.Combine,
                mobil_number: validationClient.mobil_number.Combine,
                email: validationClient.email.Combine,
                gender: validationClient.gender.Combine,
                admission_date: validationClient.admission_date.Combine,

                description_reference: validationClient.description_reference.Combine,
                document_number: validationClient.document_number.Combine,

            })
        }
    },
})(ClienteForm);

ClienteForm = connect(state => {
    const form = state.form.ClienteForm;
    let selectRedSocial = false;
    if (form && form.values) {
        const values = form.values;
        if (values.reference)
            selectRedSocial = values.reference == RED_SOCIAL
    }
    return {
        selectRedSocial
    }
})(ClienteForm);
export default ClienteForm;
