import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import _ from "lodash";

const LOADER = 'LOADER_COLABORADORES';
const DATA = 'DATA_COLABORADORES';
const PAGE = 'PAGE_COLABORADORES';
const ORDERING = 'ORDERING_COLABORADORES';
const SEARCH = 'SEARCH_COLABORADORES';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});


const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});


// ------------------------------------
// Actions
// ------------------------------------
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().colaboradores;
    const params = { page };

    if (resource.ordering)
        params.ordering = resource.ordering;
    if (resource.search)
        params.search = resource.search;
    dispatch(setLoader(true));
    api.get('garage/collaborators', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const searchChange = search => (dispatch, getStore) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().colaboradores.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};
export const actions = {
    listar,
    searchChange,
    onSortChange,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },

};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);


