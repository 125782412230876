import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/register';
import { actions as actions_planes } from '../../../../redux/modules/planes';

import Register from './Register';

const ms2p = (state) => {
    return {
        ...state.registro,
        datos_plan: { ...state.planes.item }

    };
};

const md2p = {
    ...actions,
    existe_plan: actions_planes.existe_plan
};

export default connect(ms2p, md2p)(Register);
