import React, { useState, Fragment, useEffect } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';

import {
    SelectField,
    SelectField2,
    renderCurrency,
    renderField
} from '../../../../Utils/renderField/renderField';
import {
    RenderDollar,
} from '../../../../Utils/renderField/renderReadField'
const logo = require('assets/img/default-logo.png');


import Grid from "../../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { Async } from "react-select";


const Repuestos = ({ loader, data, setSendData, sendData, data_garage }) => {
    const provider = {
        provider1: data.results[0].provider1 || "",
        provider2: data.results[0].provider2 || "",
        provider3: data.results[0].provider3 || "",
        currency: data_garage.currency,
    }
    const providerSelec = [
        { "label": "Ninguno", "value": 0 },
        { "label": data.results[0].provider1 || "", "value": 1 },
        { "label": data.results[0].provider2 || "", "value": 2 },
        { "label": data.results[0].provider3 || "", "value": 3 },
    ]

    const [state, setState] = useState({
        BreakLineCenter: {
            whiteSpace: 'normal', 
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal'
        },
        AlignCenter: {
            textAlign: 'center'
        }
    })

    return (
        <Fragment>
            <Grid hover striped data={data} loading={loader} pagination={false} className="no-hover">
                <TableHeaderColumn
                    dataField="quantity"
                    dataSort
                    isKey
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                >
                    CANTIDAD
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="replacement"
                    dataSort
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLine}
                >
                    REPUESTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="name_task"
                    dataSort
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLine}
                >
                    NÚMERO DE PARTE
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="description"
                    dataSort
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLine}
                >
                    DESCRIPCIÓN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="price_provider1"
                    dataSort
                    dataFormat={(cell, row) => {
                        return(
                            <React.Fragment>
                                <span style={{ fontWeight: "bold", fontSize: "125%" }}>
                                    {row.provider1}
                                </span>
                                <br/>
                                <div style={{ marginTop: 5 }}/>
                                <RenderDollar value={cell || 0} prefix={provider.currency + " "} />
                            </React.Fragment>
                        )
                    }}
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="price_provider2"
                    dataSort
                    dataFormat={(cell, row) => {
                        return(
                            <React.Fragment>
                                <span style={{ fontWeight: "bold", fontSize: "125%" }}>
                                    {row.provider2}
                                </span>
                                <br/>
                                <div style={{ marginTop: 5 }}/>
                                <RenderDollar value={cell || 0} prefix={provider.currency + " "} />
                            </React.Fragment>
                        )
                    }}
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                >
                    PROVEEDOR
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="price_provider3"
                    dataSort
                    dataFormat={(cell, row) => {
                        return(
                            <React.Fragment>
                                <span style={{ fontWeight: "bold", fontSize: "125%" }}>
                                    {row.provider3}
                                </span>
                                <br/>
                                <div style={{ marginTop: 5 }}/>
                                <RenderDollar value={cell || 0} prefix={provider.currency + " "} />
                            </React.Fragment>
                        )
                    }}
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                >
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                    dataFormat={(...arg) => {
                        const row = arg[1]
                        return(
                        <select name={`quotation[${arg[3]}].provider`} id='select_xjf' className=" text-16 medium negro" onChange={e => {
                            setSendData({ ...sendData, [arg[0]]: e.target.value != 0 ? e.target.value : undefined })
                        }

                        } >
                            {/*(providerSelec.map(item => (<option key={item.value} value={item.value} >{item.label}</option>)))*/}
                            <option key={1} value={1} >{row.provider1}</option>
                            <option key={2} value={2} >{row.provider2}</option>
                            <option key={3} value={3} >{row.provider3}</option>
                        </select>
                    )}}
                    thStyle={state.AlignCenter}
                    tdStyle={state.BreakLineCenter}
                >
                    PROVEEDOR
                </TableHeaderColumn>
            </Grid>

            <div class="table-responsive">
                <table className="table">
                    <tr style={{alignContent:'rigth', alignContent:'rigth'}}>
                        <th colSpan={4}>
                            <span style={{ fontWeight: "bold", fontSize: "125%" }}>
                                TOTAL
                            </span>
                        </th>
                        <th className='text-center'>
                            <RenderDollar style={{ whiteSpace: 'normal', fontWeight: "bold", fontSize: "125%" }} value={data.totalProveedor1 || 0} prefix={provider.currency + " "} />
                        </th>
                        <th className='text-center'>
                            <RenderDollar style={{ whiteSpace: 'normal', fontWeight: "bold", fontSize: "125%" }} value={data.totalProveedor2 || 0} prefix={provider.currency + " "} />
                        </th>
                        <th className='text-center'>
                            <RenderDollar style={{ whiteSpace: 'normal', fontWeight: "bold", fontSize: "125%" }} value={data.totalProveedor3 || 0} prefix={provider.currency + " "} />
                        </th>
                        <th></th>
                    </tr>
                </table>
            </div>
        </Fragment>
    )
}

const CotizacionForm = (props) => {
    const { data, loader, update } = props;
    const { data_work_orders = {}, data_garage = {} } = data || {}
    const { client = {}, order_number = "", receipt_info_garage_or_branch = {} } = data_work_orders || {}
    const { receipt_info = {} } = client || {}
    const [sendData, setSendData] = useState({})

    useEffect(() => {
        const initialData = {}
        let { results } = data || []
        results = results ? results : []
        results.forEach(item => initialData[item.id] = '1')
        setSendData(initialData)
    }, [ data ])

    return (
        <Fragment>
            <div className="row card-form">
                <div className="col-12 d-flex justify-content-center mt-3">
                    <div className="col-11">
                        <div className="col-12 d-flex justify-content-end mt-0">
                            <span>Orden: {order_number}</span>
                        </div>
                        <div className="col-12 d-flex justify-content-center mt-0">
                            <div className="col-11 pl-2">
                                <div className="row col-12 p-0 mt-3 d-flex justify-content-center align-pdf_datas-center">
                                    <img
                                        className="d-inline-block align-top"
                                        src={data_garage.logo ? data_garage.logo : logo}
                                        alt="Logo"
                                        height="100px"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row col-12 p-0 mt-2 justify-content-center align-pdf_datas-start">
                            <div className="col-12 p-0 fondo">
                                <div className="col-12 d-flex flex-column">
                                    <span className="text-20 text-uppercase rojo0a text p-0 m-0">{receipt_info_garage_or_branch.name || ''}</span>
                                    <span className="negro32 p-0 m-0">ID tributario: {receipt_info_garage_or_branch.tax_id}</span>
                                    <span className="negro32 p-0 m-0">Telefono: {receipt_info_garage_or_branch.phone_number}</span>
                                    <span className="negro32 p-0 m-0">Email: {receipt_info_garage_or_branch.email}</span>
                                    <span className="negro32 p-0 m-0">{receipt_info_garage_or_branch.address} {receipt_info_garage_or_branch.city} {receipt_info_garage_or_branch.country_text} </span>
                                </div>
                            </div>
                        </div>
                        <div className="row col-12 p-0 mt-3">
                            <div className="col-8 p-0">
                                <div className="col-12 d-flex flex-column">
                                    <span className="tc1  text-center">DATOS DEL CLIENTE</span>
                                    <table id="t01">
                                        <tr>
                                            <th>Nombre</th>
                                            <td>{receipt_info.name}</td>
                                        </tr>
                                        <tr>
                                            <th>ID tributario</th>
                                            <td>{receipt_info.tax_id}</td>
                                        </tr>
                                        <tr>
                                            <th>Telefono</th>
                                            <td>{receipt_info.phone_number || "--"}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{receipt_info.email || "--"}</td>
                                        </tr>
                                        <tr>
                                            <th>País</th>
                                            <td>{receipt_info.country_text}</td>
                                        </tr>
                                        <tr>
                                            <th>Ciudad</th>
                                            <td>{receipt_info.city}</td>
                                        </tr>
                                        <tr>
                                            <th>Dirección</th>
                                            <td>{receipt_info.address}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {data && Array.isArray(data.results) && data.results.length > 0 &&
                    <div className="col-12 padding-custom mb-3 mt-4 input-border-verde">
                        <Repuestos loader={loader} data_garage={data_garage} data={data} setSendData={setSendData} sendData={sendData} />
                    </div>
                }
            </div>
            <button type="button" className="btn btn-block btn-rojo text-16 btn-limit mx-auto mt-5" onClick={() => update(sendData)} >Guardar</button>
        </Fragment>
    );
};

export default reduxForm({
    form: 'CotizacionForm',
})(CotizacionForm);
