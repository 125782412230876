import React, { Fragment, useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate } from 'validate-redux-form';

import {
    renderField,
    renderNumber,
    SelectField,
    renderFieldCheck,
    AsyncSelectField2,
    SelectField2,
} from '../../../Utils/renderField/renderField';

import { ButtonSalirGuardar } from '../../../Utils/Button'
import { validationCar } from '../../../../../utility/validationForm'
import { connect } from 'react-redux'
import { TRANSMISSIONS } from '../../../../../utility/constants';
import { api } from "api";

const getAseguradoras = (search) => {
    return api.get("insurance_carrier", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};
const getCarType = (search) => {
    return api.get("car_type", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getBrand = (search) => {
    return api.get("brand_and_model", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};
let CarroForm = (props) => {
    const { handleSubmit, backUrl, nombre_cliente, editando, clearSelect, aseguradora_activo, item } = props;
    const [models, setModels] = useState([])

    useEffect(() => {
        if (editando) {
            if (item) {
                if (item.brand) {
                    setModels(item.brand.models)
                }
            }
        }
    }, [item])

    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">
                <div className="col-12 padding-custom mb-4">
                    <span className="text-30 bold d-block" >Cliente: {nombre_cliente}</span>
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="brand">Marca*</label>
                    <Field
                        name="brand"
                        component={AsyncSelectField2}
                        loadOptions={getBrand}
                        placeholder="Marca"
                        onCambio={(e) => {
                            setModels(e.models)
                            clearSelect('model')
                        }}
                    />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="model">Modelo*</label>
                    <Field
                        name="model"
                        options={models}
                        component={SelectField2}
                        placeholder="Modelo"
                    />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="car_type">Tipo*</label>
                    <Field
                        name="car_type"
                        component={AsyncSelectField2}
                        loadOptions={getCarType}
                        placeholder="Tipo"
                    />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="transmission">Transmisión*</label>
                    <Field
                        name="transmission"
                        options={TRANSMISSIONS}
                        component={SelectField}
                        placeholder="Transmisión"
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="vin">VIN*</label>
                    <Field name="vin" component={renderField} type="text" className="form-control" maxLength={validationCar.vin.MaxLength} />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="color">Color*</label>
                    <Field name="color" component={renderField} type="text" className="form-control" maxLength={validationCar.color.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="car_registration">Matricula*</label>
                    <Field name="car_registration" component={renderField} type="text" className="form-control" maxLength={validationCar.car_registration.MaxLength} />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="year">Año*</label>
                    <Field name="year" component={renderNumber} className="form-control" maxLength={validationCar.year.MaxLength} />
                </div>
                <div className="col-6 padding-custom mb-3">
                    <label htmlFor="insured_car">¿Asegurado?</label>
                    <Field
                        name="insured_car"
                        onCambio={(e) => {
                            if (!e)
                                clearSelect('insurance_carrier')
                        }}
                        component={renderFieldCheck}
                    />
                </div>
                {aseguradora_activo &&
                    <div className="col-6 padding-custom mb-3">
                        <label htmlFor="insurance_carrier">Aseguradora</label>
                        <Field
                            isClearable
                            name="insurance_carrier"
                            component={AsyncSelectField2}
                            loadOptions={getAseguradoras}
                            placeholder="No definida"
                        />
                    </div>
                }
                <div className="col-12 padding-custom mb-3">
                    <p className="text-right azul text-12 bold">* Campos requeridos</p>
                </div>
            </div>


            <ButtonSalirGuardar link={backUrl} />
        </form>
    );
};
CarroForm = reduxForm({
    form: 'CarroForm',
    validate: data => validate(data, {
        vin: validationCar.vin.Combine,
        color: validationCar.color.Combine,
        car_registration: validationCar.car_registration.Combine,
        year: validationCar.year.Combine,
        car_type: validationCar.car_type.Combine,
        model: validationCar.model.Combine,
        brand: validationCar.brand.Combine,
        transmission: validationCar.brand.Combine,
    })


})(CarroForm);


import { actions } from '../../../../../redux/modules/carros';
const md2p = { clearSelect: actions.clearSelect };
export default CarroForm = connect(
    (state, props) => {
        const form = state.form.CarroForm;
        let aseguradora_activo = false;
        if (form && form.values) {
            const values = form.values;
            if (values)
                aseguradora_activo = values.insured_car == true
        }
        return ({
            aseguradora_activo
        })
    }, md2p
)(CarroForm)
