import React, { Component, Fragment } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { PrintContainer, actions as printActions } from 'Utils/Print';
import Card from '../../../Utils/Cards/Card'
import { HeaderTwoFilter } from '../../../Utils/Header'
import { ROLE_MANAGEMENT_GARAGE } from '../../../../../utility/constants';
import {
    RenderCurrency
} from '../../../Utils/renderField/renderReadField'

import Select from 'react-select';

const money_svg = require("assets/img/icons_table/money.svg");
const report_svg = require("assets/img/icons_table/report.svg");

export default class Listar extends Component {

    state = {
        name_print_component: 'ORDENES ATENDIDAS',
        BreakLineCenter: {
            whiteSpace: 'normal',
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal'
        },
        AlignCenter: {
            textAlign: 'center'
        },
    }

    componentWillMount() {
        const { idColaborador, startOrderByCloseDate } = this.props;
        startOrderByCloseDate('finish_date')
        idColaborador(this.props.match.params.id);
    }

    imprimirOrden = (event) => {
        this.setState({
        }, () => {
            printActions.print(this.state.name_print_component);
        });
    }

    pagina = (page) => {
        const { listar } = this.props;
        listar(page);
    }

    render() {
        const { listar, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page, me } = this.props; //Constantes

        return (

            <Fragment>
            <PrintContainer name={this.state.name_print_component} header>
                <img class='img-imprimir' src='/assets/747ef7caff430050f91622258bf4a27c.png' alt='logo'></img>
                <Card
                    titulo="Ordenes Atendidas"
                    subTitulo={data.collaborator_name? data.collaborator_name: ''}
                    header={
                        <Fragment>
                            <HeaderTwoFilter
                                filtroText1="Año"
                                filtro1={
                                    <Select
                                        className='react-select-container w-100 bg-white rounded'
                                        backspaceRemovesValue={false}
                                        isSearchable={true}
                                        options={[
                                            { 'value': 2021, 'label': 2021 },
                                            { 'value': 2022, 'label': 2022 },
                                            { 'value': 2023, 'label': 2023 },
                                            { 'value': 2024, 'label': 2024 },
                                            { 'value': 2025, 'label': 2025 },
                                            { 'value': 2026, 'label': 2026 },
                                            { 'value': 2027, 'label': 2027 },
                                            { 'value': 2028, 'label': 2028 },
                                            { 'value': 2029, 'label': 2029 },
                                            { 'value': 2030, 'label': 2030 }
                                        ]}
                                        value={this.props.year}
                                        onChange={this.props.changeYear}
                                    />}
                                filtroText2="Mes"
                                filtro2={
                                    <Select
                                        className='react-select-container w-100 bg-white rounded'
                                        backspaceRemovesValue={false}
                                        isSearchable={true}
                                        options={[
                                            { 'value': 1, 'label': 'Enero' },
                                            { 'value': 2, 'label': 'Febrero' },
                                            { 'value': 3, 'label': 'Marzo' },
                                            { 'value': 4, 'label': 'Abril' },
                                            { 'value': 5, 'label': 'Mayo' },
                                            { 'value': 6, 'label': 'Junio' },
                                            { 'value': 7, 'label': 'Julio' },
                                            { 'value': 8, 'label': 'Agosto' },
                                            { 'value': 9, 'label': 'Septiembre' },
                                            { 'value': 10, 'label': 'Octubre' },
                                            { 'value': 11, 'label': 'Noviembre' },
                                            { 'value': 12, 'label': 'Diciembre' },
                                        ]}
                                        value={this.props.month}
                                        onChange={this.props.changeMonth}
                                    />}
                            />
                            <div className="w-100 h-100 d-flex justify-content-between flex-column flex-lg-row">
                                <div className="px-0 d-flex flex-1 mx-0 mx-sm-4 ml-lg-0 flex-column" >
                                    {data &&
                                        <div className="px-4 d-flex d-ajuste bg-blanco my-3 py-3" style={{ border: `3px solid ${data.sales_goal >= data.amount_generated ? "#CD0A0A" : "#7EA04D"}`, boxShadow: "0px 0px 7px #00000066", borderRadius: 15 }}>
                                            <img src={money_svg} style={{ width: 45 }} className="my-auto imagenquitar" alt="" />
                                            <span className="text-16 negro mt-auto my-auto px-3">Generado: <br></br> <RenderCurrency className="text-20 negro bold" value={data.amount_generated || 0} /></span>
                                            <span className="text-16 negro mt-auto my-auto px-3">Meta de venta: <br></br> <RenderCurrency className="text-20 negro bold" value={data.sales_goal || 0} /> </span>
                                            {data.sales_goal >= data.amount_generated &&
                                                <span className="text-16 negro mt-auto my-auto px-3">Faltante: <br></br> <RenderCurrency className="text-20 negro bold" value={data.sales_goal - data.amount_generated} /> </span>
                                            }
                                            <span className="text-16 negro mt-auto my-auto px-3">Superávit: <br></br> <RenderCurrency className="text-20 negro bold" value={(data.amount_generated - data.sales_goal)>0?data.amount_generated - data.sales_goal : 0} /> </span>
                                            <span className="text-16 negro mt-auto my-auto px-3">Horas totales: <br></br> <span className="text-20 negro bold">{data.time_invested} </span></span>
                                        </div>
                                    }
                                </div>
                                <div className="px-0 d-flex flex-1 mx-0 mx-sm-4 ml-lg-0 flex-column" >
                                    {data &&
                                        <div className="px-4 d-flex bg-blanco my-3 py-3" style={{ boxShadow: "0px 0px 7px #00000066", borderRadius: 15, height: 95 }}>
                                            <img src={report_svg} style={{ width: 45 }} className="my-auto" alt="" />
                                            <span className="text-16 negro mt-auto my-auto px-3">Precio por hora facturada </span>
                                            <span className="text-20 bold ml-auto my-auto"> <RenderCurrency className="text-20 negro bold" value={data.price_hour || 0} /> </span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Fragment>}
                >

                    <Grid data={data} loading={loader} onPageChange={this.pagina} onSortChange={onSortChange} page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            width="90px"
                            dataFormat={standardActions({ ver: "tasks" })}
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                        >
                            DETALLE
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="client"
                            dataFormat={cell =>  cell.name}
                            width="150px"
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                        >
                            Cliente
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="car"
                            dataFormat={cell =>  cell.car_registration}
                            width="100px"
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                        >
                            Placa
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="order_number"
                            dataSort
                            isKey
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                        >
                            NUMERO ORDEN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="order_type"
                            dataSort
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                        >
                            TIPO DE ORDEN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="repair_type"
                            dataSort
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                        >
                            TIPO DE REPARACIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="finish_date"
                            dataSort
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                        >
                            FECHA DE CIERRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="time_invested"
                            dataAlign="center"
                            tfoot="time_invested"
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                        >
                            TIEMPO
                    </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="amount_generated"
                            dataFormat={(cell) => <RenderCurrency value={cell} />}
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                        >
                            MANO DE OBRA
                        </TableHeaderColumn>
                    </Grid>
                    <button type="submit" className="btn btn-primary" onClick={this.imprimirOrden} >IMPRIMIR</button>
                </Card>
            </PrintContainer>
            </Fragment>
        )
    }
}
