//Elementos
export const TAPICERIAS = [
    { id: "Buen estado", name: "Buen estado" },
    { id: "Mal Estado", name: "Mal Estado" },
    { id: "No se revisó", name: "No se revisó" },
];
export const CUBRE_ALFOMBRAS = [
    { id: "Hule", name: "Hule" },
    { id: "Tela", name: "Tela" },
];
export const COMBUSTIBLE = [
    { id: "1/4 tanque", name: "1/4 tanque" },
    { id: "1/2 tanque", name: "1/2 tanque" },
    { id: "3/4 tanque", name: "3/4 tanque" },
    { id: "Lleno", name: "Lleno" },
    { id: "Vacío", name: "Vacío" },
];
//?  tipo de ordenes
export const ORDER_BODY_SHOP = 'order_body_shop'
export const ORDER_MECHANIC = 'order_mechanic'
export const ORDER_APPRAISAL = 'order_appraisal'

//?  Estado de un cliente
export const ACTIVE_CLIENT = 12
export const INACTIVE_CLIENT = 22
export const PARTIALLY_ACTIVE_CLIENT = 32

//?  Estado de una orden Mecanica
export const NOT_ASSIGNED = 10
export const DIAGNOSTIC = 20
export const QUOTATION = 30
export const IN_PROGRESS = 40
export const QUALITY_CONTROL = 50
export const TO_CHECK_IN = 60
export const FINALIZED = 70

//?  Estado de una orden AVALUO
const OrderAppraisal_NOT_ASSIGNED = 10
const OrderAppraisal_IN_PROGGRES = 20
const OrderAppraisal_TO_CHECK_IN = 30
const OrderAppraisal_FINALIZED = 40
export const STATUS_ORDER_APPRAISAL = {
    'NOT_ASSIGNED': OrderAppraisal_NOT_ASSIGNED,
    'IN_PROGGRES': OrderAppraisal_IN_PROGGRES,
    'TO_CHECK_IN': OrderAppraisal_TO_CHECK_IN,
    'FINALIZED': OrderAppraisal_FINALIZED,
}

//?  Estado de una orden Enderezado y pintura (body-shop)
const BS_NOT_ASSIGNED = 0
const BS_DIAGNOSTIC = 1
const BS_QUOTATION = 2
const BS_DISARMED = 3
const BS_STRAIGHTENED = 4
const BS_ENLISTED = 5
const BS_PAINTING = 6
const BS_POLISHED = 7
const BS_ARMED = 8
const BS_QUALITY_CONTROL = 9
const BS_CAR_WASH = 10
const BS_TO_CHECK_IN = 11
const BS_FINALIZED = 12
export const STATUS_ORDER_BODY_SHOP = {
    'NOT_ASSIGNED': BS_NOT_ASSIGNED,
    'DIAGNOSTIC': BS_DIAGNOSTIC,
    'QUOTATION': BS_QUOTATION,
    'DISARMED': BS_DISARMED,
    'STRAIGHTENED': BS_STRAIGHTENED,
    'ENLISTED': BS_ENLISTED,
    'PAINTING': BS_PAINTING,
    'POLISHED': BS_POLISHED,
    'ARMED': BS_ARMED,
    'QUALITY_CONTROL': BS_QUALITY_CONTROL,
    'CAR_WASH': BS_CAR_WASH,
    'TO_CHECK_IN': BS_TO_CHECK_IN,
    'FINALIZED': BS_FINALIZED,
}
export const LABEL_STATUS_ORDER_BODY_SHOP = {
    [BS_NOT_ASSIGNED]: { "label": "No asignado", 'search': 'not_assigned' },
    [BS_DIAGNOSTIC]: {"label": "Diagnóstico", 'search': 'diagnostic'},
    [BS_QUOTATION]: {"label": "Por Cotizar", 'search': 'quotation'},
    [BS_DISARMED]: { "label": "Desarmado", 'search': 'disarmed' },
    [BS_STRAIGHTENED]: { "label": "Enderezado", 'search': 'straightened' },
    [BS_ENLISTED]: { "label": "Alistado", 'search': 'enlisted' },
    [BS_PAINTING]: { "label": "Pintura", 'search': 'painting' },
    [BS_POLISHED]: { "label": "Pulida", 'search': 'polished' },
    [BS_ARMED]: { "label": "Armado", 'search': 'armed' },
    [BS_QUALITY_CONTROL]: { "label": "Control de calidad", 'search': 'quality_control' },
    [BS_CAR_WASH]: { "label": "Lavado del vehículo", 'search': 'car_wash' },
    [BS_TO_CHECK_IN]: { "label": "Por facturar", 'search': 'to_check_in' },
    [BS_FINALIZED]: { "label": "Finalizado", 'search': 'finalized' },
}

//? Sistemas y componentes para diagnóstico de enderezado y pintura
export const BODY_SHOP_SYSTEMS = [
    {
        id: 1,
        name: "Frontal",
        label: "Frontal",
        system_components: [
            {
                id: 1,
                name: "Capó",
                label: "Capó",
                system_component: 1
            },
            {
                id: 2,
                name: "Guarda barros izquierdo delantero",
                label: "Guarda barros izquierdo delantero",
                system_component: 1
            },
            {
                id: 3,
                name: "Guarda barros derecho delantero",
                label: "Guarda barros derecho delantero",
                system_component: 1
            },
            {
                id: 4,
                name: "Parachoques delantero",
                label: "Parachoques delantero",
                system_component: 1
            },
            {
                id: 5,
                name: "Vidrio frontal",
                label: "Vidrio frontal",
                system_component: 1
            },
            {
                id: 6,
                name: "Otros",
                label: "Otros",
                system_component: 1
            }
        ]
    },
    {
        id: 2,
        name: "Lateral",
        label: "Lateral",
        system_components: [
            {
                id: 7,
                name: "Puerta delantera derecha",
                label: "Puerta delantera derecha",
                system_component: 2
            },
            {
                id: 8,
                name: "Puerta delantera izquierda",
                label: "Puerta delantera izquierda",
                system_component: 2
            },
            {
                id: 9,
                name: "Puerta trasera derecha",
                label: "Puerta trasera derecha",
                system_component: 2
            },
            {
                id: 10,
                name: "Puerta trasera izquierda",
                label: "Puerta trasera derecha",
                system_component: 2
            },
            {
                id: 11,
                name: "Estribos",
                label: "Estribos",
                system_component: 2
            },
            {
                id: 12,
                name: "Aros",
                label: "Aros",
                system_component: 2
            },
            {
                id: 13,
                name: "Retrovisores",
                label: "Retrovisores",
                system_component: 2
            },
            {
                id: 14,
                name: "Otros",
                label: "Otros",
                system_component: 2
            }
        ]
    },
    {
        id: 3,
        name: "Trasero",
        label: "Trasero",
        system_components: [
            {
                id: 15,
                name: "Tapa del baúl",
                label: "Tapa del baúl",
                system_component: 3
            },
            {
                id: 16,
                name: "Faroles traseros",
                label: "Faroles traseros",
                system_component: 3
            },
            {
                id: 17,
                name: "Parachoques trasero",
                label: "Parachoques trasero",
                system_component: 3
            },
            {
                id: 18,
                name: "Otros",
                label: "Otros",
                system_component: 3
            }
        ]
    },
    {
        id: 4,
        name: "Superior",
        label: "Superior",
        system_components: [
            {
                id: 19,
                name: "Sunroof",
                label: "Sunroof",
                system_component: 4
            },
            {
                id: 20,
                name: "Otros",
                label: "Otros",
                system_component: 4
            }
        ]
    },
    {
        id: 5,
        name: "Otros",
        label: "Otros",
        system_components: [
            {
                id: 21,
                name: "Otros",
                label: "Otros",
                system_component: 5
            }
        ]
    }
]

//? tipo de referencias
export const RED_SOCIAL = 10
export const BUSCADOR_DE_INTERNET = 20
export const RADIO = 30
export const AMISTAD = 40
export const TELEVISION = 50
export const PAGINA_WEB = 60

export const REFERENCES = [
    { "name": "Red social", "id": RED_SOCIAL },
    { "name": "Buscador de internet", "id": BUSCADOR_DE_INTERNET },
    { "name": "Radio", "id": RADIO },
    { "name": "Amistad", "id": AMISTAD },
    { "name": "Televisión", "id": TELEVISION },
    { "name": "Página web", "id": PAGINA_WEB },
]
//? tipo de TRANSMISSIONS
export const MANUAL = 10
export const AUTOMATICO = 20
export const TRANSMISSIONS = [
    { "name": "Manual", "id": MANUAL },
    { "name": "Automático", "id": AUTOMATICO },
]


//? Generos
export const HOMBRE = 10;
export const MUJER = 20;

export const GENDERS = [
    { id: HOMBRE, name: "Hombre" },
    { id: MUJER, name: "Mujer" },
];



//SwalAlert
import Swal from 'sweetalert2';
export const SWALMODERROR = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-2 btn-salir',
        title: 'text-40 negro',
        image: 'blink-danger',
    },
    buttonsStyling: false,
    focusConfirm: false,
    focusCancel: false,
})

// Cambiar estilo para los Swal,
export const SWALMOD = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-2 btn-salir',
        cancelButton: 'btn btn-gris m-2 btn-salir',
        title: 'bold negro',
        image: 'blink-danger',
        validationMessage: 'my-validation-message',
    },
    preConfirm: (value) => {
        if (!value) {
            SWALMOD.showValidationMessage(
                'La justificación es importante.'
            )
        }
    },
    buttonsStyling: false,
    focusConfirm: false,
    focusCancel: false,
})

//? - - - - - - PERMISOS PARA EL TALLER
export const CUSTOMER_MANAGEMENT = 'customer_management'
export const DELETE_RECORDS = 'delete_records'
export const WORK_ORDER_MANAGEMENT = 'work_order_management'
export const MY_WORK_ORDERS = 'my_work_orders'
export const MECHANICS_ORDERS = 'mechanics_orders'
export const BODY_SHOP_ORDERS = 'body_shop_orders'
export const APPRAISAL_ORDERS = 'appraisal_orders'
export const USER_MANAGEMENT_GARAGE = 'user_management_garage'
export const ROLE_MANAGEMENT_GARAGE = 'role_management_garage'
export const SYSTEMS_AND_COMPONENTS_MANAGEMENT = 'systems_and_components_management'
export const INSURANCE_MANAGEMENT = 'insurance_management'
export const WORKSHOP_SETUP = 'workshop_setup'
export const REPORTS_GARAGE = 'reports_garage'
export const HISTORY_OF_NOTIFICATIONS_AND_INCOMPLETE_ORDERS = 'history_of_notifications_and_incomplete_orders'
export const DASHBOARD_GARAGE = 'dashboard_garage'


export const WORK_ORDER_MANAGEMENT_BODY_SHOP = 'work_order_management_body_shop'
export const WORK_ORDER_MANAGEMENT_MECHANIC = 'work_order_management_mechanic'
export const WORK_ORDER_MANAGEMENT_APPRAISAL = 'work_order_management_appraisal'

export const PERMISOS = [
    {
        'id': CUSTOMER_MANAGEMENT,
        'name': 'Gestión clientes'
    },
    {
        'id': DELETE_RECORDS,
        'name': 'Eliminar registros'
    },
    {
        'id': WORK_ORDER_MANAGEMENT,
        'name': 'Gestión ordenes de trabajo'
    },
    {
        'id': MY_WORK_ORDERS,
        'name': 'Mis ordenes de trabajo'
    },
    {
        'id': MECHANICS_ORDERS,
        'name': 'Ordenes de mecánica'
    },
    {
        'id': BODY_SHOP_ORDERS,
        'name': 'Ordenes de enderezado y pintura'
    },
    {
        'id': APPRAISAL_ORDERS,
        'name': 'Ordenes de avaluó'
    },
    {
        'id': USER_MANAGEMENT_GARAGE,
        'name': 'Gestión usuarios'
    },
    {
        'id': ROLE_MANAGEMENT_GARAGE,
        'name': 'Gestión roles'
    },
    {
        'id': SYSTEMS_AND_COMPONENTS_MANAGEMENT,
        'name': 'Gestión sistemas y componentes'
    },
    {
        'id': INSURANCE_MANAGEMENT,
        'name': 'Gestión aseguradoras'
    },
    {
        'id': WORKSHOP_SETUP,
        'name': 'Configuración del taller'
    },
    {
        'id': REPORTS_GARAGE,
        'name': 'Reportes'
    },
    {
        'id': HISTORY_OF_NOTIFICATIONS_AND_INCOMPLETE_ORDERS,
        'name': 'Historial de notificaciones y ordenes incompletas'
    },
    {
        'id': DASHBOARD_GARAGE,
        'name': 'Dashboard'
    },
]

// ------------------------------------------------------------------------------------
//?  Estados de un taller
export const ACTIVE_GARAGE = 10
export const INACTIVE_GARAGE = 20
export const PENDING_GARAGE = 30

//? Tipo de pago
export const MONTHLY_PAYMENT = 11
export const ANNUAL_PAYMENT = 21
