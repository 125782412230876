import React, { useRef } from 'react';
import './UploadDriver.css';
import PropTypes from 'prop-types';
const ArchiveIcon = require('assets/img/icons/warning.svg');
const alerta_img = require('assets/img/icons/warning.svg');
import { SWALMODERROR } from '../../../../utility/constants'

const errorSwal = (text) => {
    SWALMODERROR.fire({
        title: '¡Información!',
        text: text,
        confirmButtonText: 'Regresar',
        reverseButtons: true,
        imageUrl: alerta_img,
        imageAlt: 'Alerta',
        imageWidth: 90,
    })
}
const UploadDriver = props => {
    const input = useRef();
    const onFileChange = (e, files) => {
        let tipo_archivo_error = false
        files = files || e.target.files;

        if (props.maxFiles) {
            if ((props.value.length + files.length) > props.maxFiles) {
                errorSwal(`${props.maxFiles} archivos máximo.`)
                return
            }
        }

        let extension = ['doc', 'docx', 'dot', 'jpeg', 'jpg', 'png', 'pdf', 'xlsx'];
        let texto_error = texto_error = '¡Selecciona un archivo de tipo IMAGEN, PDF, EXCEL ó WORD!'

        if (props.imageOnly) {
            extension = ['jpeg', 'jpg', 'png'];
            texto_error = "¡Selecciona un archivo de tipo IMAGEN!"
        }

        for (let i = 0; i < files.length; i++) {
            let nombre = files[i].name
            let finalNombre = nombre.slice((nombre.lastIndexOf(".") - 1 >>> 0) + 2);
            if (extension.includes(finalNombre)) {
            } else {
                tipo_archivo_error = true
            }
        }
        if (tipo_archivo_error) {
            errorSwal(texto_error)
        } else {
            props.onChange(files)
        }
    };
    const onClick = () => {
        input.current.click();
    };

    const onDrop = e => {
        e.preventDefault();
        onFileChange(e, e.dataTransfer.files);
    };
    const onDragOver = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <div key="label" className="upload-driver-container py-4" onDrop={onDrop} onDragOver={onDragOver}>

                <input
                    type="file"
                    onChange={onFileChange}
                    style={{ display: 'none' }}
                    ref={input}
                    multiple
                ></input>
                {props.imageOnly ?
                    <p className="text-16 text-center m-0 pb-3">Click ó arrastra Imagenes</p>
                    :
                    <p className="text-16 text-center m-0 pb-3">Click ó arrastra (Pdf, Word, Excel, Imagen)</p>
                }
                <div className="d-flex justify-content-center">
                    <button className="btn btn-danger" type="button" onClick={onClick}>
                        {props.imageOnly ?
                            "Cargar Imagenes"
                            :
                            "Cargar Archivos"
                        }
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

UploadDriver.propTypes = {
    onChange: PropTypes.func.isRequired,
    // onCopy: PropTypes.func.isRequired,
    // driverTitle: PropTypes.string.isRequired,
};

export default UploadDriver;
