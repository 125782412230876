import React, { Component, Fragment } from 'react'
import CardFormOrden from 'Utils/Cards/CardFormOrden';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Formulario from './Formulario';
import { StandardActionsTracking } from "../../../Utils/Grid/StandardActionsTracking";
import { formData } from '../../OrdenesTrabajoAdmin/Crear/DatosComun/Metodos';

export default class Diagnostico extends Component {
    state = {
        fired: false,
    };

    componentWillMount() {
        if (this.props.match.params.id && this.props.isQA !== true) {
            this.props.leer(this.props.match.params.id);
        }
    }

    pauseTimer = (ev) => {
        if(this.state.fired == false) {
            this.setState({ fired: true })
            const id = this.props.match.params.id
            this.props.pauseTimer(id)
        }
    }

    componentWillUnmount() {
        //this.pauseTimer()
    }

    crear = (data, { crear = true, isQA = false, isCrear = false }) => {
        const { data_task } = this.props
        if(isQA === true) data.order_mechanic = data_task || {}
        data.crear = crear;
        data.isQA = isQA;
        data.isCrear = isCrear;
        if (crear) {
            data.order_mechanic.status = 30;
        }
        else {
            data.order_mechanic.status = 20;
        }

        const { arrayFiles, data: _data } = formData(data)
        console.log('la info', _data, arrayFiles);
        this.props.crear(this.props.match.params.id, _data, arrayFiles, isQA, isCrear);
    };

    accion_tracking(id_task, accion) {
        const idWorkOrder = this.props.match.params.id || 0
        this.props.accionTask(idWorkOrder, id_task, accion, true)
    }

    render() {
        const { loader, item, isQA=false } = this.props;
        const { order_mechanic } = item || {}
        const { task_diagnostic } = order_mechanic || {}
        const { status_in_action = {}, time = "00:00:00", id } = task_diagnostic || {}

        if(isQA !== true) {
            window.addEventListener("beforeunload", this.pauseTimer);
        }

        if(isQA == true) {
            return (
                <LoadMask loading={loader} blur>
                    <Formulario
                        onSubmit={this.crear}
                        {...this.props}
                    />
                </LoadMask>
            )
        } else {
            return (
                <CardFormOrden
                    titulo="DIAGNÓSTICO"
                    orden={item && item.order_number}
                    timer={task_diagnostic ?
                        <div className="mt-1 mt-md-2 d-flex" style={{ height: 25 }}>
                            {StandardActionsTracking({ play: status_in_action.play, no_ver_finalized: true, finalized: status_in_action.finalized, onChange: this.accion_tracking.bind(this), })(id)}                        <span className="text-18 ml-3 my-auto">
                                {time}
                            </span>
                        </div>
                        : ""
                    }
                >
                    <LoadMask loading={loader} blur>
                        <Formulario
                            onSubmit={this.crear}
                            {...this.props}
                        />
                    </LoadMask>
                </CardFormOrden>
            )
        }
    }
}

