export { default as AdminOrdenesTrabajoHome } from './Home'
export { default as AdminOrdenTrabajoSelect } from './Home/SelectOrden'

export { default as AdminTrabajoEnderezadoPinturaCrear } from './Crear/EnderezadoPintura'
export { default as AdminTrabajoEnderezadoPinturaListar } from './Listar/EnderezadoPintura'
export { default as AdminTrabajoEnderezadoPinturaFacturacion } from './Facturacion/EnderezadoPintura'
export { default as AdminTrabajoEnderezadoPinturaCotizar } from './EnderezadoPintura/Cotizacion'
export { default as FacturacionPDF } from './FacturacionPDF'


export { default as AdminTrabajoMecanicaCrear } from './Crear/Mecanica'
export { default as AdminOrdenTrabajoMecanicaListar } from './Listar/Mecanica'
export { default as AdminTrabajoMecanicaCotizar } from './Mecanica/Cotizacion'
export { default as AdminTrabajoMecanicaEnProgreso } from './Mecanica/EnProgreso'
export { default as AdminTrabajoMecanicaControlCalidad } from './Mecanica/ControlCalidad'
export { default as AdminTrabajoMecanicaCotizacionPDF } from './Mecanica/CotizacionPDF'
export { default as AdminTrabajoMecanicaFacturacion } from './Facturacion/Mecanica'
export { default as AdminTrabajoMecanicaCotizarCliente } from './Mecanica/CotizacionCliente'


export { default as AdminTrabajoAvaluoCrear } from './Crear/Avaluo'
export { default as AdminTrabajoAvaluoListar } from './Listar/Avaluo'
export { default as AdminTrabajoAvaluoFacturacion } from './Facturacion/Avaluo'



