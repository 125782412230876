import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from 'validate-redux-form';
import { renderPasswordField, renderNumber, renderField, SelectField } from '../../../Utils/renderField';
import { ButtonSalirGuardar } from '../../../Utils/Button'
import { validationReceiptInfo } from '../../../../../utility/validationForm'
import { COUNTRIES } from '../../../../../utility/countries';
import { connect } from 'react-redux'

let ClienteForm = (props) => {
    const { handleSubmit, previousStep, editar } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.name">Nombre facturación*</label>
                    <Field name="receipt_info.name" component={renderField} type="text" className="form-control" maxLength={validationReceiptInfo.name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.phone_number">Teléfono</label>
                    <Field name="receipt_info.phone_number" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationReceiptInfo.phone_number.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.email">Email</label>
                    <Field name="receipt_info.email" component={renderField} type="email" className="form-control" maxLength={validationReceiptInfo.email.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.tax_id">ID tributario*</label>
                    <Field name="receipt_info.tax_id" component={renderField} type="text" className="form-control" maxLength={validationReceiptInfo.tax_id.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.country">País*</label>
                    <Field
                        valueKey="id"
                        name="receipt_info.country"
                        labelKey="name"
                        options={COUNTRIES}
                        component={SelectField}
                    />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.city">Ciudad*</label>
                    <Field name="receipt_info.city" component={renderField} type="text" className="form-control" maxLength={validationReceiptInfo.city.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="receipt_info.address">Dirección*</label>
                    <Field name="receipt_info.address" component={renderField} type="text" className="form-control" maxLength={validationReceiptInfo.address.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3 d-flex">
                    <p className="text-right azul text-12 bold align-self-end ml-auto mb-0">* Campos requeridos</p>
                </div>
            </div>
            <ButtonSalirGuardar link={previousStep} btnText1="Anterior" btnText2={editar ? "Actualizar" : "Siguiente"} />
        </form>
    );
};

export const matchPassword = (pass, confirm) =>
    validatorFromFunction((value) => {
        return pass === confirm;
    });

ClienteForm = reduxForm({
    form: 'ClienteForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data = {}) => {
        let errors = {};
        errors.receipt_info = validate(data.receipt_info || {}, {
            name: validationReceiptInfo.name.Combine,
            tax_id: validationReceiptInfo.tax_id.Combine,
            country: validationReceiptInfo.country.Combine,
            city: validationReceiptInfo.city.Combine,
            address: validationReceiptInfo.address.Combine,
            phone_number: validationReceiptInfo.phone_number.Combine,
            email: validationReceiptInfo.email.Combine,
        })
        return errors
    },
})(ClienteForm);

export default ClienteForm = connect(
    (state, props) => {
        let values = state.form.ClienteForm.values ? { ...state.form.ClienteForm.values } : {}
        if (props.creando) {
            values.receipt_info = {
                name: values.name,
                country: values.country,
                city: values.city,
                address: values.address,
            }
        }

        return ({
            initialValues: values,
        })
    }
)(ClienteForm)
