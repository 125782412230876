import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch, HeaderSearch } from '../../../Utils/Header'
import { ROLE_MANAGEMENT_GARAGE } from '../../../../../utility/constants';
import moment from 'moment/moment';
import { TaskListar } from '..';



export default class Listar extends Component {

    state = {
        BreakLineCenter: {
            whiteSpace: 'normal', 
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal'
        },
        AlignCenter: {
            textAlign: 'center'
        }
    }

    componentWillMount() {
        const { listarTaskIncomplete } = this.props;
        const id = this.props.match.params.id || 0
        listarTaskIncomplete(id);
    }

    render() {
        const { item, loader } = this.props; //Constantes
        let description_exists = false
        item.results.forEach(task => {
            if(task.description) {
                if(task.description.replaceAll(" ", "") != "")
                    description_exists = true
            }
        })
        return (
            <Card
                titulo="Tareas"
                subTitulo={item && item.data ? item.data.order_number : ''}
                
            >
                <Grid hover striped data={item} loading={loader} pagination={false}>
                    <TableHeaderColumn
                        isKey
                        dataField="replacement"
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLine}            
                    >
                        NOMBRE
                    </TableHeaderColumn>
                    {description_exists &&
                    <TableHeaderColumn
                        dataField="description"    
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLine}                  
                    >
                        DESCRIPCIÓN
                    </TableHeaderColumn>
                    }
                    <TableHeaderColumn
                        dataField="time"
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}   
                    >
                        TIEMPO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="status"
                        dataFormat={(cell) => {
                            if (cell == 10) {
                                return 'Por comenzar'
                            }
                            if (cell == 20) {
                                return 'Empezada'
                            }
                            if (cell == 30) {
                                return 'Pausada'
                            }
                            if (cell == 40) {
                                return 'Finalizada'
                            }
                        }}      
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}                         
                    >
                        ESTADO
                    </TableHeaderColumn>
                </Grid>
            </Card>
        )
    }
}
