import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/ordenesTrabajoAdmin/enderezadoPintura';
import Crear from './Crear';

const ms2p = (state) => {
    const profile = state.login.me;
    return {
        ...state.adminEnderezadosPinturas,
        data_formOrders: state.form.WorkOrdersForm,
        profile,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Crear);
