import React, { Component, Fragment } from 'react'
import Grid from "../../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import defaultLogo from 'assets/img/place_holder/image_placeholder_small.png'

export default class Listar extends Component {

    state = {
        BreakLineCenter: {
            whiteSpace: 'normal', 
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal'
        },
        AlignCenter: {
            textAlign: 'center'
        }
    }

    render() {
        const { data_factura, loader } = this.props; //Constantes
        return (

            <div className='card-content-form2 px-0 px-0'>
                <div className="col-12 col-lg-9">

                    {data_factura && data_factura.results.map((item, index) => (
                        <Fragment key={item.name}>
                            <div className={`col-12 padding-custom mb-0 mb-md-4 ${index == 0 ? "" : "mt-5"}`}>
                                <span className="text-25 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>DETALLES DE LA ORDEN</span>
                            </div>
                            <Grid hover striped data={item} loading={loader} pagination={false}>
                                <TableHeaderColumn
                                    dataField="replacement"
                                    dataSort
                                    isKey
                                    thStyle={this.state.AlignCenter}
                                    tdStyle={this.state.BreakLineCenter}
                                >
                                    NOMBRE
                        </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="description"
                                    dataSort
                                    thStyle={this.state.AlignCenter}
                                    tdStyle={this.state.BreakLineCenter}
                                >
                                    DESCRIPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                                    dataField="observation"
                                    dataSort
                                    thStyle={this.state.AlignCenter}
                                    tdStyle={this.state.BreakLineCenter}
                                >
                                    OBSERVACIÓN
                        </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="time"
                                    dataSort
                                    width='90px'
                                    thStyle={this.state.AlignCenter}
                                    tdStyle={this.state.BreakLineCenter}
                                >
                                    TIEMPO
                        </TableHeaderColumn>
                            </Grid>
                        </Fragment>
                    ))}
                    <span className="text-25 bold rojo d-block pr-auto text-right mr-2 mt-3">TOTAL: {data_factura.total_timer || "00:00:00"}</span>
                </div>
            </div>
        )
    }
}
