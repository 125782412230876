import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { Login, Profile, Registro, ForgotPassword, ChangePassword, Suscripcion } from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from './common/components/Examples/Grids';
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';

import { RolesListar, RolCrear } from './common/components/App/Roles'
import { UsuariosListar, UsuarioCrear } from './common/components/App/Usuarios'
import { Dashboard } from './common/components/App/Dashboard';
import NotificacionesVencidas  from './common/components/App/NotificacioncesVencidas/Listar';

// - * * * * * * * * * * * * * * * * ** *
import { ClientesListar, ClienteCrear, CambiarEstado } from './common/components/App/Clientes'
import { AseguradorasListar, AseguradoraCrear } from './common/components/App/Aseguradoras';
import { CarrosListar, CarroCrear, CarroListarOrdenes } from './common/components/App/Carros';
import { ConfiguracionesListar } from './common/components/App/Configuraciones';
import { SistemasCarroListar, SistemaCarroCrear, SistemaBodyCrear} from './common/components/App/SistemasCarro';
import { SucursalCrear } from './common/components/App/Sucursales';
import {
    AdminOrdenesTrabajoHome,
    AdminOrdenTrabajoSelect,

    AdminTrabajoEnderezadoPinturaCrear,
    AdminTrabajoEnderezadoPinturaListar,
    AdminTrabajoEnderezadoPinturaCotizar,
    AdminTrabajoEnderezadoPinturaFacturacion,
    FacturacionPDF,

    AdminTrabajoMecanicaCrear,
    AdminOrdenTrabajoMecanicaListar,
    AdminTrabajoMecanicaCotizar,
    AdminTrabajoMecanicaCotizacionPDF,
    AdminTrabajoMecanicaCotizarCliente,
    AdminTrabajoMecanicaFacturacion,
    AdminTrabajoMecanicaEnProgreso,
    AdminTrabajoMecanicaControlCalidad,

    AdminTrabajoAvaluoCrear,
    AdminTrabajoAvaluoListar,
    AdminTrabajoAvaluoFacturacion,
} from './common/components/App/OrdenesTrabajoAdmin';
import {
    OperadorDiagnosticoEnderezadoPintura,
    OperadorTrackingEnderezadoPintura,
    OperadorOrdenesTrabajoListar,
    OperadorTrackingAvaluo,
    OperadorDignosticoMecanica,
    OperadorTrackingMecanica
} from './common/components/App/OrdenesTrabajoOperador';

import { CarrosIncompletosListar, OrdenesIncompletasListar, TaskListar } from './common/components/App/OrdenesIncompletas'
import { CollaboratorsListar, CollaboratorsOrdersListar, CollaboratorsOrdersDetails } from './common/components/App/Colaboradores'
import { BitacoraCollaborators, BitacoraCollaboratorsOrdersList, BitacoraCollaboratorsOrdersDetails } from './common/components/App/Bitacora'
import {
    CUSTOMER_MANAGEMENT,
    WORK_ORDER_MANAGEMENT,
    MY_WORK_ORDERS,
    MECHANICS_ORDERS,
    BODY_SHOP_ORDERS,
    APPRAISAL_ORDERS,
    USER_MANAGEMENT_GARAGE,
    ROLE_MANAGEMENT_GARAGE,
    SYSTEMS_AND_COMPONENTS_MANAGEMENT,
    INSURANCE_MANAGEMENT,
    WORKSHOP_SETUP,
    REPORTS_GARAGE,
    HISTORY_OF_NOTIFICATIONS_AND_INCOMPLETE_ORDERS,

    WORK_ORDER_MANAGEMENT_BODY_SHOP,
    WORK_ORDER_MANAGEMENT_MECHANIC,
    WORK_ORDER_MANAGEMENT_APPRAISAL,
} from './utility/constants';

require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/register/plan/:idPlan/pay/:idMode" component={Registro} />
                <Route exact path="/suscriptions" component={Suscripcion} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/forgot-password/:email" component={ForgotPassword} />
                <Route exact path="/change-password/:token" component={ChangePassword} />

                <ProtectedRoute exact path="/demo" component={Demo} />
                <ProtectedRoute exact path="/" component={Dashboard} />

                {/* ---------------- Validación de la cotización por el client ------------- */}
                <Route exact path="/accept_quotation/:token" component={AdminTrabajoMecanicaCotizarCliente} />

                {/* ---------------- Cambio de estado por parte del cliente ------------- */}
                <Route exact path="/change_order_state/:token" component={CambiarEstado} />

                {/* * * * * * * * * * * * * * * * * * * * SYS TALLER * * * * * * * * * * * * * * * * * * * * *  */}
                <ProtectedRoute exact path="/clients" component={ClientesListar} permission={[CUSTOMER_MANAGEMENT]} />
                <ProtectedRoute exact path="/client/create" component={ClienteCrear} permission={[CUSTOMER_MANAGEMENT]} />
                <ProtectedRoute exact path="/client/:id/edit" component={ClienteCrear} permission={[CUSTOMER_MANAGEMENT]} />

                <ProtectedRoute exact path="/client/:idClient/cars" component={CarrosListar} permission={[CUSTOMER_MANAGEMENT]} />
                <ProtectedRoute exact path="/client/:idClient/car/create" component={CarroCrear} permission={[CUSTOMER_MANAGEMENT]} />
                <ProtectedRoute exact path="/client/:idClient/car/:id/edit" component={CarroCrear} permission={[CUSTOMER_MANAGEMENT]} />
                <ProtectedRoute exact path="/client/:idClient/car/:id/orders" component={CarroListarOrdenes} permission={[CUSTOMER_MANAGEMENT]} />


                <ProtectedRoute exact path="/insurance-carriers" component={AseguradorasListar} permission={[INSURANCE_MANAGEMENT]} />
                <ProtectedRoute exact path="/insurance-carrier/create" component={AseguradoraCrear} permission={[INSURANCE_MANAGEMENT]} />
                <ProtectedRoute exact path="/insurance-carrier/:id/edit" component={AseguradoraCrear} permission={[INSURANCE_MANAGEMENT]} />

                <ProtectedRoute exact path="/configurations" component={ConfiguracionesListar} permission={[WORKSHOP_SETUP]} />

                <ProtectedRoute exact path="/garage_branches/create" component={SucursalCrear} permission={[WORKSHOP_SETUP]} />
                <ProtectedRoute exact path="/garage_branches/:id/edit" component={SucursalCrear} permission={[WORKSHOP_SETUP]} />

                <ProtectedRoute exact path="/car-systems" component={SistemasCarroListar} permission={[SYSTEMS_AND_COMPONENTS_MANAGEMENT]} />
                <ProtectedRoute exact path="/car-system/create" component={SistemaCarroCrear} permission={[SYSTEMS_AND_COMPONENTS_MANAGEMENT]} />
                <ProtectedRoute exact path="/car-system/:id/edit" component={SistemaCarroCrear} permission={[SYSTEMS_AND_COMPONENTS_MANAGEMENT]} />

                {/* ENDEREZADO Y PINTURA */}
                <ProtectedRoute exact path="/body-system/create" component={SistemaBodyCrear} permission={[SYSTEMS_AND_COMPONENTS_MANAGEMENT]} />
                <ProtectedRoute exact path="/body-system/:id/edit" component={SistemaBodyCrear} permission={[SYSTEMS_AND_COMPONENTS_MANAGEMENT]} />


                {/* ##########-##########-##########-Admin taller ##########-##########-##########- */}
                <ProtectedRoute exact path="/work-orders" component={AdminOrdenesTrabajoHome} permission={[WORK_ORDER_MANAGEMENT]} />
                <ProtectedRoute exact path="/work-order/select" component={AdminOrdenTrabajoSelect} permission={[WORK_ORDER_MANAGEMENT]} />

                <ProtectedRoute exact path="/work-order/:id/order_closing/pdf/:back?" component={FacturacionPDF} permission={[WORK_ORDER_MANAGEMENT]} />
                {/* ----------Mecanica---------- */}
                <ProtectedRoute exact path="/work-order/mechanic/create" component={AdminTrabajoMecanicaCrear} permission={[WORK_ORDER_MANAGEMENT_MECHANIC]} />
                <ProtectedRoute exact path="/work-order/mechanic/:id/edit" component={AdminTrabajoMecanicaCrear} permission={[WORK_ORDER_MANAGEMENT_MECHANIC]} />

                <ProtectedRoute exact path="/mechanic_orders" component={AdminOrdenTrabajoMecanicaListar} permission={[WORK_ORDER_MANAGEMENT_MECHANIC]} />
                <ProtectedRoute exact path="/mechanic_order/:id/quotation" component={AdminTrabajoMecanicaCotizar} permission={[WORK_ORDER_MANAGEMENT_MECHANIC]} />
                <ProtectedRoute exact path="/mechanic_order/:id/quotation/pdf" component={AdminTrabajoMecanicaCotizacionPDF} permission={[WORK_ORDER_MANAGEMENT_MECHANIC]} />
                <ProtectedRoute exact path="/mechanic_order/:id/in_progress" component={AdminTrabajoMecanicaEnProgreso} permission={[WORK_ORDER_MANAGEMENT_MECHANIC]} />
                <ProtectedRoute exact path="/mechanic_order/:id/quality_control" component={AdminTrabajoMecanicaControlCalidad} permission={[WORK_ORDER_MANAGEMENT_MECHANIC]} />

                <ProtectedRoute exact path="/mechanic-order/:id/order_closing" component={AdminTrabajoMecanicaFacturacion} permission={[WORK_ORDER_MANAGEMENT_MECHANIC]} />
                {/* ----------Enderezado y Pintura---------- */}
                <ProtectedRoute exact path="/work-order/body-shop/create" component={AdminTrabajoEnderezadoPinturaCrear} permission={[WORK_ORDER_MANAGEMENT_BODY_SHOP]} />
                <ProtectedRoute exact path="/work-order/body-shop/:id/edit" component={AdminTrabajoEnderezadoPinturaCrear} permission={[WORK_ORDER_MANAGEMENT_BODY_SHOP]} />

                <ProtectedRoute exact path="/body-shop-orders" component={AdminTrabajoEnderezadoPinturaListar} permission={[WORK_ORDER_MANAGEMENT_BODY_SHOP]} />
                <ProtectedRoute exact path="/body-shop-order/:id/order_closing" component={AdminTrabajoEnderezadoPinturaFacturacion} permission={[WORK_ORDER_MANAGEMENT_BODY_SHOP]} />
                <ProtectedRoute exact path="/body_shop_order/:id/quotation" component={AdminTrabajoEnderezadoPinturaCotizar} permission={[WORK_ORDER_MANAGEMENT_BODY_SHOP]}/>

                {/* ----------Avaluo---------- */}
                <ProtectedRoute exact path="/work-order/appraisal/create" component={AdminTrabajoAvaluoCrear} permission={[WORK_ORDER_MANAGEMENT_APPRAISAL]} />
                <ProtectedRoute exact path="/work-order/appraisal/:id/edit" component={AdminTrabajoAvaluoCrear} permission={[WORK_ORDER_MANAGEMENT_APPRAISAL]} />

                <ProtectedRoute exact path="/appraisal-orders" component={AdminTrabajoAvaluoListar} permission={[WORK_ORDER_MANAGEMENT_APPRAISAL]} />
                <ProtectedRoute exact path="/appraisal-order/:id/order_closing" component={AdminTrabajoAvaluoFacturacion} permission={[WORK_ORDER_MANAGEMENT_APPRAISAL]} />


                {/* ##########-##########-##########-Operador taller##########-##########-##########- */}
                <ProtectedRoute exact path="/mywork-orders" component={OperadorOrdenesTrabajoListar} permission={[MY_WORK_ORDERS]} />
                {/* ----------Mecanica---------- */}
                <ProtectedRoute exact path="/mywork-order/mechanic/:id/diagnosis" component={OperadorDignosticoMecanica} permission={[MECHANICS_ORDERS]} />
                <ProtectedRoute exact path="/mywork-order/mechanic/:id/tracking" component={OperadorTrackingMecanica} permission={[MECHANICS_ORDERS]} />
                {/* ----------Enderezado y Pintura---------- */}
                <ProtectedRoute exact path="/mywork-order/body-shop/:id/tracking" component={OperadorTrackingEnderezadoPintura} permission={[BODY_SHOP_ORDERS]} />
                <ProtectedRoute exact path="/mywork-order/body-shop/:id/diagnosis" component={OperadorDiagnosticoEnderezadoPintura} permission={[BODY_SHOP_ORDERS]} />
                {/* ----------Avaluo---------- */}
                <ProtectedRoute exact path="/mywork-order/appraisal/:id/tracking" component={OperadorTrackingAvaluo} permission={[APPRAISAL_ORDERS]} />
                 {/* ----------Notificaciones vencidas---------- */}
                <ProtectedRoute exact path="/overdue-notifications" component={NotificacionesVencidas} permission={[WORK_ORDER_MANAGEMENT]} />

                <ProtectedRoute exact path="/roles" component={RolesListar} permission={[ROLE_MANAGEMENT_GARAGE]} />
                <ProtectedRoute exact path="/role/create" component={RolCrear} permission={[ROLE_MANAGEMENT_GARAGE]} />
                <ProtectedRoute exact path="/role/:id/edit" component={RolCrear} permission={[ROLE_MANAGEMENT_GARAGE]} />

                <ProtectedRoute exact path="/users" component={UsuariosListar} permission={[USER_MANAGEMENT_GARAGE]} />
                <ProtectedRoute exact path="/user/create" component={UsuarioCrear} permission={[USER_MANAGEMENT_GARAGE]} />
                <ProtectedRoute exact path="/user/:id/edit" component={UsuarioCrear} permission={[USER_MANAGEMENT_GARAGE]} />

                <ProtectedRoute exact path="/cars/incomplete-orders" component={CarrosIncompletosListar} permission={[WORK_ORDER_MANAGEMENT]} />
                <ProtectedRoute exact path="/cars/incomplete-orders/:id/tasks" component={OrdenesIncompletasListar} permission={[WORK_ORDER_MANAGEMENT]} />
                <ProtectedRoute exact path="/cars/tasks/:id" component={TaskListar} permission={[WORK_ORDER_MANAGEMENT]} />

                <ProtectedRoute exact path="/collaborators" component={CollaboratorsListar} permission={[REPORTS_GARAGE]} />
                <ProtectedRoute exact path="/collaborator/:id" component={CollaboratorsOrdersListar} permission={[REPORTS_GARAGE]} />
                <ProtectedRoute exact path="/collaborator/:idCollaborator/tasks/:id" component={CollaboratorsOrdersDetails} permission={[REPORTS_GARAGE]} />

                <ProtectedRoute exact path="/bitacora-collaborators" component={BitacoraCollaborators} permission={[REPORTS_GARAGE]} />
                <ProtectedRoute exact path="/bitacora-collaborator/:id" component={BitacoraCollaboratorsOrdersList} permission={[REPORTS_GARAGE]} />
                <ProtectedRoute exact path="/bitacora-collaborator/:idCollaborator/tasks/:id" component={BitacoraCollaboratorsOrdersDetails} permission={[REPORTS_GARAGE]} />

                <ProtectedRoute exact path="/user-profile" component={Profile} />

                {/* <ProtectedRoute exact path="/page2" component={Examples} />
                <ProtectedRoute exact path="/grids" component={Grids} />
                <ProtectedRoute exact path="/notifications" component={Notificaciones} />
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} /> */}
                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
)
