import React, { Component, Fragment } from 'react'
import moment from 'moment/moment';
import Card from '../../../Utils/Cards/Card';
import { PrintContainer, actions as printActions } from 'Utils/Print';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import { Redirect } from 'react-router-dom';
import Grid from "../../..//Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { ORDER_BODY_SHOP, ORDER_MECHANIC, ORDER_APPRAISAL } from '../../../../../utility/constants'
import defaultLogo from 'assets/img/place_holder/image_placeholder_small.png'

import './index.css';
const logo = require('assets/img/default-logo.png');

export default class ReportImp extends Component {
    state = {
        date_after: moment().format('DD/MM/YYYY'),
        fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A'),
        name_print_component: 'boleta-report',
        extender: false,
        BreakLineCenter: {
            whiteSpace: 'normal',
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal',
            textAlign: 'justify',
        },
        AlignCenter: {
            textAlign: 'center'
        },
    }

    componentWillMount() {
        this.props.idOrder(this.props.profile.id_garage)
        if (this.props.match.params.id) {
            this.props.getPDF(this.props.match.params.id);
        }
    }

    imprimirBoleta = (event) => {
        this.setState({
            fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A')
        }, () => {
            printActions.print(this.state.name_print_component);
            // this.gogoBack()
        });
    }
    gogoBack = () => {
        const { order_type = "" } = this.props.pdf_data.data
        const { back = "" } = this.props.match.params
        if (back == "back") {
            this.props.history.goBack()
        } else if (order_type == ORDER_APPRAISAL) {
            this.props.history.push("/appraisal-orders")
        } else if (order_type == ORDER_MECHANIC) {
            this.props.history.push("/mechanic_orders")
        } else if (order_type == ORDER_BODY_SHOP) {
            this.props.history.push("/body-shop-orders")
        } else {
            this.props.history.push("/")
        }
    }


    render() {
        const { pdf_data = {}, order, loader } = this.props;
        const { data_historial = [], data = {} } = pdf_data || {}
        const { client = {}, receipt_info_garage_or_branch = {}, order_number = "" } = data || {}
        const { receipt_info = {} } = client || {}

        return (
            <div>
                <Card>
                    <PrintContainer name={this.state.name_print_component}>
                        <div className="col-12 p-0">
                            <div className="row contenido-impresion d-flex justify-content-center">
                                <div className="col-12 row">
                                    <div className="col-12 d-flex justify-content-end mt-0">
                                        <span>Orden: {order_number}</span>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-0">
                                        <div className="col-11 pl-2">
                                            <div className="row col-12 p-0 mt-3 d-flex justify-content-center align-pdf_datas-center">
                                                <img
                                                    className="d-inline-block align-top"
                                                    src={order && order.logo ? order.logo : logo}
                                                    alt="Logo"
                                                    height="100px"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center mt-3">
                                        <div className="col-11">
                                            <div className="row col-12 p-0 mt-2 justify-content-center align-pdf_datas-start">
                                                <div className="col-12 p-0 fondo">
                                                    <div className="col-12 d-flex flex-column">
                                                        <span className="text-20 text-uppercase rojo0a text p-0 m-0">{receipt_info_garage_or_branch.name || ''}</span>
                                                        <span className="negro32 p-0 m-0">ID tributario: {receipt_info_garage_or_branch.tax_id}</span>
                                                        <span className="negro32 p-0 m-0">Telefono: {receipt_info_garage_or_branch.phone_number}</span>
                                                        <span className="negro32 p-0 m-0">Email: {receipt_info_garage_or_branch.email}</span>
                                                        <span className="negro32 p-0 m-0">{receipt_info_garage_or_branch.address} {receipt_info_garage_or_branch.city} {receipt_info_garage_or_branch.country_text} </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row  p-0 mt-3">
                                                <div className="ajuste p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <table id="t01">
                                                            <tr>
                                                                <th className="tc1 text-center" colspan="2">DATOS DEL CLIENTE</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Nombre</th>
                                                                <td>{receipt_info.name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>ID tributario</th>
                                                                <td>{receipt_info.tax_id}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Telefono</th>
                                                                <td>{receipt_info.phone_number || "--"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Email</th>
                                                                <td>{receipt_info.email || "--"}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>País</th>
                                                                <td>{receipt_info.country_text}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Ciudad</th>
                                                                <td>{receipt_info.city}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Dirección</th>
                                                                <td>{receipt_info.address}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Fecha</th>
                                                                <td>{this.state.fechaHoraImpresion}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row col-12 p-0 mt-5">
                                        <div className="col-12 p-0">
                                            <div className="col-12 d-flex flex-column">
                                                <table className='tabla' id="assigments">
                                                    <tr>
                                                        <th style={{ whiteSpace: 'pre', textAlign: "center", width: 50 }}>#</th>
                                                        <th className='column-resp' style={{ whiteSpace: 'pre', textAlign: "center" }}>DESCRIPCIÓN</th>
                                                        <th style={{ whiteSpace: 'pre', textAlign: "center", width: 100 }}>CANTIDAD</th>
                                                        <th className='column-resp1' style={{ whiteSpace: 'pre', textAlign: "center" }}>TOTAL</th>
                                                    </tr>
                                                    {
                                                        data && data.additionals && (
                                                            data.additionals.map((data, index) => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <tr key={index} >
                                                                            <td style={{ textAlign: "center", width: 50 }}>
                                                                                <span>{index + 1}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span>{data.description}</span>
                                                                            </td>
                                                                            <td style={{ textAlign: "center", width: 100 }}>
                                                                                <span>1</span>
                                                                            </td>
                                                                            <td style={{ textAlign: "center", width: 200 }}>
                                                                                <RenderCurrency value={data.amount ? data.amount : 0} />
                                                                            </td>
                                                                        </tr>

                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        )
                                                    }
                                                    <tr>
                                                        <td style={{ textAlign: "right" }} colspan="3" class="bold text-20">TOTAL</td>
                                                        <td style={{ whiteSpace: 'pre', textAlign: "center" }}>
                                                            <RenderCurrency value={data && data.total_amount ? data.total_amount : 0} className="text-30 bold negro" />

                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.extender && data_historial &&
                                        <div className="col-12">
                                            <div className={`col-12 padding-custom mb-0 mb-md-2 mt-5`}>
                                                <span className="text-30 bold d-block text-center" >Detalle del trabajo</span>
                                            </div>
                                            {data_historial.order_appraisal &&
                                                <Fragment>
                                                    <div className={`col-12 padding-custom mb-0 mb-md-2 mt-5`}>
                                                        <span className="text-25 bold d-block text-center" >Orden avalúo</span>
                                                    </div>
                                                    {data_historial.order_appraisal.results.map((item, index) => (
                                                        <Fragment key={item.name}>
                                                            <Grid hover striped data={item} loading={loader} pagination={false}>
                                                                <TableHeaderColumn
                                                                    isKey
                                                                    dataField="name"
                                                                    dataSort
                                                                >
                                                                    NOMBRE
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField="time"
                                                                    width="100"
                                                                    dataSort
                                                                >
                                                                    TIEMPO
                                                                </TableHeaderColumn>
                                                            </Grid>
                                                        </Fragment>
                                                    ))}
                                                </Fragment>
                                            }

                                            {data_historial.order_mechanic &&
                                                <Fragment>
                                                    <div className={`col-12 padding-custom mb-0 mb-md-2 mt-5`}>
                                                        <span className="text-25 bold d-block text-center" >Orden mecánica</span>
                                                    </div>
                                                    {data_historial.order_mechanic.results.map((item, index) => (
                                                        <Fragment key={item.name}>
                                                            <Grid hover striped data={item} loading={loader} pagination={false}>
                                                                <TableHeaderColumn
                                                                    isKey
                                                                    dataField="replacement"
                                                                    dataSort
                                                                    thStyle={this.state.AlignCenter}
                                                                    tdStyle={this.state.BreakLineCenter}
                                                                >
                                                                    NOMBRE
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField="description"
                                                                    dataSort
                                                                    thStyle={this.state.AlignCenter}
                                                                    tdStyle={this.state.BreakLine}
                                                                >
                                                                    DESCRIPCIÓN
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField="observation"
                                                                    dataSort
                                                                    thStyle={this.state.AlignCenter}
                                                                    tdStyle={this.state.BreakLine}
                                                                >
                                                                    OBSERVACIÓN
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField="quotation"
                                                                    dataFormat={(cell, row) => {
                                                                        let approved_provider = ''
                                                                        if(cell){
                                                                            if(cell.approved_provider1){
                                                                                approved_provider = cell.provider1
                                                                            }else if(cell.approved_provider2){
                                                                                approved_provider = cell.provider2
                                                                            }else if(cell.approved_provider3){
                                                                                approved_provider = cell.provider3
                                                                            }
                                                                        }
                                                                        return (<span>{approved_provider}</span>)
                                                                    } }
                                                                    width="400"
                                                                    thStyle={this.state.AlignCenter}
                                                                    tdStyle={this.state.BreakLine}
                                                                >
                                                                    Proveedor
                                                                </TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField="time"
                                                                    width="100"
                                                                    dataSort
                                                                >
                                                                    TIEMPO
                                                                </TableHeaderColumn>
                                                            </Grid>
                                                        </Fragment>
                                                    ))}
                                                </Fragment>
                                            }

                                            {data_historial.order_body_shop &&
                                                <Fragment>
                                                    <div className={`col-12 padding-custom mb-0 mb-md-2 mt-5`}>
                                                        <span className="text-25 bold d-block text-center" >Orden enderezado y pintura</span>
                                                    </div>
                                                    {data_historial.order_body_shop.results.map((item, index) => {
                                                        if (item.name !== 'Por Cotizar') {
                                                            return (
                                                                <Fragment key={item.name}>
                                                                    <div className={`col-12 padding-custom mb-0 mb-md-4 ${index == 0 ? "" : "mt-5"}`}>
                                                                        <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>{item.name}</span>
                                                                    </div>
                                                                    <Grid hover striped data={item} loading={loader} pagination={false}>
                                                                        <TableHeaderColumn
                                                                            isKey
                                                                            dataField="part"
                                                                            width='80px'
                                                                            dataFormat={(cell, row) => <img src={cell || defaultLogo} height="30" />}
                                                                            thStyle={this.state.AlignCenter}
                                                                            tdStyle={this.state.BreakLineCenter}
                                                                        >
                                                                            PARTE
                                                                        </TableHeaderColumn>
                                                                        <TableHeaderColumn
                                                                            dataField="name"
                                                                            dataSort
                                                                            dataFormat={cell => (cell !== 'diagnostic_tasks' ? cell : 'Diagnóstico')}
                                                                            thStyle={this.state.AlignCenter}
                                                                            tdStyle={this.state.BreakLineCenter}
                                                                        >
                                                                            NOMBRE
                                                                        </TableHeaderColumn>
                                                                        <TableHeaderColumn
                                                                            dataField="description"
                                                                            dataSort
                                                                            thStyle={this.state.AlignCenter}
                                                                            tdStyle={this.state.BreakLine}
                                                                        >
                                                                            DESCRIPCIÓN
                                                                        </TableHeaderColumn>
                                                                        <TableHeaderColumn
                                                                            dataField="time"
                                                                            width="100"
                                                                            dataSort
                                                                        >
                                                                            TIEMPO
                                                                        </TableHeaderColumn>
                                                                    </Grid>
                                                                </Fragment>
                                                            )
                                                        }
                                                    })}
                                                </Fragment>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </PrintContainer>
                </Card>
                <div className="mb-4 mt-2 col-12 text-right pointer">
                    <span className="text-16 azul bold" onClick={() => {
                        this.setState({ extender: !this.state.extender })
                    }}>{this.state.extender ? "Eliminar detalle del trabajo" : "Agregar detalle del trabajo"} </span>
                </div>

                <div className="mb-4 col-6 p-0 mx-auto">
                    <div className="card-form flex-column flex-sm-row d-flex mt-5">
                        <div className="flex-1 text-right padding-custom">
                            <button type="button" className="btn btn-block btn-gris text-16 btn-limit mx-auto mr-sm-0" onClick={(e) => {
                                e.preventDefault();
                                this.gogoBack()
                            }} >Regresar</button>
                        </div>
                        <div className="flex-1 padding-custom mt-sm-0 mt-3">
                            <button type="button" className="btn btn-block btn-primary text-16 mx-auto btn-limit text-nowrap" onClick={this.imprimirBoleta} >Imprimir</button>
                        </div>
                    </div >
                </div>
            </div >
        )
    }
}
