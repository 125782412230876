import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch, HeaderSearch } from '../../../Utils/Header'
import { ROLE_MANAGEMENT_GARAGE } from '../../../../../utility/constants';
import moment from 'moment/moment';



export default class Listar extends Component {
    componentWillMount() {
        const { listarOrderIncomplete } = this.props;
        const id = this.props.match.params.id || 0
        listarOrderIncomplete(id);
    }

    render() {
        const { data_order, loader } = this.props; //Constantes
        return (
            <Card
                titulo="Ordenes incompletas"
            // subTitulo={data_order && data_order.results[0] ? data_order.results[0].car.name_full : ''}

            >
                <Grid hover striped data={data_order} loading={loader} pagination={false}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='10%'
                        dataFormat={standardActions({ ver: '/cars/tasks' })}
                    />
                    <TableHeaderColumn
                        dataField="order_number"
                    >
                        NUMERO ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="order_type"
                        dataFormat={(cell) => {
                            if (cell == 'order_mechanic') {
                                return 'Mecánica'
                            }
                            if (cell == 'order_body_shop') {
                                return 'Enderezado y Pintura'
                            }
                            if (cell == 'order_appraisal') {
                                return 'Avalúo'
                            }
                        }}
                    >
                        TIPO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="finish_date"
                        dataFormat={cell => cell ? moment(cell).format('DD/MM/YYYY') : '---'}
                        dataSort
                    >
                        FECHA DE CIERRE
                    </TableHeaderColumn>

                </Grid>
            </Card>
        )
    }
}
