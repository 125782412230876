import React, { Fragment } from 'react';
import { Link } from "react-router-dom";


export function ButtonSalir(props) {
    const { btnText1 } = props;
    const { link } = props;
    const { dos } = props
    let botonFuncion = false
    if (typeof (link) === "function") {
        botonFuncion = true
    }
    return (
        <Fragment>
            {botonFuncion ?
                <button type="button" className={`btn btn-block btn-gris text-16 btn-limit mx-auto ${dos ? "mr-sm-0" : "mt-5"}`} onClick={link} >{btnText1 || "Salir"}</button>
                :
                <Link className={`btn btn-block btn-gris text-16 btn-limit mx-auto   ${dos ? "mr-sm-0" : "mt-5"}`} to={`${link || '/'}`}>{btnText1 || "Salir"}</Link>
            }
        </Fragment>
    );
}

export function ButtonSalirGuardar(props) {
    const { btnText1, btnText2, formSubmit, link2, mt } = props;
    const { link } = props;


    // link 2
    let botonFuncion = false
    if (typeof (link2) === "function") {
        botonFuncion = true
    }


    return (
        <div className={`card-form flex-column flex-sm-row d-flex ${!mt ? 'mt-5' : ''}`}>
            <div className="flex-1 text-right padding-custom">
                <ButtonSalir btnText1={btnText1} link={link} dos />
            </div>
            <div className="flex-1 padding-custom mt-sm-0 mt-3">
                {link2 ?
                    <Fragment>
                        {botonFuncion ?
                            <button type="button" className="btn btn-block btn-primary text-16 ml-sm-0 mx-auto btn-limit text-nowrap" onClick={link2} >{btnText2 || "Guardar"}</button>
                            :
                            <Link className="btn btn-block btn-primary text-16 ml-sm-0 mx-auto btn-limit text-nowrap" to={`${link2 || '/'}`}>{btnText2 || "Guardar"}</Link>
                        }
                    </Fragment>
                    :
                    <Fragment>
                        {formSubmit ?
                            <button form={formSubmit} type="submit" className="btn btn-block btn-primary text-16 ml-sm-0 mx-auto btn-limit text-nowrap">{btnText2 || "Guardar"}</button>
                            :
                            <button type="submit" className="btn btn-block btn-primary text-16 ml-sm-0 mx-auto btn-limit text-nowrap">{btnText2 || "Guardar"}</button>
                        }
                    </Fragment>
                }

            </div>
        </div >
    );
}



export function ButtonFacturacion(props) {
    const { btnText1, btnText2, link2 } = props;
    const { link } = props;
    return (
        <div className="card-form flex-column flex-sm-row d-flex mt-5">
            <div className="flex-1 text-right padding-custom">
                <ButtonSalir btnText1={btnText1} link={link} dos />
            </div>
            <div className="flex-1 padding-custom mt-sm-0 mt-3">
                <button type="submit" className="btn btn-block btn-primary text-16 mx-auto btn-limit text-nowrap">Guardar</button>
            </div>
            <div className="flex-1 padding-custom mt-sm-0 mt-3">
                <button type="button" className="btn btn-block btn-primary text-16 ml-sm-0 mx-auto btn-limit text-nowrap" onClick={link2} >Cerrar orden</button>
            </div>
        </div >
    );
}



export function ButtonEnderezadoPinturaGuardar(props) {
    const { btnText1, btnText2, link2 } = props;
    const { link } = props;
    return (
        <div className="card-form flex-column flex-sm-row d-flex mt-5">
            <div className="flex-1 text-right padding-custom">
                <ButtonSalir btnText1={btnText1} link={link} dos />
            </div>
            <div className="flex-1 padding-custom mt-sm-0 mt-3">
                <button type="button" className="btn btn-block btn-primary text-16 mx-auto btn-limit text-nowrap" onClick={link2} >{btnText2 || "Guardar"}</button>
            </div>
            <div className="flex-1 padding-custom mt-sm-0 mt-3">
                <button type="submit" className="btn btn-block btn-primary text-16 ml-sm-0 mx-auto btn-limit text-nowrap">Finalizar asignación</button>
            </div>
        </div >
    );
}


export function ButtonMecanicaGuardar(props) {
    const { btnText1, btnText2, link2 } = props;
    const { link } = props;
    return (
        <div className="card-form flex-column flex-sm-row d-flex mt-5">
            <div className="flex-1 text-right padding-custom">
                <ButtonSalir btnText1={btnText1} link={link} dos />
            </div>
            <div className="flex-1 padding-custom mt-sm-0 mt-3">
                {/* <button type="submit" className="btn btn-block btn-primary text-16 ml-sm-0 mx-auto btn-limit text-nowrap">{btnText2 || "Guardar"}</button> */}

                <button type="button" onClick={link2} className="btn btn-block btn-primary text-16 mx-auto btn-limit text-nowrap">{btnText2 || "Guardar"}</button>
            </div>
            <div className="flex-1 padding-custom mt-sm-0 mt-3">
                <button type="submit" className="btn btn-block btn-primary text-16 ml-sm-0 mx-auto btn-limit text-nowrap">Finalizar diagnóstico</button>
            </div>
        </div >
    );
}

export function ButtonCotizacionEnviar(props) {
    const { btnText1, btnText2, link2, link3 } = props;
    const { link } = props;
    return (
        <div className="card-form flex-column flex-sm-row d-flex mt-5">
            <div className="flex-1 text-right padding-custom">
                <ButtonSalir btnText1={btnText1} link={link} dos />
            </div>
            <div className="flex-1 padding-custom mt-sm-0 mt-3">
                <button type="button" onClick={link2} className="btn btn-block btn-primary text-16 mx-auto btn-limit text-nowrap">{btnText2 || "Guardar"}</button>
            </div>
            <div className="flex-1 padding-custom mt-sm-0 mt-3">
                <button type="button" onClick={link3} className="btn btn-block btn-primary text-16 ml-sm-0 mx-auto btn-limit text-nowrap">Finalizar</button>
            </div>
        </div >
    );
}
