import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";

const LOADER = 'LOADER_CARROS';
const DATA = 'DATA_CARROS';
const ITEM_DATA = 'ITEM_CARROS';
const PAGE = 'PAGE_CARROS';

const DATA_ORDENES = 'DATA_ORDENES_CARROS';
const PAGE_ORDENES = 'PAGE_ORDENES_CARROS';
const SEARCH_ORDENES = 'SEARCH_CARRO_ORDENES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});


const setSearchOrdenes = search_ordenes => ({
    type: SEARCH_ORDENES,
    search_ordenes,
});
const setDataOrdenes = data_ordenes => ({
    type: DATA_ORDENES,
    data_ordenes,
});
const setPageOrdenes = page_ordenes => ({
    type: PAGE_ORDENES,
    page_ordenes,
});

// ------------------------------------
// Actions
// ------------------------------------
const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('car', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push(`/client/${data.client}/cars`));
    }).catch((error) => {
        let mensaje = 'Error en la creación, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leer = (id, idClient = 0) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'car'}/${(id)}`, { client_id: idClient }).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('CarroForm', response));
    }).catch(() => {
        dispatch(push('/'));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const listar = (page = 1, idClient = 0) => (dispatch, getStore) => {
    const resource = getStore().carros;
    const params = { page, client_id: idClient };

    dispatch(setLoader(true));
    api.get('car', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarOrdenes = (page = 1, id = 0) => (dispatch, getStore) => {
    const resource = getStore().carros;
    const params = {page};
    if (resource.search_ordenes)
        params.search_ordenes = resource.search_ordenes;
    dispatch(setLoader(true));
    api.get(`${'car'}/${(id)}/orders`, params).then((response) => {
        dispatch(setDataOrdenes(response));
        dispatch(setPageOrdenes(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`car/${id}`, data)
        .then(() => {
            NotificationManager.success('Registro actualizado', 'Éxito', 3000);
            dispatch(push(`/client/${data.client}/cars`));
        })
        .catch((error) => {
            let mensaje = 'Error en la edición, vuelve a intentarlo';
            if (error) {
                if (error.detail)
                    mensaje = error.detail;
            }
            NotificationManager.error(mensaje, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


const eliminar = (id, idClient) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`car/${id}`).then(() => {
        dispatch(listar(1, idClient));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        let mensaje = 'Error en la eliminación, vuelve a intentarlo';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('CarroForm', {}));
};

const searchChangeOrdenes = (search_ordenes, id) => (dispatch) => {
    dispatch(setSearchOrdenes(search_ordenes));
    dispatch(listarOrdenes(1, id));
};


const clearSelect = (select) => (dispatch, getStore) => {
    const FORM_PRINCIPAL = getStore().form.CarroForm.values;

    let form_principal = FORM_PRINCIPAL ? _.cloneDeep(FORM_PRINCIPAL) : {};

    if (select == 'model')
        delete form_principal.model

    if (select == 'insurance_carrier')
        form_principal.insurance_carrier = null

    dispatch(initializeForm('CarroForm', form_principal));
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    cleanForm,
    clearSelect,
    searchChangeOrdenes,
    listarOrdenes,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SEARCH_ORDENES]: (state, { search_ordenes }) => {
        return {
            ...state,
            search_ordenes,
        };
    },
    [DATA_ORDENES]: (state, { data_ordenes }) => {
        return {
            ...state,
            data_ordenes,
        };
    },
    [PAGE_ORDENES]: (state, { page_ordenes }) => {
        return {
            ...state,
            page_ordenes,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    search_ordenes: '',
    data_ordenes: {
        results: [],
        count: 0,
    },
    page_ordenes: 1,
};

export default handleActions(reducers, initialState);
