import React, { Component, Fragment } from 'react'
import Grid from "../../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import defaultLogo from 'assets/img/place_holder/image_placeholder_small.png'

export default class Listar extends Component {


    render() {
        const { data_factura, loader } = this.props; //Constantes
        return (
            <div className='card-content-form2 px-0 px-0'>
                <div className="col-12 col-lg-9">

                    {data_factura && data_factura.results.map((item, index) => {
                        if (item.name !== 'Por Cotizar') {
                            return (
                                <Fragment key={item.name}>
                                    <div className={`col-12 padding-custom mb-0 mb-md-4 ${index == 0 ? "" : "mt-5"}`}>
                                        <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>{item.name}</span>
                                    </div>
                                    <Grid hover striped data={item} loading={loader} pagination={false}>
                                        <TableHeaderColumn
                                            isKey
                                            dataField="part"
                                            tdStyle={{ filter: 'none' }}
                                            width='80px'
                                            dataFormat={(cell, row) => <img src={cell || defaultLogo} height="30" />}
                                        >
                                            PARTE
                                        </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="name"
                                                    dataSort
                                                    dataFormat={cell => (cell !== 'diagnostic_tasks' ? cell : 'Diagnóstico')}
                                                >
                                                    NOMBRE
                                        </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="description"
                                                    dataSort
                                                >
                                                    DESCRIPCIÓN
                                        </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="time"
                                                    dataSort
                                                    width='90px'
                
                                                >
                                                    TIEMPO
                                        </TableHeaderColumn>
                                    </Grid>
                                    <span className="text-16 rojo d-block pr-auto text-right mr-2">Subtotal: {item.sub_total || "00:00:00"}</span>
                                </Fragment>
                            )
                        }
                    })}
                    <span className="text-25 bold rojo d-block pr-auto text-right mr-2 mt-3">TOTAL: {data_factura.total_timer || "00:00:00"}</span>
                </div>
            </div>
        )
    }
}
