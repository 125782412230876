import React, { Component } from 'react'
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch } from '../../../Utils/Header'
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import ConfiguracionGeneral from './ConfiguracionGeneral';
import DatosTaller from './DatosTaller'
import ListarSucursales from '../../Sucursales/Listar/Listar'
export default class TabsListar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logo: null,
        };
    }

    componentWillMount() {
        if (this.props.me.id_garage){
            this.props.leer(this.props.me.id_garage);
        }
    }

    setImg = (logo) => {
        this.setState({ logo });
    };

    update = (_data) => {
        let data = {..._data}
        this.props.editar(
            this.props.me.id_garage,
            { ...data, logo: null},
            [{ "file": this.state.logo, "name": "logo" }]
        );
    }

    render() {
        return (
            <Card
                titulo="CONFIGURACIÓN"
                subTitulo="General"
            >
                <Tabs
                    defaultActiveKey="GENERAL"
                    tabBarPosition="top"
                    onChange={this.callback}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane
                        tab="GENERAL"
                        key="GENERAL"
                    >
                        <ConfiguracionGeneral {...this.props} onSubmit={this.update} />
                    </TabPane>
                    <TabPane
                        tab="SUCURSALES"
                        key="SUCURSALES"
                    >
                        <ListarSucursales {...this.props} />
                    </TabPane>
                    <TabPane
                        tab="DATOS DE TALLER"
                        key="DATOS_DE_TALLER"
                    >
                        <DatosTaller {...this.props} setImg={this.setImg} onSubmit={this.update} />
                    </TabPane>
                    
                </Tabs>
            </Card>
        )
    }
}
