import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styleGrid.css';
import Swal from 'sweetalert2';

import svgDelete from '../../../../../assets/img/icons_table/delete.svg'
import svgEdit from '../../../../../assets/img/icons_table/edit.svg'
import svgSee from '../../../../../assets/img/icons_table/see.svg'
import svgReport from '../../../../../assets/img/icons_table/report.svg'
import svgIncompleteOrder from '../../../../../assets/img/icons_table/incomplete_order.svg'
import svgCar from '../../../../../assets/img/icons_table/car.svg'
import pngCarritoCompra from '../../../../../assets/img/icons_table/carrito_compra.png'


import { DELETE_RECORDS, DIAGNOSTIC, IN_PROGRESS, QUALITY_CONTROL, STATUS_ORDER_BODY_SHOP } from '../../../../utility/constants'
import { connect } from 'react-redux';
const warningSvg = require('assets/img/icons/warning.svg');
import { SWALMOD } from '../../../../utility/constants'
class Acciones2 extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            SWALMOD.fire({
                title: '¿Eliminar?',
                text: '¡No podrás revertir esta acción!',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    };

    eliminarEP = (id) => {
        return () => {
            SWALMOD.fire({
                title: '¿Eliminar?',
                text: '¡No podrás revertir esta acción!',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarEP(id);
                }
            });
        };
    };

    render() {

        const { ordenes_facturadas, row, id, ver, editar, editarEP, eliminar, eliminarEP, me, verCarro, createTask, F_editar, verOrdenBodyShop, cotizacion, cotizacion_enderezado, in_progress, facturar, incomplete_order, ver_factura } = this.props;
        let createTaskLink = '/'
        const state_color = row.state ? 
                                row.state == 10 ? 'orange' : 
                                row.state == 20 ? 'green' : 
                                row.state == 30 ? 'red' : 
                                null : 
                            null
        const state_title = row.state ? 
                                row.state == 10 ? 'Pendiente de aceptar' : 
                                row.state == 20 ? 'Aceptada' : 
                                row.state == 30 ? 'Rechazada' : 
                                null : 
                            null
        if (createTask) {
            if (row.order_mechanic.status == DIAGNOSTIC) {
                createTaskLink = `/mywork-order/mechanic/${id}/diagnosis`
            } else if (row.order_mechanic.status == IN_PROGRESS || row.order_mechanic.status == QUALITY_CONTROL) {
                createTaskLink = `/mywork-order/mechanic/${id}/tracking`
            }
        }

        return (
            <div className="d-flex justify-content-center">
                {row.order_body_shop
                    ?
                    ((verOrdenBodyShop && row.order_body_shop.status !== STATUS_ORDER_BODY_SHOP['DIAGNOSTIC']) && (
                        <Link to={`${verOrdenBodyShop[0]}${id}${verOrdenBodyShop[1]}`} className="btn btn-primary btn-sm text-11 bold">VER ORDEN</Link>
                    ))
                    :
                    ((verOrdenBodyShop && row.order_appraisal) && (
                        <Link to={`${verOrdenBodyShop[0]}${id}${verOrdenBodyShop[1]}`} className="btn btn-primary btn-sm text-11 bold">VER ORDEN</Link>
                    ))
                }

                {row.order_body_shop &&
                    (verOrdenBodyShop && row.order_body_shop.status === STATUS_ORDER_BODY_SHOP['DIAGNOSTIC']) && (
                        <Link to={`${verOrdenBodyShop[0]}${id}/diagnosis`} className="btn btn-primary btn-sm text-11 bold">VER DIAGNÓSTICO</Link>
                    )
                }

                {F_editar !== undefined && (
                    <div data-toggle="tooltip" data-placement="left" title="Editar" onClick={() => { F_editar(id) }}>
                        <img className="icon_svg" src={svgEdit} style={{ cursor: "pointer" }} />
                    </div>
                )}

                {me.permissions.includes(DELETE_RECORDS) && eliminar !== undefined && (
                    <a
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Eliminar"
                        style={{ cursor: 'pointer' }}
                        onClick={this.eliminar(id)}
                    >
                        <img className="icon_svg" src={svgDelete} style={{ cursor: "pointer" }} />
                    </a>
                )}

                {/* ENDEREZADO & PINUTRA */}
                {me.permissions.includes(DELETE_RECORDS) && eliminarEP !== undefined && (
                    <a
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Eliminar"
                        style={{ cursor: 'pointer' }}
                        onClick={this.eliminarEP(id)}
                    >
                        <img className="icon_svg" src={svgDelete} style={{ cursor: "pointer" }} />
                    </a>
                )}
                {editar !== undefined && (
                    <Link to={`${editar}/${id}/edit`} data-toggle="tooltip" data-placement="left" title="Editar">
                        <img className="icon_svg" src={svgEdit} style={{ cursor: "pointer" }} />
                    </Link>
                )}

                {/* ENDEREZADO & PINTURA */}
                {editarEP !== undefined && (
                    <Link to={`${editarEP}/${id}/edit`} data-toggle="tooltip" data-placement="left" title="Editar">
                        <img className="icon_svg" src={svgEdit} style={{ cursor: "pointer" }} />
                    </Link>
                )}

                {ver !== undefined && (
                    <Link to={`${ver}/${id}/`} data-toggle="tooltip" data-placement="left" title="Ver">
                        <img className="icon_svg" src={svgSee} style={{ cursor: "pointer" }} />
                    </Link>
                )}

                {verCarro && (
                    <Link to={`client/${id}/cars`} data-toggle="tooltip" data-placement="left" title="Ver Carros">
                        <img className="icon_svg" src={svgCar} style={{ cursor: "pointer" }} />
                    </Link>
                )}

                {createTask && (
                    <Link to={createTaskLink} className="btn btn-primary btn-sm text-11 bold">VER ORDEN</Link>
                )}
                {facturar && (
                    <Link to={`${facturar[0]}${id}${facturar[1]}`} data-toggle="tooltip" data-placement="left" title="Facturar">
                        <img className="icon_svg" src={pngCarritoCompra} style={{ cursor: "pointer" }} />
                    </Link>
                )}

                {cotizacion && (
                    <Link to={`mechanic_order/${id}/quotation`} data-toggle="tooltip" data-placement="left" title="Crear Cotización">
                        <img className="icon_svg" src={svgCar} style={{ cursor: "pointer" }} />
                    </Link>
                )}

                {cotizacion_enderezado && (
                    <Link to={`body_shop_order/${id}/quotation`} data-toggle="tooltip" data-placement="left" title="Crear Cotización">
                        <img className="icon_svg" src={svgCar} style={{ cursor: "pointer" }} />
                    </Link>
                )}

                {in_progress && (
                    <Link to={`mechanic_order/${id}/${in_progress}`} data-toggle="tooltip" data-placement="left" title="Ver Detalle">
                        <img className="icon_svg" src={svgCar} style={{ cursor: "pointer" }} />
                    </Link>
                )}
                {incomplete_order && (
                    <Link to={`/cars/incomplete-orders/${id}/tasks`} data-toggle="tooltip" data-placement="left" title="Ver Orden Incompleta">
                        <img className="icon_svg" src={svgIncompleteOrder} style={{ cursor: "pointer" }} />
                    </Link>
                )}
                {ver_factura && (
                    <Link to={`/work-order/${id}/order_closing/pdf${ver_factura == "back" ? "/back" : ""}`} data-toggle="tooltip" data-placement="left" title="Ver Factura">
                        <img className="icon_svg" src={svgReport} style={{ cursor: "pointer" }} />
                    </Link>
                )}

                {ordenes_facturadas !== undefined && (
                    <Link to={`${ordenes_facturadas}/${id}/orders`} data-toggle="tooltip" data-placement="left" title="Ver ordenes facturadas">
                        <img className="icon_svg" src={svgIncompleteOrder} style={{ cursor: "pointer" }} />
                    </Link>
                )}
                {editar == 'work-order/mechanic' && state_color && row.state &&
                <div
                    data-toggle="tooltip" 
                    data-placement="left" 
                    title={state_title}
                    style={{
                        width: '15px',
                        height: '15px',
                        marginLeft: '5px',
                        borderWidth: '1px',
                        alignSelf: 'center',
                        borderStyle: 'solid',
                        borderColor: state_color,
                        backgroundColor: state_color,
                    }}
                />
                }
            </div>
        );
    }
}

const ms2p = (state) => {
    return {
        me: state.login.me,
    };
};
const md2p = {};
const Acciones = connect(ms2p, md2p)(Acciones2);

export function standardActions(acciones) {
    return (cell, row) => {
        return <Acciones id={cell} {...acciones} row={row} />;
    };
}
