import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate } from 'validate-redux-form';

import {
    renderFieldCheck,
    renderField,
} from '../../../Utils/renderField/renderField';

import { ButtonSalirGuardar } from '../../../Utils/Button'
import { validationConfiguration } from '../../../../../utility/validationForm'


let ConfigurationForm = (props) => {
    const { handleSubmit } = props;
    const [qa, setQa] = useState(true)

    return (
        <div className='card-content-form2 px-0 px-0'>
            <div className="col-12 col-lg-9">
                <form id="ConfigurationForm" onSubmit={handleSubmit}>
                    <div className="row card-form">
                        <div className="col-md-6 padding-custom mb-3">
                            <label htmlFor="brand">Símbolo monetario*</label>
                            <Field name="currency" component={renderField} type="text" className="form-control" placeholder="Símbolo monetario" maxLength="3" />
                        </div>

                        <div className="col-md-6 padding-custom mb-3">
                            <label htmlFor="qa">¿Incluir control de calidad en mis ordenes?</label>
                            <Field
                                name="qa"
                                onCambio={(e) => {
                                    if (!e)
                                        setQa(false)
                                }}
                                component={renderFieldCheck}
                            />
                        </div>
                        <div className="col-12 padding-custom mb-3">
                            <p className="text-right azul text-12 bold">* Campos requeridos</p>
                        </div>
                    </div>
                    <ButtonSalirGuardar formSubmit="ConfigurationForm" link="/" />
                </form>
            </div>
        </div>
    );
};
ConfigurationForm = reduxForm({
    form: 'ConfigurationForm',
    validate: (data) => {
        let errors = {};
        return {
            ...errors, ...validate(data, {
                currency: validationConfiguration.currency.Combine,
            })
        }
    },
})(ConfigurationForm);

export default ConfigurationForm;
