import React, { useState, useEffect } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';


import {
    renderField,
    renderNumber,
} from '../../../Utils/renderField/renderField';

import { ButtonSalirGuardar } from '../../../Utils/Button'

import { RenderDollar } from '../../../Utils/renderField/renderReadField';
const minValue = min => value => value && value < min ? `El mínimo de orden es ${min}` : undefined
const minValueOne = minValue(1)
const _required = value => (value ? undefined : 'Campo requerido.');

const OrdenExtraForm = (props) => {
    const { handleSubmit, required = false, cost_per_additional_order } = props;
    const [orderExtra, setOrderExtra] = useState(0)
    const [costoTotal, setCostoTotal] = useState(0)
    useEffect(() => {
        setCostoTotal(cost_per_additional_order * orderExtra)
    }, [orderExtra])

    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form" style={{ maxWidth: 500 }}>
                <div className="col-12 padding-custom mb-4">
                    <span className="text-25 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>¡Llegaste a tu limite de ordenes!</span>
                </div>
                <div className="col-md-12 padding-custom mb-3">
                    <span className="d-block">Para poder continuar puedes adquirir ordenes adicionales que se cargaran a tu siguiente factura:</span>
                </div>
                <div className="col-md-12 padding-custom mb-3">
                    <span >Preció por orden adicional </span>
                    <RenderDollar value={cost_per_additional_order} className="text-18 bold negro mr-auto" />
                </div>
                <div className="col-md-12 padding-custom mb-3 d-flex">
                    <span >¿Cuantas ordenes adicionales deseas?</span>
                    <Field name="number_order" component={renderNumber} className="form-control" validate={[_required, minValueOne]} onCambio={(e) => {
                        setOrderExtra(e)
                    }} />
                </div>
                <div className="col-md-12 padding-custom mb-3 d-flex">
                    <span className="">Total: </span>
                    <RenderDollar value={costoTotal} className="text-18 bold negr ml-auto" />
                </div>
            </div>
            <ButtonSalirGuardar btnText1="Cancelar" link={required ? "/work-orders" : "/login"}
                btnText2="Adquirir"
            // link2=
            />
        </form>
    );
};

export default reduxForm({
    form: 'OrdenExtraForm',
})(OrdenExtraForm);
