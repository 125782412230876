import React, { Component, Fragment } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { ORDER_MECHANIC, ORDER_BODY_SHOP, ORDER_APPRAISAL } from '../../../../../utility/constants'
import moment from 'moment';
export default class Listar extends Component {

    state = {
        BreakLineCenter: {
            whiteSpace: 'normal', 
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal'
        },
        AlignCenter: {
            textAlign: 'center'
        }
    }

    render() {
        const { onSortChange, listar } = this.props; //Funciones
        const { data, loader, page, estado_mis_ordenes_tab } = this.props; //Constantes

        let actions = {}

        if (estado_mis_ordenes_tab == ORDER_MECHANIC) {
            actions = { createTask: true }
        }

        if (estado_mis_ordenes_tab == ORDER_BODY_SHOP) {
            actions = { verOrdenBodyShop: ['/mywork-order/body-shop/', '/tracking'] }
        }

        if (estado_mis_ordenes_tab == ORDER_APPRAISAL) {
            actions = { verOrdenBodyShop: ['/mywork-order/appraisal/', '/tracking'] }
        }

        return (
            <Fragment>
                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='150PX'
                        dataFormat={standardActions(actions)}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    />
                    <TableHeaderColumn
                        dataField="car"
                        dataFormat={cell => cell.name.split(" - ")[1]}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        MARCA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="car"
                        dataFormat={cell => cell.car_registration}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        MATRÍCULA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="order_number"
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        NUMERO ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="car"
                        dataFormat={cell => cell.vin}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        VIN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="creation_date"
                        dataFormat={cell => moment(cell).format('DD-MM-YYYY')}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        INGRESO
                    </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }
}
