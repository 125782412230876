import React, { Component } from 'react'
import Card from '../../../../Utils/Cards/Card'
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import History from './History'
import Details from './Details'
import LoadMask from 'Utils/LoadMask/LoadMask';
import { formData } from '../../Crear/DatosComun/Metodos';

export default class TabsListar extends Component {

    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.destroyForm()
            this.props.leer(this.props.match.params.id);
            this.props.timeHistory(this.props.match.params.id);
        }
    }

    actualizar = (_data) => {
        _data.files = _data.close_files;
        const { arrayFiles, data } = formData(_data);
        this.props.editar(this.props.match.params.id, { ...data, type: "close_orders" }, arrayFiles);
    };
    render() {
        const { item, data_factura, form_factura, loader, tax_percentage_garage } = this.props;
        return (
            <Card
                titulo="CIERRE DE ORDEN MECÁNICA"
            >
                <LoadMask loading={loader} blur>
                    <Tabs
                        defaultActiveKey="details"
                        tabBarPosition="top"
                        renderTabBar={() => <ScrollableInkTabBar />}
                        renderTabContent={() => <TabContent />}

                    >
                        <TabPane
                            tab="DETALLE DE CIERRE"
                            key='details'
                        >
                            <Details
                                onSubmit={this.actualizar}
                                item={item || {}}
                                data_factura={data_factura || {}}
                                form_factura={form_factura}
                                tax_percentage_garage={tax_percentage_garage}
                            />
                        </TabPane>
                        <TabPane
                            tab="HISTORIAL DE TIEMPO"
                            key='history'
                        >
                            <History {...this.props} />
                        </TabPane>
                    </Tabs>
                </LoadMask>
            </Card>
        )
    }
}
