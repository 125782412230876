import React, { useEffect, useState, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';

import {
    renderCurrency,
    renderTextArea,
    renderFieldCheck,
} from '../../../../Utils/renderField/renderField';
import {
    RenderCurrency
} from '../../../../Utils/renderField/renderReadField'
import Grid from "../../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { change as changeForm } from 'redux-form';

const required = value => value ? undefined : 'Campo requerido.'

const Repuestos = ({ loader, purchased_spare_parts, dispatch, tax_percentage_garage, form_factura }) => {
    const data = {
        results: purchased_spare_parts
    }
    return (
        <Fragment>
            <Grid hover striped data={data} loading={loader} pagination={false} className="no-hover">
                <TableHeaderColumn
                    dataField="replacement"
                    dataSort
                    isKey

                >
                    NOMBRE
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="description"
                    dataSort
                >
                    DESCRIPCIÓN
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="provider"
                    dataSort
                >
                    PROVEEDOR
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="tax"
                    dataSort
                    width='82px'
                    dataAlign="center"
                    dataFormat={(...arg) => (
                        <Field
                            name={`purchased_spare_parts[${arg[3]}].used`}
                            component={renderFieldCheck}
                        />
                    )}
                >
                    USADO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="tax"
                    dataSort
                    width='109px'
                    dataAlign="center"
                    dataFormat={(...arg) => (
                        <Field
                            name={`purchased_spare_parts[${arg[3]}].tax`}
                            component={renderFieldCheck}
                            onCambio3={(e) => {
                                let valor = 0
                                if (e) {
                                    valor = form_factura.values.purchased_spare_parts[arg[3]].price_provider * ((parseFloat(tax_percentage_garage) / 100) + 1)
                                } else {
                                    valor = form_factura.values.purchased_spare_parts[arg[3]].price_provider * 100
                                    valor = valor / (parseFloat(tax_percentage_garage) + 100)
                                }
                                dispatch(changeForm('WorkOrdersForm', `purchased_spare_parts[${arg[3]}].price_provider`, valor))
                            }}
                        />
                    )}
                >
                    IMPUESTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="time"
                    dataSort
                    width='170px'
                    dataFormat={(...arg) => (
                        <Field
                            name={`purchased_spare_parts[${arg[3]}].price_provider`}
                            component={renderCurrency}
                            validate={[required]}
                        />
                    )}
                >
                    PRECIO*
                </TableHeaderColumn>
            </Grid>
        </Fragment>
    )
}
const FacturaForm = (props) => {
    const { handleSubmit, data_factura, form_factura, item, loader, dispatch, tax_percentage_garage } = props;
    const { purchased_spare_parts = [] } = item || {}

    const {
        order_mechanic = {}
    } = data_factura || {}

    const {
        total_timer = "00:00:00",
        hourly_sale = 0,
        total_sale_of_labor_mechanic = 0,

        cost_hour = 0,
        total_cost_of_sale = 0,
    } = order_mechanic || {}

    const [total_pagar, setTotal_pagar] = useState(0)
    const [total_pagar_repuesto, setTotal_pagar_repuesto] = useState(0)


    useEffect(() => {
        if (form_factura && form_factura.values) {
            let total_to_pay = 0
            let total_repuesto = 0

            if (form_factura.values.data_close_order && form_factura.values.data_close_order.total_sale_of_labor_mechanic) {
                total_to_pay = parseFloat(form_factura.values.data_close_order.total_sale_of_labor_mechanic)
            }

            if (form_factura.values.purchased_spare_parts) {
                form_factura.values.purchased_spare_parts.forEach(item => {
                    if (item && item.price_provider) {
                        total_to_pay = total_to_pay + parseFloat(item.price_provider)
                        total_repuesto = total_repuesto + parseFloat(item.price_provider)
                    }
                });
                setTotal_pagar_repuesto(total_repuesto)
            }
            setTotal_pagar(total_to_pay)
        }
    }, [form_factura])

    return (
        <div className='card-content-form2 px-0 px-0'>
            <div className="col-12 col-lg-9">
                <form onSubmit={handleSubmit}>
                    <div className="row card-form">

                        <div className="col-12 padding-custom mb-0 mb-md-5 mt-2">
                            <span className="text-20 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>DETALLES DE ORDEN MECÁNICA</span>
                        </div>
                        <div className="col-md-6 padding-custom mb-3 ">
                            <span className="text-16 medium negro">COSTO POR MANO DE OBRA:</span>
                            <span className="d-block text-16 negro bold">
                                <RenderCurrency value={cost_hour} className="text-16 negro bold" />
                                &nbsp;* {total_timer}
                                &nbsp;=&nbsp;
                                <RenderCurrency value={total_cost_of_sale} className="text-16 verde bold" />
                            </span>
                        </div>
                        <div className="col-md-6 padding-custom mb-3 ">
                            <span className="text-16 medium negro">VENTA POR MANO DE OBRA:</span>
                            <span className="d-block text-16 negro bold">
                                <RenderCurrency value={hourly_sale} className="text-16 negro bold" />
                                &nbsp;* {total_timer}
                                &nbsp;=&nbsp;
                                <RenderCurrency value={total_sale_of_labor_mechanic} className="text-16 rojo bold" />
                                <Field
                                    name="data_close_order.total_sale_of_labor_mechanic"
                                    component={renderCurrency}
                                    validate={[required]}
                                />
                            </span>
                        </div>

                        <div className="col-12 padding-custom mb-3">
                            <label htmlFor="closing_observation">Observación</label>
                            <Field name="data_close_order.closing_observation_mechanic" component={renderTextArea} type="text" className="form-control" />
                        </div>
                        {(purchased_spare_parts && Array.isArray(purchased_spare_parts) && purchased_spare_parts.length > 0) &&
                            <div className="col-12 padding-custom mb-3 mt-4 input-border-verde">
                                <Repuestos loader={loader} purchased_spare_parts={purchased_spare_parts} dispatch={dispatch} tax_percentage_garage={tax_percentage_garage} form_factura={form_factura} />
                                <span className="text-20 rojo d-block pr-auto text-right mr-2">Subtotal:
                                    <RenderCurrency value={total_pagar_repuesto} className="text-20 rojo bold" />
                                </span>
                            </div>
                        }

                        <div className="col-12 padding-custom mb-3">
                            <span className="text-20 bold rojo d-block pr-auto text-right mr-2 mt-3">
                                Total orden enderezado y pintura: <RenderCurrency value={total_pagar} className="text-20 rojo bold" />
                            </span>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    );
};

export default reduxForm({
    form: 'WorkOrdersForm',
})(FacturaForm);
