import React, { Component, Fragment } from 'react'
import Grid from "../../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import Modal from "../../../../Utils/Modal/ReactModal";
import {
    RenderCurrency,
} from '../../../../Utils/renderField/renderReadField'
import moment from 'moment';
import ReactTooltip from "react-tooltip";
export default class Listar extends Component {
    constructor(props){
        super(props)
        this.state = {
            orden: "-----",
            openModal : false,
            mecanico: "N/A",
            descripcion: ".....",
        }
    }
    state = {
        BreakLineCenter: {
            whiteSpace: 'normal', 
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal'
        },
        AlignCenter: {
            textAlign: 'center'
        },
        elementos: {
            security_cap: "Tapa de seguridad",
            tow_hooks: "Ganchos de remolque",
            emblems: "Emblemas",
            car_documents: "Documentos de vehículos",
            radio: "Radio",
            carpet: "Alfombra",
            triangles: "Triangulos",
            coins: "Monedas",
            jewelry: "Joyeria",
            lenses: "Lentes",
            spare_parts: "Repuestos",
            hydraulic_jack: "Gato",
            tool_kit: "Estuche de herramienta",
            fire_extinguisher: "Extintor",
            air_conditioning: "Aire acondicionado funcionando",
            glass: "Vidrio funcionando",
        }
    }

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    componentDidUpdate() {
        ReactTooltip.rebuild()
    }

    truncate(source, size) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }


    render() {
        const { onSortChange, listar, eliminar, facturar, ver_factura, total_amount } = this.props;
        const { data, loader, page } = this.props;
        const detectMob = () => {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];
        
            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        }
        return (
            <Fragment>
                <Modal show_modal={this.state.openModal} >
                    <div className="card-content-form2 px-0 px-0 modal-mb" style={{minWidth: 350, maxWidth:900}}>
                        <div className="col-12 col-lg-8 px-0">
                            <h5>ORDEN: {this.state.orden}</h5>
                            <hr
                                style={{
                                    border: 'none',
                                    height: '1px',
                                    color: 'red',
                                    backgroundColor: 'red',
                                }}
                            />
                            <div className="card-form d-flex flex-column">
                                <p>Mecanico Operador: <br/> {this.state.mecanico}</p>
                                <p>Descripción: <br/> {this.state.descripcion}</p>
                            </div>
                            <div className='w-100 d-flex justify-content-center'>
                                <button onClick={ () => this.setState( { openModal: false } ) } className='btn btn-block btn-gris text-16 btn-limit mt-3'>
                                    CERRAR
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {data.results.map(item => (
                    <ReactTooltip 
                        className={"detail-tooltip"}
                        id={item.order_number+"-name_cell-"+item.client.id} 
                        key={item.order_number+"-tooltip-"+item.client.id} 
                        resizeHide={true}
                        multiline={true}
                        insecure={true}
                        effect="solid" 
                        place="right"
                        type="dark"
                        html={true}
                    />
                ))}
                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='150PX'
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                        dataFormat={standardActions({ editar: "work-order/appraisal", eliminar, facturar, ver_factura })}
                    />
                    <TableHeaderColumn
                        dataField="client"
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                        dataFormat={(cell, row) => {
                            if (detectMob()){
                                return (
                                    <span onClick={ () => this.setState( 
                                        {
                                            orden: row.order_number,
                                            openModal: true, 
                                            mecanico: row.order_appraisal.user ? row.order_appraisal.user.name : "N/A", 
                                            descripcion: row.order_appraisal.description && row.order_appraisal.description != "" ? row.order_appraisal.description: "N/A"
                                            } ) }>
                                        {cell.name}
                                    </span>
                                )
                            }else{
                                return (
                                    <Fragment>
                                        <div 
                                            data-for={row.order_number+"-name_cell-"+cell.id}
                                            data-tip={`
                                                <div>
                                                    <p>
                                                        Mecánico/Operador: 
                                                        ${row.order_appraisal.user ? row.order_appraisal.user.name : "N/A"}
                                                    </p>
                                                    Descripción:
                                                    ${row.order_appraisal.description && row.order_appraisal.description != "" ?
                                                        ("<p>"+row.order_appraisal.description+"</p>"):
                                                        "N/A<p/><p/>"
                                                    }
                                                </div>
                                            `} 
                                        >
                                            {cell.name}
                                        </div>
                                    </Fragment>
                                )
                            }
                        }}
                    >
                        CLIENTE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="order_number"
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        NUMERO ORDEN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="car"
                        dataFormat={cell => cell.car_registration}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        MATRÍCULA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="client"
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                        dataFormat={cell => cell.phone_number}
                    >
                        TELÉFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="car"
                        dataFormat={cell => cell.vin}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        VIN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="car"
                        dataFormat={cell => cell.name.split(" - ")[1]}
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                    >
                        MARCA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="creation_date"
                        thStyle={this.state.AlignCenter}
                        tdStyle={this.state.BreakLineCenter}
                        dataFormat={cell => moment(cell).format('DD-MM-YYYY')}
                    >
                        INGRESO
                    </TableHeaderColumn>
                    {total_amount &&
                        <TableHeaderColumn
                            dataField="total_amount"
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                            dataFormat={cell => <RenderCurrency value={cell || 0} />}
                        >
                            TOTAL FACTURADO
                        </TableHeaderColumn>
                    }
                </Grid>
            </Fragment >
        )
    }
}
