import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../../../Utils/renderField/renderField';
import { ButtonSalirGuardar } from '../../../../Utils/Button';
import { validationGarage } from '../../../../../../utility/validationForm';

const EmailModal = props => {
    const { handleSubmit, showModal } = props;

    return (
        <React.Fragment>
            <h6>Enviar listado de repuestos a proveedor</h6>
            <hr
                style={{
                    border: 'none',
                    height: '1px',
                    color: 'red',
                    backgroundColor: 'red',
                }}
            ></hr>
            <form onSubmit={handleSubmit}>
                <div className="row card-form">
                    <div className="col-12 padding-custom mb-3">
                        <label htmlFor="email">Email*</label>
                        <Field
                            name="email"
                            component={renderField}
                            type="email"
                            className="form-control"
                            maxLength={validationGarage.email.MaxLength}
                        />
                    </div>
                    <div className="col-12 padding-custom mb-3">
                        <p className="text-right azul text-12 bold">
                            * Campos requeridos
                        </p>
                    </div>
                </div>
                <ButtonSalirGuardar
                    btnText1="Cancelar"
                    link={showModal}
                    btnText2="Enviar"
                    mt={'asdf'}
                />
            </form>
        </React.Fragment>
    );
};

export default reduxForm({
    form: 'emailModalForm', // a unique identifier for this form
    validate: data => {
        return validate(data, {
            email: validators.exists()('Este campo es requerido'),
            email: validationGarage.email.Combine,
        });
    },
})(EmailModal);
