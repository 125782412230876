import React, { Component } from 'react'
import Card from '../../../../Utils/Cards/Card'
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Listar from './Listar'
import { NOT_ASSIGNED, DIAGNOSTIC, QUOTATION, IN_PROGRESS, QUALITY_CONTROL, TO_CHECK_IN, FINALIZED } from '../../../../../../utility/constants'
import { HeaderSearch } from '../../../../Utils/Header'

export default class TabsListar extends Component {
    render() {
        return (
            <Card
                style={{overflow: "visible"}}
                titulo="ORDENES MECANICAS"
                subTitulo=""
                header={
                    <HeaderSearch
                        {...this.props}
                    />
                }
            >
                <Tabs
                    defaultActiveKey={String(NOT_ASSIGNED)}
                    tabBarPosition="top"
                    onChange={(tab) => this.props.estadoMecanicaTab(tab)}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                    activeKey={String(this.props.estado_mecanica_tab)}
                    style={{overflow: "visible"}}
                >
                    <TabPane
                        tab="NO ASIGNADAS"
                        key={NOT_ASSIGNED}
                    >
                        <Listar {...this.props} />
                    </TabPane>
                    <TabPane
                        tab="DIAGNOSTICO"
                        key={DIAGNOSTIC}
                    >
                        <Listar {...this.props} />
                    </TabPane>
                    <TabPane
                        tab="POR COTIZAR"
                        key={QUOTATION}
                    >
                        <Listar {...this.props} cotizacion />
                    </TabPane>
                    <TabPane
                        tab="EN PROCESO"
                        key={IN_PROGRESS}
                    >
                        <Listar {...this.props} in_progress="in_progress" />
                    </TabPane>
                    <TabPane
                        tab="CONTROL DE CALIDAD"
                        key={QUALITY_CONTROL}
                    >
                        <Listar {...this.props} in_progress="quality_control" />
                    </TabPane>
                    <TabPane
                        tab="PARA FACTURAR"
                        key={TO_CHECK_IN}
                    >
                        <Listar {...this.props} facturar={["/mechanic-order/", "/order_closing"]} total_amount />
                    </TabPane>
                    <TabPane
                        tab="FINALIZADAS"
                        key={FINALIZED}
                    >
                        <Listar {...this.props} ver_factura total_amount />
                    </TabPane>
                </Tabs>
            </Card>
        )
    }
}
