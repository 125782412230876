import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';

import {
    renderCurrency,
    renderTextArea,
} from '../../../../Utils/renderField/renderField';
import {
    RenderCurrency
} from '../../../../Utils/renderField/renderReadField'

const required = value => value ? undefined : 'Campo requerido.'

const FacturaForm = (props) => {
    const { handleSubmit, data_factura, form_factura } = props;
    const {
        order_body_shop = {}
    } = data_factura || {}

    const {
        total_timer = "00:00:00",
        hourly_sale = 0,
        total_sale_of_labor_body_shop = 0,

        cost_hour = 0,
        total_cost_of_sale = 0,
    } = order_body_shop || {}

    const [total_pagar, setTotal_pagar] = useState(0)

    useEffect(() => {
        if (form_factura && form_factura.values) {
            if (form_factura.values.data_close_order && form_factura.values.data_close_order.total_sale_of_labor_body_shop) {
                setTotal_pagar(parseFloat(form_factura.values.data_close_order.total_sale_of_labor_body_shop))
            }
        }
    }, [form_factura])

    return (
        <div className='card-content-form2 px-0 px-0'>
            <div className="col-12 col-lg-9">
                <form onSubmit={handleSubmit}>
                    <div className="row card-form">

                        <div className="col-12 padding-custom mb-0 mb-md-5 mt-2">
                            <span className="text-20 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>DETALLES DE ORDEN ENDEREZADO Y PINTURA </span>
                        </div>
                        <div className="col-md-6 padding-custom mb-3 ">
                            <span className="text-16 medium negro">COSTO POR MANO DE OBRA:</span>
                            <span className="d-block text-16 negro bold">
                                <RenderCurrency value={cost_hour} className="text-16 negro bold" />
                                &nbsp;* {total_timer}
                                &nbsp;=&nbsp;
                                <RenderCurrency value={total_cost_of_sale} className="text-16 verde bold" />
                            </span>
                        </div>
                        <div className="col-md-6 padding-custom mb-3 ">
                            <span className="text-16 medium negro">VENTA POR MANO DE OBRA:</span>
                            <span className="d-block text-16 negro bold">
                                <RenderCurrency value={hourly_sale} className="text-16 negro bold" />
                                &nbsp;* {total_timer}
                                &nbsp;=&nbsp;
                                <RenderCurrency value={total_sale_of_labor_body_shop} className="text-16 rojo bold" />
                                <Field
                                    name="data_close_order.total_sale_of_labor_body_shop"
                                    component={renderCurrency}
                                    validate={[required]}
                                />
                            </span>
                        </div>

                        <div className="col-12 padding-custom mb-3">
                            <label htmlFor="closing_observation">Observación</label>
                            <Field name="data_close_order.closing_observation_body_shop" component={renderTextArea} type="text" className="form-control" />
                        </div>

                        <div className="col-12 padding-custom mb-3">
                            <span className="text-20 bold rojo d-block pr-auto text-right mr-2 mt-3">
                                Total orden enderezado y pintura: <RenderCurrency value={total_pagar} className="text-20 rojo bold" />
                            </span>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    );
};

export default reduxForm({
    form: 'WorkOrdersForm',
})(FacturaForm);
