import React, { useEffect, useState } from 'react';
import { Field, reduxForm, arrayInsert as arrayInsertForm, change as changeForm, unregisterField } from 'redux-form';
import {
    validate,
} from 'validate-redux-form';

import {
    renderField,
    renderNumber,
} from '../../../../Utils/renderField/renderField';
import { ButtonSalirGuardar } from '../../../../Utils/Button'
import { SWALMODERROR } from '../../../../../../utility/constants'
const alerta_img = require('assets/img/icons/warning.svg');
const img1 = require('assets/img/work_orders/tapa_de_motor.png');
const img2 = require('assets/img/work_orders/guarda_barros_izquierdo.png');
const img3 = require('assets/img/work_orders/guarda_barros_derecho.png');
const img4 = require('assets/img/work_orders/parachoques_delantero.png');
const img5 = require('assets/img/work_orders/puerta_delantera_izquierda.png');
const img6 = require('assets/img/work_orders/puerta_delantera_derecha.png');
const img7 = require('assets/img/work_orders/puerta_trasera_derecha.png');
const img8 = require('assets/img/work_orders/puerta_trasera_izquierda.png');
const img9 = require('assets/img/work_orders/tapa_de_baul.png');
const img10 = require('assets/img/work_orders/parachoques_trasero.png');
const img11 = require('assets/img/work_orders/desmontar_faroles_delanteros.png');
const img12 = require('assets/img/work_orders/desmontar_faroles_traseros.png');
const img13 = require('assets/img/work_orders/vidrios.png');
const img14 = require('assets/img/work_orders/aros.png');
const img15 = require('assets/img/work_orders/estribos.png');
const img16 = require('assets/img/work_orders/sunroof.png');
const img17 = require('assets/img/work_orders/retrovisores.png');

var datos = [{
    imagen: 'assets/db278b945d66c37d8e2fc2d3d0f5388c.png',
    name: "Tapa de motor",
    part_identifier: 'part_number_1',
}, {
    imagen: 'assets/315a4ebfed9a2da38337e6733d7834a0.png',
    name: "Guarda barros izquierdo",
    part_identifier: 'part_number_2',
}, {
    imagen: 'assets/6a98fdfd4267978a9ad4b1f2c7747640.png',
    name: "Guarda barros derecho ",
    part_identifier: 'part_number_3',
}, {
    imagen: 'assets/8e444270e2a399c527c09be0dab217d3.png',
    name: "Parachoques delantero",
    part_identifier: 'part_number_4',
}, {
    imagen: 'assets/2453eca5cb8b9d2119537a48918b8bd9.png',
    name: "Puerta delantera izquierda",
    part_identifier: 'part_number_5',
}, {
    imagen: 'assets/8d14ddc390cc946cceddb9eaaa5f0ed9.png',
    name: "Puerta delantera derecha",
    part_identifier: 'part_number_6',
}, {
    imagen: 'assets/4600c1233196716d7a0d7ad2cce15f34.png',
    name: "Puerta trasera derecha",
    part_identifier: 'part_number_7',
}, {
    imagen: 'assets/974d1c36f5d34ecb32b54234e21bf20d.png',
    name: "Puerta trasera izquierda",
    part_identifier: 'part_number_8',
}, {
    imagen: 'assets/68790e47973364701f0e393757056c50.png',
    name: "Tapa de baul",
    part_identifier: 'part_number_9',
}, {
    imagen: 'assets/f5dd617461be2f07b25a14d99ef07685.png',
    name: "Parachoques trasero",
    part_identifier: 'part_number_10',
}, {
    imagen: 'assets/739076b46d331ceddfc8d6cebf0d0687.png',
    name: "Desmontar faroles delanteros",
    part_identifier: 'part_number_11',
}, {
    imagen: 'assets/01a458f949e316c5a04ca25b3029bead.png',
    name: "Desmontar faroles traseros",
    part_identifier: 'part_number_12',
}, {
    imagen: 'assets/963b5493004d1b6b231e6832a535f362.png',
    name: "Vidrios",
    part_identifier: 'part_number_13',
}, {
    imagen: 'assets/29ce0316f5da252c1629f6b19ad341ae.png',
    name: "Aros",
    part_identifier: 'part_number_14',
}, {
    imagen: 'assets/fd2c24fb91c60816f719189a9f61cef7.png',
    name: "Estribos",
    part_identifier: 'part_number_15',
}, {
    imagen: 'assets/5ad49ccf15a4c658971bdce3f0b0807d.png',
    name: "Sunroof",
    part_identifier: 'part_number_16',
}, {
    imagen: 'assets/f5dabe492c17dc9d7c63b6f25d8c6130.png',
    name: "Retrovisores",
    part_identifier: 'part_number_17',
},];


const SelectCard = (props) => {
    let {
        imagen,
        name,
        selected,
        onClick
    } = props;
    let isSelected = selected ? "selected" : "";
    let className = "selectable " + isSelected;
    return (
        <div className={`card card-flujo text-center ${className}`} onClick={onClick}>
            <div className="card-body">
                <img className="card-img-top mx-auto" src={imagen}></img>
                <p className="text-center description">{name}</p>
            </div>
        </div>
    );
}


const cardRender = ({ input, meta: { submitFailed, error, dispatch }, data, partSelect }) => {
    useEffect(() => {
        if (input.value) {
            dispatch(changeForm('SelectFlujosForm', `parts_active.${data.part_identifier}`, input.value))
        }
    }, [input.value])
    const active = partSelect[data.part_identifier]
    return (
        <SelectCard
            imagen={data.imagen}
            name={data.name}
            selected={active}
            onClick={() => {
                if (!active) {
                    if (input.value) {
                        dispatch(changeForm('SelectFlujosForm', `parts_active.${data.part_identifier}`, input.value))
                    } else {
                        dispatch(changeForm('SelectFlujosForm', `parts_active.${data.part_identifier}`, { part_identifier: input.name, name: data.name, part: data.imagen }))
                    }
                } else {
                    if (input.value && input.value.lock) {
                        SWALMODERROR.fire({
                            title: '¡Error!',
                            text: '¡Esta tarea ya fue iniciada!',
                            confirmButtonText: 'Regresar',
                            reverseButtons: true,
                            imageUrl: alerta_img,
                            imageAlt: 'Alerta',
                            imageWidth: 90,
                        })
                    } else {
                        dispatch(changeForm('SelectFlujosForm', `parts_active.${data.part_identifier}`, null))
                    }
                }
            }}
        />
    )
}


const partRender = ({ input, setPartSelect }) => {
    useEffect(() => {
        setPartSelect(input.value)
    }, [input.value])
    return ""
}
const SelectFlujosForm = (props) => {
    const [partSelect, setPartSelect] = useState([])
    const { handleSubmit, listarPartesActivas, work_order_data_id, search_flow } = props;

    useEffect(() => {
        listarPartesActivas(work_order_data_id, search_flow);
    }, [])

    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">
                <div className="d-flex flex-wrap justify-content-sm-around justify-content-md-around justify-content-lg-center">
                    {datos.map((item, index) => (
                        <Field key={item.part_identifier} name={item.part_identifier} component={cardRender} data={item} partSelect={partSelect} />
                    ))}
                </div>
            </div>
            <Field name="parts_active" component={partRender} setPartSelect={setPartSelect} />
            <ButtonSalirGuardar link="/mywork-orders" btnText2="Siguiente" />
        </form>
    );
};

export default reduxForm({
    form: 'SelectFlujosForm',
})(SelectFlujosForm);
