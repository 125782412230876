import React, { Fragment, useState } from 'react';
import { Field, reduxForm, FieldArray, change as changeForm } from 'redux-form';

import {
    renderField,
    AsyncSelectField2,
    SelectField2,
    renderNumber,
} from '../../../Utils/renderField/renderField';

import { ButtonMecanicaGuardar } from '../../../Utils/Button';
const svgDelete = require('assets/img/icons_table/delete.svg');
import { api } from 'api';
import { validate } from 'validate-redux-form';
import { validationDiagnosticMecInfo } from '../../../../../utility/validationForm';
import { SWALMODERROR } from '../../../../../utility/constants';
import ImagenesDiagnosticoForm from '../Common/ImagenesDiagnosticoForm';
const alerta_img = require('assets/img/icons/warning.svg');

const getSystem = search => {
    return api
        .get('car_system', { search })
        .then(data => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};
const required = value => (value ? undefined : 'Campo requerido.');

const getOperador = (search) => {
    return api.get("garage/my_users/", { search, 'type': 'mechanic' }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const errorSwal = text => {
    SWALMODERROR.fire({
        title: '¡Información!',
        text: text,
        confirmButtonText: 'Aceptar',
        reverseButtons: true,
        imageUrl: alerta_img,
        imageAlt: 'Alerta',
        imageWidth: 90,
    });
};

const renderPartes = ({ fields, meta: { error, submitFailed }, dispatch, data_item, isQA, handleSubmit, props, status, }) => {
    const [components, setComponents] = useState({});
    const [diagnosisPhotosOpen, setDiagnosisPhotosOpen] = useState(false)
    const { tasks = [] } = data_item;
    return (
        <div className="col-12 form-group m-auto px-0 px-sm-0 pt-2">
            
            <div className={`mx-auto w-100 my-5 ${diagnosisPhotosOpen ? 'd-block' : 'd-none'}`}>
                <ImagenesDiagnosticoForm 
                    dispatch={dispatch}
                    form="DiagnosticoMecanicaForm"
                    handleOpen={() => {
                        setDiagnosisPhotosOpen(false)
                        console.log(diagnosisPhotosOpen);
                    }}
                />
                <button className='btn d-block btn-success bg-primary mx-auto' type='button' onClick={() => {
                    setDiagnosisPhotosOpen(!diagnosisPhotosOpen)
                }}>Cerrar</button>
                </div>

            <div className="my-n2 d-none d-md-block">
                <div
                    className="react-bs-container-header"
                    style={{ height: 25 }}
                >
                    <div className="d-none d-md-flex row py-1 px-0 px-sm-1 px-lg-1 px-xl-1">
                        <div
                            className="mb-2 d-none d-md-flex justify-content-center align-items-center"
                            style={{ width: 45 }}
                        ></div>
                        <div className="flex-1 mb-2 px-1">
                            <label
                                htmlFor="mobil_number"
                                className="blanco d-block"
                            >
                                SISTEMA*
                            </label>
                        </div>
                        <div className="flex-1 mb-2 px-1">
                            <label
                                htmlFor="mobil_number"
                                className="blanco d-block"
                            >
                                COMPONENTE*
                            </label>
                        </div>
                        <div className="flex-1  mb-2 px-1">
                            <label htmlFor="name" className="ml-2 blanco d-block">NÚMERO DE PARTE</label>
                        </div>
                        <div className="flex-1  mb-2 px-1">
                            <label htmlFor="name" className="ml-2 blanco d-block">DESCRIPCIÓN</label>
                        </div>
                        
                        <div className="flex-1 mb-2 px-1">
                            <label htmlFor="mobil_number" className="blanco d-block">
                                {isQA !== true ? 'CANTIDAD*' : 'MECÁNICO/OPERADOR*'}
                            </label>
                        </div>

                        <div className="flex-1  mb-2 px-1">
                            <label htmlFor="name" className="ml-2 blanco d-block">Imágenes diagnóstico</label>
                        </div>
                    </div>
                </div>
            </div>

            

            {fields.map((item, index) => {
                return (
                    <div
                        className="d-flex py-3 px-0 px-sm-1 flex-md-row flex-column"
                        key={index}
                    >
                        <div className="order-1 order-md-0 px-1 d-inline d-md-flex">
                            <img
                                src={svgDelete}
                                alt="Eliminar"
                                className="icon_svg pointer my-auto row mx-auto"
                                onClick={() => {
                                    fields.remove(index);
                                }}
                            />
                            <span
                                className="text-18 bold d-block  d-md-none d-none mx-2 mt-2"
                                style={{
                                    borderBottom: '2px #7EA04D solid',
                                    paddingBottom: 5,
                                }}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label
                                htmlFor="car_system"
                                className="gris-oscuro d-block d-md-none"
                            >
                                SISTEMA*
                            </label>
                            <Field
                                name={`${item}.car_system`}
                                component={AsyncSelectField2}
                                loadOptions={getSystem}
                                placeholder="Sistema"
                                onCambio={e => {
                                    setComponents({
                                        ...components,
                                        [index]: e.system_components,
                                    });
                                    dispatch(
                                        changeForm(
                                            'DiagnosticoMecanicaForm',
                                            `tasks[${index}].system_component`,
                                            null
                                        )
                                    );
                                }}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label
                                htmlFor="system_component"
                                className="gris-oscuro d-block d-md-none"
                            >
                                COMPONENTE*
                            </label>
                            <Field
                                name={`${item}.system_component`}
                                options={
                                    components[index]
                                        ? components[index]
                                        : tasks[index]
                                        ? tasks[index].car_system
                                            ? tasks[index].car_system
                                                  .system_components
                                                ? tasks[index].car_system
                                                      .system_components
                                                : []
                                            : []
                                        : []
                                }
                                component={SelectField2}
                                placeholder="Componente"
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label
                                htmlFor="name"
                                className="gris-oscuro d-block d-md-none"
                            >
                                NÚMERO DE PARTE
                            </label>
                            <Field
                                name={`${item}.name`}
                                type="text"
                                component={renderField}
                            />
                        </div>
                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label
                                htmlFor="description"
                                className="gris-oscuro d-block d-md-none"
                            >
                                DESCRIPCIÓN
                            </label>
                            <Field
                                name={`${item}.description`}
                                type="textarea"
                                component={renderField}
                            />
                        </div>
                       

                        {isQA !== true ?
                        (<div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="quantity" className="gris-oscuro d-block d-md-none">CANTIDAD*</label>
                            <Field
                                name={`${item}.quotation.quantity`}
                                isNumericString={true}
                                component={renderNumber}
                                type="text"
                                className="form-control"
                                validate={[required]}
                            />
                        </div>) :
                        (<div className="flex-1 px-1 pb-2 pb-md-0">
                            <label htmlFor="quantity" className="gris-oscuro d-block d-md-none">MECÁNICO/OPERADOR*</label>
                            <Field
                                name={`${item}.user`}
                                component={AsyncSelectField2}
                                loadOptions={getOperador}
                                placeholder="Mecánico/Operador"
                            />
                        </div>)
                        }

                        <div className="flex-1 px-1 pb-2 pb-md-0">
                            <label
                                htmlFor="diagnosis-photos"
                                className="gris-oscuro d-block d-md-none"
                            >
                                Imágenes diagnóstico
                            </label>
                            <button class="btn btn-primary" type="button" onClick={() => {
                                console.log(diagnosisPhotosOpen);
                                setDiagnosisPhotosOpen(!diagnosisPhotosOpen)
                               
                                
                            }} >
                                agregar
                            </button>

                            
                        </div>
                    </div>
                );
            })}
            <div className="d-flex justify-content-center mt-3">
                <button
                    type="button"
                    className="btn btn-secondary py-1"
                    onClick={() => {
                        if (status == 20) {
                            fields.push({});
                        } else {
                            errorSwal('No has comenzado a contar el tiempo, por favor seleccionalo.');
                        }
                    }}
                >
                    AGREGAR
                </button>
            </div>
            <div className='w-100'>
            </div>
        </div>
    );
};

const DiagnosticoMecanicaForm = (props) => {
    const { handleSubmit, dispatch, item, isQA, data_task } = props;

    const status = item.order_mechanic && isQA !== true ? 
                    item.order_mechanic.task_diagnostic.status : 
                    data_task ? data_task.status : null; 

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <div className="row card-form justify-content-center align-items-center">
                    {isQA !== true &&
                    <div className="card padding-custom mb-3">
                        <div style={{ padding: 20 }}>
                            <h3 className="card-title">
                                INFORMACIÓN DEL VEHÍCULO
                            </h3>
                            <div className="card-body">
                                <div className="row">
                                    <div style={{ fontWeight: 'bold' }}>
                                        VIN:&nbsp;
                                    </div>
                                    {item.car ? item.car.vin : '---'}
                                </div>
                                <div className="row">
                                    <div style={{ fontWeight: 'bold' }}>
                                        MATRÍCULA:&nbsp;
                                    </div>
                                    {item.car
                                        ? item.car.car_registration
                                        : '---'}
                                </div>
                                <div className="row">
                                    <div style={{ fontWeight: 'bold' }}>
                                        MARCA:&nbsp;
                                    </div>
                                    {item.car ? item.car.brand.name : '---'}
                                </div>
                                <div className="row">
                                    <h5 className="text-18 bold">
                                        DESCRIPCIÓN:&nbsp;
                                    </h5>

                                    <p className="text-16">
                                        {item &&
                                        item.order_mechanic &&
                                        item.order_mechanic.description
                                            ? item.order_mechanic.description
                                            : ''}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="col-12 padding-custom mb-0 mb-md-4 mt-0">
                        <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>
                            {isQA !== true ? 'DIAGNOSTICO' : 'TAREAS ADICIONALES'}
                        </span>
                    </div>

                    

                    <div className="col-12 padding-custom mb-3">
                        <FieldArray 
                            name="tasks" 
                            component={renderPartes} 
                            dispatch={dispatch} 
                            data_item={item} 
                            handleSubmit={handleSubmit}
                            isQA={isQA}
                            props={props}
                            status={status}
                        />
                    </div>
                </div>
                {isQA !== true ?
                (<ButtonMecanicaGuardar link="/mywork-orders" link2={handleSubmit(values => {
                    if(status == 20) {
                        errorSwal('Debes detener el contador de tiempo para realizar esta acción.');
                    } else {
                        props.onSubmit(values, { crear: false, isCrear: true })
                    }
                })} btnText2="Guardar" btnText1="Salir" />) :
                (<div className="card-form flex-column flex-sm-row d-flex">
                    <div className="flex-1 text-right padding-custom">
                    </div>
                    <div className="flex-1 text-center padding-custom mt-sm-0 mt-3">
                        <button type="button" onClick={handleSubmit(values =>
                        props.onSubmit(values, { "crear": false, "isQA": true }))} className="btn btn-primary py-1">GUARDAR</button>
                    </div>
                    <div className="flex-1 padding-custom mt-sm-0 mt-3">
                    </div>
                </div >)
                }
            </form>
        </React.Fragment>
    );
};

export default reduxForm({
    form: 'DiagnosticoMecanicaForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: (data, props) => {
        let errors = {};
        let DetalleArrayErrors = [];
        if (data && data.tasks && data.tasks.length) {
            data.tasks.forEach((detalle, detalleIndex) => {
                let other = {}
                if(props.isQA) {
                    other.user = validationDiagnosticMecInfo.user.Combine
                }
                DetalleArrayErrors[detalleIndex] = validate(detalle, {
                    /*name: validationDiagnosticMecInfo.name.Combine,
                    description: validationDiagnosticMecInfo.description.Combine,*/
                    car_system: validationDiagnosticMecInfo.car_system.Combine,
                    system_component: validationDiagnosticMecInfo.system_component.Combine,
                    ...other,
                })
            });
        }
        errors.tasks = DetalleArrayErrors;
        return {
            ...errors,
            ...validate(data, {}),
        };
    },
})(DiagnosticoMecanicaForm);
