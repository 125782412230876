import React, {Component, Fragment} from "react";
import Grid from "../../../Utils/Grid";
import Card from "../../../Utils/Cards/Card";
import {TableHeaderColumn} from "react-bootstrap-table";
import classNames from "classnames";


class List extends Component {
    state = {
        BreakLineCenter: {
            whiteSpace: 'normal',
            textAlign: 'center'
        },
        BreakLine: {
            whiteSpace: 'normal'
        },
        AlignCenter: {
            textAlign: 'center'
        }
    }

    componentWillMount() {
        const {initializeWorkOrderId, id_colaborador, setIdColaborador} = this.props;
        if (id_colaborador === 0) {
            setIdColaborador(this.props.match.params.idCollaborator)
        }
        initializeWorkOrderId(this.props.match.params.id);
    }

    changePage = (page) => {
        this.props.listarTareas(page);
    }

    render() {
        const {dataTasks, loader, page2} = this.props;

        return (
            <div>
                <Card
                    titulo="Tareas"
                    subTitulo={dataTasks.name ? dataTasks.name : ""}
                    header={
                        <div className="col-12 d-flex justify-content-end mt-0 mb-1">
                            <h3>Orden: {dataTasks.order_number}</h3>
                        </div>
                    }
                >
                    <br/>
                    <Grid
                        hover
                        striped
                        page={page2}
                        data={dataTasks}
                        loading={loader}
                        onPageChange={this.changePage}
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            width="100"
                            dataFormat={(cell, row) => {
                                return null
                            }}
                        >
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="replacement"
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="description"
                            dataFormat={(cell, row) => {
                                return <span className={row.was_rejected? 'text-danger': ''}> {cell}</span>
                            }}
                        >
                            DESCRIPCION
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="time"
                            dataFormat={(cell, row) => {
                                return <span className={row.was_rejected? 'text-danger': ''}> {cell}</span>
                            }}
                        >
                            TIEMPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="was_rejected"
                            dataFormat={viewChecked}
                            thStyle={this.state.AlignCenter}
                            tdStyle={this.state.BreakLineCenter}
                        >
                            FUE RECHAZADO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}


function viewChecked(cell, row) {
    return (
        <div className="checkbox c-checkbox mx-auto text-center">
            <label className="needsclick my-0 ">
                <input
                    type="checkbox"
                    disabled={false}
                    checked={cell}
                    className={""}
                    onChange={() => {

                    }}
                />
                <span className="fa fa-check"/>
            </label>
        </div>
    )
}

export default List;
