import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import './styleGrid.css';

const play_activo = require("assets/img/work_orders/icons/play_activo.png");
const play_inactivo = require("assets/img/work_orders/icons/play_inactivo.png");
const pause_activo = require("assets/img/work_orders/icons/pausar_activo.png");
const pause_inactivo = require("assets/img/work_orders/icons/pausar_inactivo.png");
const finish_activo = require("assets/img/work_orders/icons/check_activo.png");
const finish_inactivo = require("assets/img/work_orders/icons/check_inactivo.png");

import { connect } from 'react-redux';
const warningSvg = require('assets/img/icons/warning.svg');
import { SWALMOD } from '../../../../utility/constants'
class Acciones2 extends Component {
    constructor(props) {
        super(props);
    }

    fun_finalizar = (id, acction) => {
        return () => {
            SWALMOD.fire({
                title: '¿Finalizar tarea?',
                text: '¡No podrás revertir esta acción!',
                showCancelButton: true,
                confirmButtonText: '¡Sí, finalizar!',
                cancelButtonText: 'No, finalizar',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then((result) => {
                if (result.value) {
                    this.props.onChange(id, acction);
                }
            });
        };
    };

    render() {

        const { id, play = true, finalized, onChange, no_ver_finalized, no_ver_play_pause } = this.props;
        const pause = !play

        return (
            <div className="d-flex justify-content-center">
                {!no_ver_finalized && finalized !== undefined && (
                    <div onClick={onChange && this.fun_finalizar(id, 'finalized')} type="button" data-toggle="tooltip" data-placement="left" title="Finalizar" className="mx-2">
                        <img className="icon_svg" src={finish_inactivo} style={{ cursor: "pointer" }} />
                    </div>
                )}
                {!no_ver_play_pause &&
                    <Fragment>
                        {play !== undefined && (
                            <div onClick={() => {
                                onChange && play == false && onChange(id, 'play')
                            }} type="button" data-toggle="tooltip" data-placement="left" title="Iniciar" className="mx-2">
                                <img className="icon_svg" src={play ? play_activo : play_inactivo} style={{ cursor: play ? "not-allowed" : "pointer" }} />
                            </div>
                        )}
                        {pause !== undefined && (
                            <div onClick={() => {
                                onChange && pause == false && onChange(id, 'pause')
                            }} type="button" data-toggle="tooltip" data-placement="left" title="Pausar" className="mx-2">
                                <img className="icon_svg" src={pause ? pause_activo : pause_inactivo} style={{ cursor: pause ? "not-allowed" : "pointer" }} />
                            </div>
                        )}
                    </Fragment>
                }
            </div>
        );
    }
}

const ms2p = (state) => {
    return {
        me: state.login.me,
    };
};
const md2p = {};
const Acciones = connect(ms2p, md2p)(Acciones2);

export function StandardActionsTracking(acciones) {
    if (acciones.finalized)
        return () => <span className="text-13 verde bold my-auto">Finalizado</span>
    return (cell, row) => {
        return <Acciones id={cell} {...acciones} />;
    };
}

