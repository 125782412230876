import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate,
} from 'validate-redux-form';

import {
    renderField,
    renderNumber,
    renderPasswordField,
    AsyncSelectField2,
    renderCurrency,
} from '../../../Utils/renderField/renderField';

import { Link } from "react-router-dom";

import { validationAdministrador } from '../../../../../utility/validationForm'
import { ButtonSalirGuardar } from '../../../Utils/Button'
import { ROLE_MANAGEMENT_GARAGE } from '../../../../../utility/constants';

import { api } from "api";
const getRol = (search) => {
    return api.get("role_garage", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


const UsuarioForm = (props) => {
    const { handleSubmit, actualizar, me } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="first_name">Nombre*</label>
                    <Field name="first_name" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.first_name.MaxLength} />
                </div>

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="last_name">Apellido*</label>
                    <Field name="last_name" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.last_name.MaxLength} />
                </div>

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="username">Usuario*</label>
                    <Field name="username" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.username.MaxLength} />
                </div>

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="phone_number">Teléfono*</label>
                    <Field name="phone_number" component={renderNumber} type="text" className="form-control" isNumericString={true} maxLength={validationAdministrador.phone_number.MaxLength} />
                </div>

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="email">Email*</label>
                    <Field name="email" component={renderField} type="email" className="form-control" maxLength={validationAdministrador.email.MaxLength} />
                </div>

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="role">Rol*</label>
                    {
                        actualizar ?
                            <Field
                                disabled={me.permissions.includes(ROLE_MANAGEMENT_GARAGE) ? false : true}
                                valueKey="id"
                                name="role"
                                labelKey="name"
                                label="name"
                                component={AsyncSelectField2}
                                loadOptions={getRol}
                            />
                            :
                            <Field
                                valueKey="id"
                                name="role"
                                labelKey="name"
                                label="name"
                                component={AsyncSelectField2}
                                loadOptions={getRol}
                            />
                    }
                </div>

                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="salary">Salario por hora</label>
                    <Field name="salary" component={renderCurrency} className="form-control" />
                </div>
                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="sales_goal">Meta de ventas mensual</label>
                    <Field name="sales_goal" component={renderCurrency} className="form-control" />
                </div>
                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="average_monthly_goal_hours">Horas promedio mensual de meta</label>
                    <Field name="average_monthly_goal_hours" isNumericString={true} component={renderNumber} type="text" className="form-control" />
                </div>

                {actualizar ?
                    <div className="col-md-6 padding-custom d-flex">
                        <div className="col-md-12 col-sm-12 align-self-center px-0">
                            <Field name="new_password" placeholder="Nueva contraseña" component={renderPasswordField} />
                        </div>
                    </div>
                    :
                    <p className="col-12 padding-custom mb-3 mt-3 text-14">- Al momento de guardar se le enviara la contraseña temporal al nuevo usuario.</p>
                }
                <div className="col-12 padding-custom mb-3">
                    <p className="text-right azul text-12 bold">* Campos requeridos</p>
                </div>
            </div>
            <ButtonSalirGuardar link="/users" />
        </form >
    );
};

export default reduxForm({
    form: 'UsuarioForm',
    validate: data => {
        let errors = {};
        if (data && data.new_password && data.new_password.length < 6) {
            errors.new_password = "Mínimo 6 carácteres"
        }
        if (data && data.new_password && data.new_password.length > 150) {
            errors.new_password = "Máximo 150 carácteres"
        }
        return {
            ...errors, ...validate(data, {
                first_name: validationAdministrador.first_name.Combine,
                last_name: validationAdministrador.last_name.Combine,
                username: validationAdministrador.username.Combine,
                phone_number: validationAdministrador.phone_number.Combine,
                email: validationAdministrador.email.Combine,
                role: validationAdministrador.role.Combine,
            })
        }
    }

})(UsuarioForm);


