import React from "react";
import Modal from "react-modal";
import "./FullModal.css";
import { ButtonSalirGuardar } from '../Button'

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderRadius: 25,
    },
};
Modal.setAppElement("#app-container");

const FullModal = (props) => {
    // const closeModal = () => {
    //     props.showModal(false);
    // };
    return (
        <Modal
            isOpen={props.show_modal}
            // onRequestClose={closeModal}
            style={customStyles}
            overlayClassName="modal-fondo"
        >
            {props.children}
        </Modal>
    );
};

export default FullModal;
