import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/cotizaciones';
import EnProgreso from './EnProgreso';


const ms2p = (state) => {
    return {
        ...state.cotizaciones,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(EnProgreso);
