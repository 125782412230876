import React, { Component } from 'react'
import Card from '../../../../Utils/Cards/Card'
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Listar from './Listar'
import { STATUS_ORDER_APPRAISAL } from '../../../../../../utility/constants'
import { HeaderSearch } from '../../../../Utils/Header'

export default class TabsListar extends Component {
    render() {
        const {
            NOT_ASSIGNED,
            IN_PROGGRES,
            TO_CHECK_IN,
            FINALIZED,
        } = STATUS_ORDER_APPRAISAL
        return (
            <Card
                style={{overflow: "visible"}}
                titulo="ORDENES DE AVALÚO"
                subTitulo=""
                header={
                    <HeaderSearch
                        {...this.props}
                    />
                }
            >
                <Tabs
                    defaultActiveKey={String(NOT_ASSIGNED)}
                    tabBarPosition="top"
                    onChange={(tab) => this.props.estadoAvaluoTab(tab)}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                    activeKey={String(this.props.estado_avaluo_tab)}
                    style={{overflow: "visible"}}
                >
                    <TabPane
                        tab="NO ASIGNADAS"
                        key={NOT_ASSIGNED}
                    >
                        <Listar {...this.props} />
                    </TabPane>
                    <TabPane
                        tab="EN PROCESOS"
                        key={IN_PROGGRES}
                    >
                        <Listar {...this.props} />
                    </TabPane>
                    <TabPane
                        tab="POR FACTURAR"
                        key={TO_CHECK_IN}
                    >
                        <Listar {...this.props} facturar={["/appraisal-order/", "/order_closing"]} total_amount />
                    </TabPane>
                    <TabPane
                        tab="FINALIZADO"
                        key={FINALIZED}
                    >
                        <Listar {...this.props} ver_factura total_amount />
                    </TabPane>
                </Tabs>
            </Card>
        )
    }
}
