import React, { useState, useEffect } from 'react';
import Select, { Creatable, Async } from 'react-select';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader/FileUploader';
import DatePicker from 'react-date-picker';
import _, { values } from 'lodash';
import { Collapse } from 'antd';
const { Panel } = Collapse;
import moment from 'moment';
import UploadDriver from '../../Utils/UploadDriver'
import { change as changeForm, arrayPush as arrayPushForm } from 'redux-form';
import { connect } from 'react-redux';
import FileUploaderExcel from "../FileUploader/FileUploaderExcel";

export const renderField = ({
    input,
    placeholder,
    type,
    maxLength,
    meta: { touched, error },
    disabled,
}) => {
    const invalid = touched && error;
    if(type === "textarea"){
        return (
            <div>
                <textarea
                    {...input}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    className={classNames('form-control', {
                        'is-invalid': invalid,
                    })}
                    disabled={disabled}
                />
                {invalid && <div className="invalid-feedback">{error}</div>}
            </div>
        )
    } else {
        return (
            <div>
                <input
                    {...input}
                    placeholder={placeholder}
                    type={type}
                    maxLength={maxLength}
                    className={classNames('form-control', {
                        'is-invalid': invalid,
                    })}
                    disabled={disabled}
                />
                {invalid && <div className="invalid-feedback">{error}</div>}
            </div>
        );
    }
};

export const renderTextArea = ({
    input,
    placeholder,
    rows,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                {...input}
                placeholder={placeholder}
                style={{ resize: 'none' }}
                rows={rows || 3}
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderNumber = ({
    input,
    decimalScale,
    placeholder,
    meta: { touched, error },
    prefix = '',
    suffix = '',
    numberFormat,
    isNumericString,
    maxLength,
    type,
    onCambio,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
                maxLength={maxLength}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                isNumericString={isNumericString || false}
                prefix={prefix}
                suffix={suffix}
                type={type}
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (onCambio) onCambio(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderPasswordField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const [watch, setWatch] = useState(false);
    return (
        <div className="negro collapse-contrasena mb-3 mt-1 mb-md-0">
            <Collapse defaultActiveKey={['0']} >
                <Panel header=" Cambiar contraseña" key="1">
                    <div className="position-relative">
                        <input
                            maxLength="151"
                            style={{ backgroundImage: "none" }}
                            {...input}
                            placeholder={placeholder}
                            type={`${watch ? "text" : "password"}`}
                            className={classNames('form-control', {
                                'is-invalid': invalid,
                            })}
                        />
                        <button type="button" className="btn-password" onClick={() => { setWatch(!watch) }}>
                            <i className={`material-icons ${invalid ? "rojo" : watch ? "verde" : "negro"}`}>remove_red_eye</i>
                        </button>
                    </div>
                </Panel>
            </Collapse>
            {invalid && <div className="invalid-feedback d-inline">{error}</div>}
        </div>
    );
};

const GetRenderCurrency = ({
    onChange,
    input,
    meta: { touched, error },
    placeholder,
    disabled,
    setTotal,
    total,
    totalTotal,
    me
}) => {
    const invalid = touched && error;
    const { currency_symbol = "$" } = me || {}
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
                onChange={onChange}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix={currency_symbol + " "}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
                onFocus={() => {
                    if (total === 0) {
                        let valActual = Number(input.value);
                        setTotal(totalTotal - valActual);
                    }else{
                        let valActual = Number(input.value)
                        setTotal( total - valActual );
                    }
                    }
                }
                onBlur={() => {
                    let valActual = Number(input.value)
                    setTotal( total + valActual );
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};
const props = state => ({ me: state.login.me, })
export const renderCurrency = connect(props)(GetRenderCurrency);


export const renderSwitch = ({
    input,
    meta: { touched, error },
    label,
    disabled,
}) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            <Switch
                onColor="#7EA04D"
                height={18}
                width={36}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
            // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderFieldCheck = ({ input, to_block, label, value, disabled, onCambio, onCambio2, onCambio3, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    useEffect(() => {
        if (input) {
            onCambio && onCambio(!!input.value)
            onCambio2 && onCambio2(input.value)
        }
    }, [input.value])
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick my-0">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        onChange={(value) => {
                            if (!to_block)
                                input.onChange(value);
                            onCambio3 && onCambio3(!input.value)
                        }}
                        checked={input.value}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderFieldRadio = ({
    input,
    label,
    value,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const SelectField = ({
    input,
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    options,
    placeholder = "Seleccionar...",
    labelKey = 'name',
    valueKey = 'id',
    meta: { touched, error },
    defaultValue,
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }
    useEffect(() => {
        if (!value) {
            if (defaultValue)
                input.onChange(defaultValue);
        }
    }, [])
    if (!value) {
        value = null
    }
    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container select', {
                    'is-invalid': invalid,
                })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e[valueKey] : null);
                }}
                value={value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderFilePicker2 = ({
    photo,
    setFile,
    className,
    disabled,
    input,
    accept,
    meta: { touched, error },
    excel,
}) => {
    const invalid = touched && error;

    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <FileUploaderExcel
                excel={excel}
                disabled={disabled}
                accept={accept}
                img={null}
                {...input}
                borderRed={!!(invalid)}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }}
            />
            {!!(invalid) && <div className="invalid-feedback d-block mb-0">{error}</div>}
        </div>
    );
};

export const SelectField2 = ({
    input,
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    options,
    onCambio,
    placeholder = "Seleccionar...",
    labelKey = 'name',
    valueKey = 'id',
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });
    let value = input.value;
    if (value != null && value != undefined && value) {
        input.value = {
            ...value,
            label: value[labelKey],
            value: value[valueKey],
        }
    }
    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container select', {
                    'is-invalid': invalid,
                })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                    onCambio && onCambio(e?e:[])
                }}
                isDisabled={disabled}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const AsyncSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    loadOptions,
    placeholder,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', {
                    'is-invalid': invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};


export const AsyncSelectField2 = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder = "Seleccionar...",
        onCambio,
        valueKey = "id",
        labelKey = "name",
        defaultOptions,
        meta: { touched, error },
    }) => {

    const invalid = touched && error;
    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container select', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder}

                onChange={(e) => {
                    input.onChange(e ? e : null);
                    if (onCambio) onCambio(e);
                }}
                value={input.value}
                isDisabled={disabled}
                getOptionValue={(option) => (option[valueKey])}
                getOptionLabel={(option) => (option[labelKey])}
                defaultOptions={defaultOptions ? defaultOptions : true}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const CreatableSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    options,
    placeholder,
    labelKey = 'label',
    valueKey = 'value',
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', {
                    'is-invalid': invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({
    photo,
    setFile,
    className,
    disabled,
    input,
    meta: { touched, error },
    onlyImagen = true,
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploader
                onlyImagen={onlyImagen}
                disabled={disabled}
                img={!!photo ? photo : "x"}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }}
            />
            {invalid && <div className="d-inline-flex invalid-feedback">{error}</div>}
        </div>
    );
};


export const renderUploadDriver = ({
    onCambio,
    nameForm,
    className,
    input,
    maxFiles = false,
    imageOnly = false,
    meta: { submitFailed, error, dispatch },
}) => {
    const invalid = submitFailed;
    useEffect(() => {
        if (input && input.value && Array.isArray(input.value)) {
            onCambio && onCambio(input.value)
        } else {
            onCambio && onCambio([])
        }
    }, [input.value])
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <UploadDriver
                imageOnly={imageOnly}
                maxFiles={maxFiles}
                value={input.value}
                onChange={(files) => {
                    for (let index = 0; index < files.length; index++) {
                        const file = files[index];
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            let objetoArchivo = { src: reader.result, file: file, name: file.name }
                            dispatch(arrayPushForm(nameForm, input.name, objetoArchivo))
                        };
                        reader.readAsDataURL(file);
                    }
                }}
            />
            {invalid && <div className="d-inline-flex invalid-feedback">{error}</div>}
        </div>
    );
};


export const renderDayPicker = ({
    className,
    disabled,
    maxDate,
    minDate,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(e) => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderDatePicker = ({
    className,
    disabled,
    maxDate,
    minDate,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const new_value = new Date(moment(input.value))
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                onChange={(e) => input.onChange(e ? moment(e).format('YYYY-MM-DD') : e)}
                disabled={disabled}
                format="dd/MM/y"
                maxDate={maxDate}
                minDate={minDate}
                value={input.value ? new_value : null}

            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
    renderFilePicker2,
};
