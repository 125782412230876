import React, { Component, Fragment } from 'react'
import Grid from "../../../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../../Utils/Grid/StandardActions";
import { ButtonSalirGuardar } from '../../../../../Utils/Button'
const warningSvg = require('assets/img/icons/warning.svg');
import { SWALMOD } from '../../../../../../../utility/constants'

const car_wash = require("assets/img/car_wash.png")  // esta es la imagen man

export default class Listar extends Component {

    componentWillMount() {
        const { listarQaOrCarWash, work_order_data_id, search_flow } = this.props;
        listarQaOrCarWash(work_order_data_id, search_flow);
    }

    finalizarFlujo() {
        SWALMOD.fire({
            title: '¿Finalizar el flujo?',
            text: "",
            showCancelButton: true,
            confirmButtonText: '¡Sí, finalizar!',
            cancelButtonText: 'No, finalizar',
            reverseButtons: true,
            imageUrl: warningSvg,
            imageAlt: 'Alerta',
            imageWidth: 90,
        }).then((result) => {
            if (result.value) {
                const { finalizarFlujo, work_order_data_id, search_flow } = this.props;
                finalizarFlujo(work_order_data_id, search_flow)
            }
        });
    }

    render() {
        return (

            <Fragment>
                <div className="col-12 padding-custom mb-0 mb-md-4 mt-4">
                    <img src={car_wash} className="img-fluid" />
                </div>

                <ButtonSalirGuardar
                    link="/mywork-orders"
                    btnText1="Salir"
                    btnText2="Finalizar tareas"
                    link2={this.finalizarFlujo.bind(this)}
                />
            </Fragment>
        )
    }
}
