import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Carousel, { consts } from 'react-elastic-carousel';

import svgArrow from '../../../../../assets/img/icons/arrow-down.svg';
import svgTimbre from '../../../../../assets/img/carousel/timbre_celeste.svg';
import svgTimbreBlue from '../../../../../assets/img/carousel/timbre_blue-balck.svg';
import next from '../../../../../assets/img/carousel/next.png'
import next_disable from '../../../../../assets/img/carousel/next_disable.png'
import previous from '../../../../../assets/img/carousel/previous.png'
import previous_disable from '../../../../../assets/img/carousel/previous_disable.png'

import "./styles.css";

const logo = require('assets/img/logo_navbar.png');

const breakPoints = [
    { width: 1, itemsToShow: 1, },
    { width: 714, itemsToShow: 2, itemsToScroll: 2, },
    { width: 1015, itemsToShow: 3, },
    { width: 1200, itemsToShow: 4, },
];

const SuscripcionCarousel = (props) => {
    const { data } = props
    const [itemShow, setItemShow] = useState(1)

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? 'next' : 'back'
        return (
            <div onClick={!isEdge ? onClick : () => { }} disabled={isEdge} className="d-flex justify-content-center align-items-center p-0 m-0" style={{ width: 45, }} >
                <img className={`${isEdge ? "d-none" : ""} img-fluid p-0 m-0 ${pointer != "next" ? "mr-0 mr-sm-1 mr-md-2 mr-lg-5" : "ml-0 ml-sm-1 ml-md-2 ml-lg-5"}`}
                    src={pointer != "next" ? !isEdge ? next : next_disable : !isEdge ? previous : previous_disable} style={{ cursor: "pointer", zIndex: 1000 }} />
            </div>
        )
    }

    return (
        <div className="app-carousel">
            <div className="mb-4 col-12 p-0">
                <div className="row mb-5">
                    <div className="col-12 row">
                        <div className="col-12 d-flex justify-content-center">
                            <img src={logo} alt="logo" className="img-fluid-nav" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-wrapper align-items-center d-flex">
                <Carousel
                    breakPoints={breakPoints}
                    renderArrow={myArrow}
                >
                    {data && data.length > 0 && data.map((item, index) => {
                        return (
                            <div className="card card-body suscription text-center" key={index}>
                                <h3 className="text-25 bold titulo-plan pt-5">{item.name}</h3>
                                <img
                                    className="main-logo-timbre pb-3 mx-auto"
                                    src={index % 2 == 0 ? svgTimbreBlue : svgTimbre}
                                />
                                <h1 className={`${index % 2 == 0 ? "azul-21" : "azul48"} text-50 bold pb-4`}  >$ {item.monthly_fee.toFixed(2)}</h1>
                                <div className="text-left">
                                    <ul>
                                        {item.work_orders >= 1000000 ?
                                            <li className="mb-1 text-12 bold"> Ordenes Ilimitadas de trabajo</li>
                                            :
                                            <li className="mb-1 text-12 bold"> Límite de ordenes de trabajo de {item.work_orders}</li>
                                        }
                                        <li className="mb-1 text-12 bold"> Número de tipos de ordenes activas de {item.enable_type_work_orders} </li>
                                    </ul>
                                </div>
                                <div className="mx-4 my-3" style={{ borderTop: "solid 2px #323232" }} />
                                <div onClick={() => { setItemShow(index) }} className={itemShow == index ? "d-none" : "d-flex justify-content-center align-items-center p-0 m-0"} style={{ cursor: "pointer" }}>
                                    <img className="img-fluid p-0 m-0" width={25} src={svgArrow} style={{ cursor: "pointer", zIndex: 1000 }} />
                                </div>

                                <div class={itemShow == index ? "collapse-show" : "collapse"} id={"collapse"+index}>
                                    <h4 className="text-20 bold">OBTENER MODO DE PAGO</h4>
                                    <div className="pt-1 pr-4 pl-4">
                                        <h6>Mensual</h6>
                                        <Link
                                            className="btn btn-block btn-primary text-20"
                                            to={`/register/plan/${item.id}/pay/monthly`}
                                        >
                                            $ {item.monthly_fee.toFixed(2)}
                                        </Link>
                                    </div>
                                    <div className="pt-4 pr-4 pl-4">
                                        <h6>Anual</h6>
                                        <Link
                                            className="btn btn-block btn-azul text-20"
                                            to={`/register/plan/${item.id}/pay/annual`}
                                        >
                                            $ {item.annual_fee.toFixed(2)}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
            </div>
            <div className="col-12 p-0">
                <div className="row mb-5">
                    <div className="col-12 row">
                        <div className="col-12 d-flex justify-content-center mt-5">
                            <div className="col-9 buttons-box">
                                <Link
                                    className="btn btn-gris text-20"
                                    to={`/login`}
                                >
                                    REGRESAR
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuscripcionCarousel;
