import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import Formulario from './Formulario';


const logo = require('assets/img/logo_navbar.png');
class Registro extends Component {
    componentWillMount() {
        const token = this.props.match.params.token || 0
        this.props.validate_token(token);
    }
    update = (_data) => {
        const token = this.props.match.params.token || 0
        this.props.update({ token, ..._data })
    };

    render() {
        const { loader, data } = this.props;
        if (localStorage.getItem('token')) {
            return <Redirect to="/" />;
        }
        return (
            <div className="blue-gradient-bg d-flex align-items-center">
                <div className="login-wrapper flex-1 px-sm-5 px-md-0">
                    <div className="card-registro_cotizacion mx-1 mx-sm-auto">
                        <LoadMask loading={loader} light>
                            <Formulario update={this.update} data={data} />
                        </LoadMask>
                    </div>
                </div>
            </div >
        );
    }
}

export default Registro;
