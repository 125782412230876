import React, { Component, Fragment } from 'react'
import Formulario from './Formulario';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Modal from "../../../Utils/Modal/ReactModal";
export default class Crear extends Component {
    state = {
        modal_open: true,
        data_payment: {},
    };

    componentWillMount() {
    }
    close_modal = () => {
        this.setState({ modal_open: false })
    };
    render() {
        //Value
        const { loader, required = false, me = {} } = this.props;
        const { cost_per_additional_order = 0 } = me || {}
        return (
            <LoadMask loading={loader} blur>
                <Modal
                    show_modal={this.state.modal_open}
                    showModal={this.close_modal}
                >
                    <Formulario close_modal={this.close_modal} required={required} onSubmit={this.props.adquirirOrdenExtra} cost_per_additional_order={cost_per_additional_order} />

                </Modal>
            </LoadMask>
        )
    }
}
